import React from 'react';

const NewHomeContainer = (props) => {
  const { panelMarginTop, ...rest } = props;
  return (
    <div
      className={`
      flex
      justify-center
      w-full
      px-16
      sm:px-32
      ${panelMarginTop}
    `}
    >
      <div
        className={`
          new-home
          flex
          flex-col
          w-full
          pb-16
      `}
        {...rest}
      >
        {rest.children}
      </div>
    </div>
  );
};

export default NewHomeContainer;
