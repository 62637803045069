// import _ from 'lodash';
// import axios from 'axios';
// import * as types from '../constants/actionTypes';
// import { getVcmsSeasonId } from 'utils/opta';
// import defaultConfig from 'constants/defaultConfig';
//
// export function getTables(competitionId, season) {
//   return (dispatch) => {
//     const {
//       vcmsBaseUrl,
//       VCMSConfig: { resultsStandingsEndpoint },
//     } = defaultConfig;
//     const vcmsSeasonId = getVcmsSeasonId(competitionId);
//     const url = `${vcmsBaseUrl}${resultsStandingsEndpoint}?season=${vcmsSeasonId}&competition=${competitionId}`;
//     const tables = {competitionId, season};
//
//     dispatch({type: types.GET_TABLES_INPROGRESS, tables});
//
//     axios.get(url)
//       .then((res) => {
//         const isSuccess = (_.get(res, 'data.result[0].statuscode', 'N') === 'Y');
//
//         if (isSuccess) {
//           tables.rows = _.get(res, 'data.APIinfo[0].Table', []);
//           dispatch({type: types.GET_TABLES_SUCCESS, tables});
//         } else {
//           dispatch({type: types.GET_TABLES_FAILURE, tables});
//         }
//
//       })
//       .catch((error) => {
//         dispatch({type: types.GET_TABLES_FAILURE, tables});
//       })
//   }
// }

import _ from 'lodash';
import axios from 'axios';
import * as types from '../constants/actionTypes';

export function getTables(competitionId, season) {
  return (dispatch) => {
    const tables = {competitionId, season};
    const url = `https://d3rj5p7u4c4icq.cloudfront.net/sports/api/Soccer/resultsstandings2?season=${season}&competition=${competitionId}`;
    dispatch({type: types.GET_TABLES_INPROGRESS, tables});

    axios.get(url)
      .then((res) => {
        const isSuccess = (_.get(res, 'data.result[0].statuscode', 'N') === 'Y');

        if (isSuccess) {
          tables.rows = _.get(res, 'data.APIinfo[0].Table', []);
          dispatch({type: types.GET_TABLES_SUCCESS, tables});
        } else {
          dispatch({type: types.GET_TABLES_FAILURE, tables});
        }

      })
      .catch((error) => {
        dispatch({type: types.GET_TABLES_FAILURE, tables});
      })
  }
}
