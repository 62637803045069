import * as types from '../constants/actionTypes';
import API from '@aws-amplify/api';

export function getSAAGData() {
  const saagdata = {};
  return function (dispatch) {
    dispatch({type: types.GET_SAAGDATA_INPROGRESS, saagdata});

    return API.get('fe-api-dev-sportsdataGetSaagData', '', {
      'queryStringParameters': {
        'v': '1'
      }
    })
    .then(saagdata => {
      if (!saagdata.error) {
        // set saag data
        dispatch({type: types.GET_SAAGDATA_SUCCESS, saagdata});
      } else {
       // unset saag data
       dispatch({type: types.GET_SAAGDATA_FAILURE, saagdata});
      }
    })
    .catch(err => {
      // Error so clear saag data
      dispatch({type: types.GET_SAAGDATA_FAILURE, saagdata});
    })
  }
}
