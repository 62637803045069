import React from 'react';
import PropTypes from 'prop-types';

const DeviceTypes = ({ width, height, viewBox, ...props }) => (
  <svg className="device-types-svg" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} {...props}>
    <g fill="#888A8C" fillRule="evenodd">

    <path d="M366 19.5V72h-75V19.5h29.531L311.781 2h5.469L326 19.5h4.219L339.28 2h5.781L336 19.5h30zM361 67V24.5h-65V67h65zM276 61.913h-32.813v5.044H261V72h-40v-5.043h17.188v-5.044H206V14h70v47.913zm-65-42.87V56.87h60V19.043h-60zM121 67.048h70V72h-70v-4.952zm10-42.096v32.19h50v-32.19h-50zm55 37.143h-60V20h60v42.095zM102.595 0c.826 0 1.6.298 2.322.895S106 2.225 106 3.092v65.816c0 .868-.284 1.6-.851 2.197a2.77 2.77 0 0 1-2.09.895H56.94a2.77 2.77 0 0 1-2.089-.895c-.567-.597-.851-1.33-.851-2.197V3.092c0-.868.284-1.6.851-2.197A2.77 2.77 0 0 1 56.941 0h45.654zm-1.547 5.208H58.952v7.813h42.096V5.208zM58.952 66.792h42.096V18.229H58.952v48.563zm18.572-5.209c0-.76.232-1.383.696-1.871.465-.489 1.058-.733 1.78-.733s1.315.244 1.78.733c.464.488.696 1.112.696 1.871 0 .76-.232 1.384-.696 1.872-.465.488-1.058.733-1.78.733s-1.315-.245-1.78-.733c-.464-.488-.696-1.112-.696-1.872zM17.316 63.295c0-.621.208-1.165.624-1.632.416-.466.936-.7 1.56-.7s1.144.234 1.56.7c.416.467.624 1.01.624 1.632 0 .622-.208 1.14-.624 1.555-.416.414-.936.622-1.56.622s-1.144-.208-1.56-.622c-.416-.415-.624-.933-.624-1.555zM36.504 12c.728 0 1.326.233 1.794.7.468.466.702 1.062.702 1.787v55.026c0 .725-.26 1.321-.78 1.788-.52.466-1.092.699-1.716.699H2.496c-.728 0-1.326-.233-1.794-.7C.234 70.835 0 70.239 0 69.514V14.487c0-.725.234-1.321.702-1.788C1.17 12.233 1.768 12 2.496 12h34.008zm-2.496 4.974H4.992v5.751h29.016v-5.75zM4.992 67.026h29.016V27.699H4.992v39.327z"/>
  </g>
</svg>
);

DeviceTypes.propTypes = {
  viewBox: PropTypes.string
};

DeviceTypes.defaultProps = {
  viewBox: '0 0 366 72'
};

export default DeviceTypes;



