import styled, { css } from 'styled-components';
import { colorLuminance, asRem } from '../Theme';

const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${(props) => {
    const { fullWidth, verticalPush, verticalPull } = props;
    return css`
      ${fullWidth &&
        css`
          width: 100%;
        `};
      ${verticalPush &&
        css`
          margin-top: ${verticalPush}px;
        `}
      ${verticalPull &&
        css`
          margin-bottom: ${verticalPull}px;
        `}
    `;
  }}
`;

const InputTail = styled.span`
  color: white;
  box-sizing: border-box;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 75px;
  height: 100%;
  font-size: ${asRem(16)};
  line-height: ${asRem(16)};
  color: white;
  transition: all 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    color: #e0e0e0;
  }
`;

const InputItem = styled.input.attrs({ type: 'text' })`
  ${(props) => {
    const variants = {
      primary: css`
        border-color: #acafb2;
        background-color: rgb(87, 88, 89);
        color: #acafb2;
        &:placeholder {
          color: #acafb2;
        }
      `,
      secondary: css`
        background-color: #191919;
        border-color: #acafb2;
        color: white;
        &:placeholder {
          color: #acafb2;
        }
      `,
      tertiary: css`
        background-color: white;
        border-color: #acafb2;
        color: #191919;
        &:placeholder {
          color: #acafb2;
        }
      `,
    };
    const {
      fullWidth,
      verticalPush,
      variant,
      isError,
      disabled,
      theme: { palette },
    } = props;
    const disabledStyle = css`
      &:disabled {
        border: 2px solid #3a3a3a;
      }
    `;
    const showError = css`
      border: 2px solid ${palette?.['live-red'] || 'red'};
    `;
    return css`
      width: ${fullWidth ? '100%' : 'auto'};
      padding: 12px;
      color: ${palette?.['os-white']};
      border: 2px solid ${palette?.['os-light-grey']};
      outline: none;
      border-radius: 4px;
      vertical-align: middle;
      box-sizing: border-box;
      font-size: ${asRem(16)};
      line-height: ${asRem(16)};
      background-color: ${colorLuminance(palette?.['os-dark-grey'], 0.5)};
      margin-top: ${verticalPush !== 0 ? props.verticalPush : 0}px;
      margin-bottom: 0;
      ${variants?.[variant]};
      ${isError && showError};
      ${disabled && disabledStyle};
    `;
  }}
`;

export { InputItem, InputContainer, InputTail };
