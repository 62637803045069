import _ from 'lodash';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import defaultConfig from '../../../../constants/defaultConfig';
import VideoCard from './asset-card/AssetCard';
import selectors from './selectors';
import Header from './Header/';

/**
 *
 * @param allAssets
 * @param noOfCols
 * @param noOfRows
 * @param categoryMapping
 * @param isArticle
 * @returns {*[]}
 */

const getVideoCardListsDom = (
  allAssets,
  noOfCols = 4,
  noOfRows,
  categoryMapping,
  isArticle = false,
  theme = 'dark',
) => {
  const allAssetsCloned = allAssets.slice(0);
  let splittedAssets = [],
    rowIndex = 0;
  while (rowIndex < noOfRows && allAssetsCloned.length) {
    splittedAssets.push(allAssetsCloned.splice(0, noOfCols));
    rowIndex++;
  }
  return splittedAssets.map((assets, i) => (
    <div
      key={`${shortid.generate()}-${i}`}
      className={`layout-d-card-list ${i < noOfRows - 1 ? 'pb-16' : ''}`}
    >
      {assets.map((asset, currIndex) => (
        <div className='item' key={`${shortid.generate()}-${currIndex}`}>
          <VideoCard
            key={asset.id}
            asset={asset}
            categoryMapping={categoryMapping}
            isArticle={isArticle}
            theme={theme}
            themeType='d'
            className='rounded-t-xl'
          />
        </div>
      ))}
    </div>
  ));
};

/**
 * Responsive Component to create row(s) of cards
 *
 * Desktop mode: 4 Normal cards in 1 row
 *
 * Tablet & Mobile: Smaller cards in 2 cards in 2 separate rows
 *
 * Number of rows to render can be controlled by `noOfRows` props
 */

class LayoutDVideoCardList extends Component {
  render() {
    const {
      editorial,
      categoryPath,
      videoCardsListDom,
      isArticle,
      carousel,
      viewAllUrl,
      showViewAllLink,
      theme,
    } = this.props;

    const cardList = videoCardsListDom.length ? (
      <div className='w-full'>
        {!isArticle && (
          <Header
            title={_.get(carousel, 'title', _.get(editorial, 'title', ''))}
            categoryPath={categoryPath}
            viewAllUrl={viewAllUrl}
            showViewAllLink={showViewAllLink}
            theme={theme}
          />
        )}
        {videoCardsListDom}
      </div>
    ) : null;
    return <>{cardList}</>;
  }
}

function mapStateToProps(state, ownProps) {
  const noOfRows = ownProps.noOfRows || 1;
  const categoryMapping = _.get(state, 'systemConfig.categoryMapping', []);
  const noOfCols = ownProps.noOfCols || 4;
  if (ownProps.isArticle) {
    const { articles, isArticle, theme } = ownProps;
    return {
      videoCardsListDom: getVideoCardListsDom(
        articles,
        noOfCols,
        noOfRows,
        categoryMapping,
        isArticle,
        theme,
      ),
    };
  }
  const editorial = state.editorials.find(
    (ed) => ed.id === ownProps.carousel.id,
  );
  const imageBaseUrl =
    _.get(state, 'systemConfig.image.baseUrl', null) ||
    defaultConfig.image.baseUrl;
  const carouselImageSize =
    _.get(state, 'systemConfig.image.sizes.carouselImageSize', null) ||
    defaultConfig.image.sizes.carouselImageSize;
  const assetTypeNames = _.get(
    state,
    'systemConfig.hideCarouselModal.assetTypeNames',
    [],
  );
  const assets = ownProps.asset || _.get(editorial, 'assets', []);
  const { theme } = ownProps;
  return {
    imageBaseUrl,
    carouselImageSize,
    assetTypeNames,
    categoryMapping,
    editorial,
    categoryPath: selectors.getCategoryPath(ownProps, editorial),
    videoCardsListDom: getVideoCardListsDom(
      assets,
      noOfCols,
      noOfRows,
      categoryMapping,
      null,
      theme,
    ),
  };
}

LayoutDVideoCardList.propTypes = {
  noOfRows: PropTypes.number,
  noOfCols: PropTypes.number,
  viewllUrl: PropTypes.string,
  showViewAllLink: PropTypes.bool,
};

LayoutDVideoCardList.defaultProps = {
  noOfRows: 1,
  noOfCols: 4,
  viewAllUrl: null,
  showViewAllLink: true,
};

export default connect(mapStateToProps)(LayoutDVideoCardList);
