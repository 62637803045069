import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import * as metaDescriptionAction from '../../actions/metaDescription';

class UpdateMetaDescription extends Component {
  getMetaDescriptionFromPath = pathName => {
    switch ( pathName ) {
      case '/':
        return `The World's Best Football. Optus Sport is the exclusive home of Premier League, Women's Super League, LaLiga. Every Game Live & On Demand. Watch now for $24.99 per month.`;
      case '/login':
        return 'Already got an account? Log In here to access Optus Sport.';
      case '/campaign':
        return `Sign up to Optus Sport here. Get access to the best in Football, with Premier League, Women's Super League, LaLiga and more. Live & On-Demand.`;
      case '/forgotpassword':
        return 'Change your Optus Sport password here.';
      case '/changepassword':
        return 'Change Password';
      case '/help':
        return 'Need a hand? Head to Optus Sport Help & Support for answers to common questions or to chat with other users on our forums.';
      case '/signup':
        return `Sign up to Optus Sport now for $24.99 per month. Every Premier League, Women's Super League, LaLiga Match, Live & On Demand. The World's Best Football.`;
      case '/register':
        return 'Create your Optus Sport account.';
      case '/checkout':
        return 'Change your current Optus Sport subscription.';
      case '/usersettings':
        return 'Your Optus Sport account settings.';
      default:
        return this.props.metaDescription;
    }
  };

  componentDidMount() {
    const path = _.get(this, 'props.location.pathname', null);
    this.props.metaDescriptionAction && this.props.metaDescriptionAction.setMetaDescription(this.getMetaDescriptionFromPath(path));
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location || this.props.metaDescription !== prevProps.metaDescription) {
      const path = _.get(this, 'props.location.pathname', null);
      this.props.metaDescriptionAction && this.props.metaDescriptionAction.setMetaDescription(this.getMetaDescriptionFromPath(path));
    }
  }

  render() {
    const { metaDescription = '' } = this.props;
    return <Helmet>
      <meta name='description' content={metaDescription}/>
    </Helmet>;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    metaDescription: state.metaDescription,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    metaDescriptionAction: bindActionCreators(metaDescriptionAction, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateMetaDescription));
