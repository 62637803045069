import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function systemConfig(state = initialState.systemConfig, action) {
  let newState = {};

  switch (action.type) {
    case types.GET_SYSTEM_CONFIG_INPROGRESS:
      newState = Object.assign(newState, state, {inProgress: true});
      return newState;

    case types.GET_SYSTEM_CONFIG_SUCCESS:
      newState = Object.assign(newState, action.systemConfig, {inProgress: false});
      return newState;

    case types.GET_SYSTEM_CONFIG_FAILURE:
      newState = Object.assign(newState, {error: true}, {inProgress: false});
      return newState;

    default:
      return state;
  }
}
