import defaultConfig from '../constants/defaultConfig';
import * as types from '../constants/actionTypes';
import API from '@aws-amplify/api';

const loadSystemConfigs = () => {
  return function(dispatch) {
    dispatch({ type: types.GET_SYSTEM_CONFIG_INPROGRESS });

    return API.get(
      'fe-api-dev-metadataGetSystemConfig',
      `/systemConfigs/${defaultConfig.frontendConfigName}`,
      {}
    )
      .then((systemConfig) => {
        dispatch({ type: types.GET_SYSTEM_CONFIG_SUCCESS, systemConfig });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_SYSTEM_CONFIG_FAILURE,
          error: 'failed to get system config'
        });
      });
  };
};

export { loadSystemConfigs };
