import React, { Component } from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as squadActions from '../../actions/squad';
import * as teamStatsActions from '../../actions/teamstats';
import * as teamSummaryActions from '../../actions/teamSummary';
import { default as TeamProfileUI } from '../ui/TeamProfileUI';
import PageLoading from '../ui/PageLoading';
import * as optaUtils from '../../utils/opta';
import * as assetActions from '../../actions/asset';
import defaultConfig from '../../constants/defaultConfig';
import { getSaagCompetition } from '../../utils/teamListings';

class TeamProfile extends Component {
  constructor(props, context) {
    super(props, context);
    const navigations = _.get(this.props.navigation, 'navigations', []);
    const opta = optaUtils.getOptaConfig(
      this.props.location.pathname,
      navigations
    );
    const teamId = this.props.match.params.teamId;
    this.getTeamSummary = this.getTeamSummary.bind(this);
    this.loadAsset = this.loadAsset.bind(this);

    const teams = _.get(props, ['teams', 'allTeams'], _.get(props, 'teams', []));
    const teamName = this.getTeamName(teams, teamId);
    this.loadAsset(teamName);
    if (opta && teamId) {
      this.getTeamSummary(opta.season, opta.competitionId, teamId);
      this.getSquad(opta.competitionId, opta.season, teamId);
    }
    this.state = { teamId }

  }

  componentWillReceiveProps(nextProps) {
    let path = _.get(nextProps, 'location.pathname', '').toLowerCase();
    if (path === '') {
      // no new path, use the current path
      path = _.get(this.props, 'location.pathname', '').toLowerCase();
    }

    let navigations = _.get(nextProps, 'navigation.navigations', []);

    if (navigations.length === 0) {
      navigations = _.get(this.props.navigation, 'navigation.navigations', []);
    }

    // path or navigation updated, need to update fixture
    const opta = optaUtils.getOptaConfig(path, navigations);

    if (opta) {
      const currentCompetitionId = _.get(
        this.props.teamSummary,
        'competitionId',
        null
      );
      const currentSeason = _.get(this.props.teamSummary, 'seasonId', null);
      const currentTeams = _.get(this.props, ['teams', 'allTeams'], _.get(this.props, 'teams', []));
      const nextTeams = _.get(nextProps, ['teams', 'allTeams'], _.get(nextProps, 'teams', []));

      if (currentCompetitionId !== opta.competitionId || currentSeason !== opta.season || currentTeams.length !== nextTeams.length) {
        this.getTeamSummary(opta.season, opta.competitionId, this.state.teamId);
        this.getSquad(opta.competitionId, opta.season, this.state.teamId);
        const teamName = this.getTeamName(nextTeams, this.state.teamId);
        this.loadAsset(teamName);

        if (nextTeams.length) {
          const competitions = nextTeams.find(team => team.optaId === this.state.teamId);
          if (competitions) {
            const optaItems = _.get(opta, 'optaItems');

            const filteredCompetitions = competitions.competitions.map(team => {
                const compTeamOptaId = _.get(team, 'teamOptaId');

                return compTeamOptaId && compTeamOptaId ===  this.state.teamId ? team : null;
            });
            const deDupeUniqCompetitions = _.uniqBy(_.compact(filteredCompetitions), team => team && team.optaId);

            deDupeUniqCompetitions.map(team => {
              const teamCompetitionId = _.get(team, 'optaId');
              // If we have optaItems then use that to retrieve the correct season id for the team.CompetitionID

              let optaSeasonId = _.get(opta, 'season', new Date().getFullYear());
              if (optaItems) {
                const teamCompetitionInfo = _.find(optaItems, (optaData) => Number(_.get(optaData, 'competitionId')) === Number(teamCompetitionId));

                if (teamCompetitionInfo) {
                  optaSeasonId = _.get(teamCompetitionInfo, 'season', optaSeasonId);
                }
              }

              this.getTeamStats(optaSeasonId, Number(teamCompetitionId), this.state.teamId);
              return null;
            });
          }
        }
      }
    }
  }

  getTeamName(teams, teamId) {
    if (!teams.length) {
      return '';
    }
    const teamDetails = teams.find( team => team.optaId === teamId);
    return teamDetails ? teamDetails.title : '';
  }

  getSquad(competitionId, seasonId, teamId) {
    this.props.squadActions.getSquad(competitionId, seasonId, teamId);
  }

  getTeamStats(seasonId, competitionId, teamId) {
    this.props.teamStatsActions.getTeamStats(seasonId, competitionId, teamId);
  }

  getTeamSummary(seasonId, competitionId, teamId) {
    this.props.teamSummaryActions.getSummary(seasonId, competitionId, teamId);
  }

  loadAsset(teamName) {
    if (teamName) {
      this.props.assetActions.getAssetByType('team', teamName);
    }
  }


  render() {
    const { assetCategory } = this.props;
    const inProgress = _.get(this.props, 'squad.inProgress', false) || _.get(this.props, 'teamSummary.inProgress', false) || _.get(this.props, 'teamStats.inProgress', false);
    const squadResults = _.get(this.props, 'squad.rows', []);
    const teamSummaryResults = _.get(this.props, 'teamSummary.rows', []);
    const userLoggedIn = _.get(this.props.user, 'loggedIn', false);
    const userType = _.get(this.props.user, ['userType', 'type'],
      _.get(this.props.user, 'authData.cognitoUser.attributes[custom:userType]', '')
    );
    const isPremiumUser = userLoggedIn && userType === 'premium';
    const filteredLiveData = _.get(this.props, 'livematchData.info', []);
    // const teamStats =
    // const baseUrl = this.props.systemConfig.infinitOptaBaseUrl || defaultConfig.infinitOptaBaseUrl;
    if (inProgress) {
      return <PageLoading />;
    }

    if (this.props.table.length === 0 || this.props.fixtures.length === 0 || this.props.results.length === 0) {
      return null;
    }

    return <TeamProfileUI
      onSelectChange={this.getTeamStats}
      teams={this.props.teams}
      teamID={this.props.match.params.teamId || this.state.teamId}
      squad={squadResults} teamStats={this.props.teamStats}
      teamSummary={teamSummaryResults} tableSummary={this.props.table}
      fixtures={this.props.fixtures}
      results={this.props.results}
      opta={this.props.opta}
      competitions={this.props.competitions}
      assetCategory={assetCategory}
      isPremiumUser={isPremiumUser}
      imageBaseUrl={this.props.imageBaseUrl}
      panelCoverSize={this.props.panelCoverSize}
      categoryMapping={this.props.categoryMapping}
      liveMatchData={filteredLiveData}
      />;
  }
}

function mapStateToProps(state, ownProps) {
  const path = ownProps.location.pathname.toLowerCase();
  const navigations = _.get(state.navigation, 'navigations', []);
  const opta = optaUtils.getOptaConfig(path, navigations);
  const teamId = ownProps.match.params.teamId;
  let squad = [];
  let teamSummaryResults = [];
  let livematchData;
  const { teams } = state;
  if (opta) {
    teamSummaryResults = state.teamSummary.find(
      summary =>
        summary.competitionId === opta.competitionId &&
        summary.seasonId === opta.season &&
        summary.teamId === teamId
    );
    const competition = getSaagCompetition(opta.competitionId);
    livematchData = state.saagdata.find(saageItem => saageItem.sport === competition)
  }
  squad = state.squad.find(squad => squad.teamId === teamId);
  const allTeams = _.get(state, 'teams.allTeams', []);
  const teamCompetitions = allTeams.find(team => team.optaId === teamId);
  const filteredCompetitions = teamCompetitions ?
    teamCompetitions.competitions.map(team => {
      const compTeamOptaId = _.get(team, 'teamOptaId');

      return compTeamOptaId && compTeamOptaId ===  teamId ? team : null;
    }) : []
  ;
  const deDupeUniqCompetitions = _.uniqBy(_.compact(filteredCompetitions), team => team && team.optaId);

  const imageBaseUrl = _.get(state, 'systemConfig.image.baseUrl', null) || defaultConfig.image.baseUrl;
  const panelCoverSize = _.get(state, 'systemConfig.image.sizes.panelCoverSize', null) || defaultConfig.image.sizes.panelCoverSize;
  const categoryMapping = _.get(state, 'systemConfig.categoryMapping', null);

  return {
    systemConfig: state.systemConfig,
    navigation: state.navigation,
    opta: opta,
    squad,
    teamStats: state.teamStats,
    teamSummary: teamSummaryResults,
    table: state.tables,
    fixtures: state.fixtures,
    results: state.results,
    teams,
    competitions: deDupeUniqCompetitions,
    assetCategory: state.assetCategory,
    user: state.user,
    imageBaseUrl: imageBaseUrl,
    panelCoverSize: panelCoverSize,
    categoryMapping: categoryMapping,
    livematchData
}
}

function mapDispatchToProps(dispatch) {
  return {
    squadActions: bindActionCreators(squadActions, dispatch),
    teamStatsActions: bindActionCreators(teamStatsActions, dispatch),
    teamSummaryActions: bindActionCreators(teamSummaryActions, dispatch),
    assetActions: bindActionCreators(assetActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamProfile);
