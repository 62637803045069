import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import Header from './Header/';
import VideoCard from './asset-card/AssetCard';
import defaultConfig from '../../../../constants/defaultConfig';
import selectors from './selectors';

const VideoCardList = loadable(() => import('./LayoutDVideoCardList'));

/**
 * Responsive Component to create 2 different types of grids at the moment (v1 OR v2 passed as props)
 *
 * Desktop mode:
 * v1 (default): A combination of 2 Hero cards with 4 Normal cards
 * v2: Row 1 portrays 2 Hero cards
 *     Row 2 portrays 4 Normal cards
 *
 * Tablet mode:
 * v1 (default): Row 1 & 2 both portray a lone Hero card for each
 *               Row 3 & 4 both portray 2 Normal cards
 * v2: Same as v1's layout
 *
 * Mobile mode:
 * v1 (default) and v2: Similar layout with smaller tiles
 */

class LayoutAHeroGrid extends Component {
  render() {
    const {
      carousel,
      editorial,
      categoryMapping,
      categoryPath,
      gridVersion,
      assets,
      isArticle,
      sectionPath,
      theme,
      carouselIndex,
    } = this.props;

    if (editorial && editorial.inProgress && !editorial.isSuccess) {
      return null;
    } else {
      const assetsLength = assets.length;

      // We need to fallback to Layout D if less than 6 assets
      if (assetsLength < 6) {
        return (
          <VideoCardList
            key={`${carousel.id}_${carouselIndex}`}
            carousel={carousel}
            noOfRows={1}
            sectionPath={sectionPath}
            theme={theme}
            themeType='d'
          />
        );
      }

      const assetsForGrid = assetsLength ? assets.slice(0, 6) : null;

      return assetsForGrid ? (
        <div
          className='
          flex
          flex-col
        '
        >
          {!isArticle && (
            <Header
              title={_.get(carousel, 'title', _.get(editorial, 'title', ''))}
              categoryPath={categoryPath}
              theme={theme}
            />
          )}
          <div
            className={`${
              gridVersion === 'v1'
                ? 'layout-a-card-list-v1'
                : 'layout-a-card-list-v2'
            } pl-0`}
          >
            {assetsForGrid.map((asset, index) => (
              <div
                key={_.get(asset, 'id', shortid.generate())}
                className={`item-${index + 1}`}
              >
                <VideoCard
                  asset={asset}
                  isArticle={isArticle}
                  categoryMapping={categoryMapping}
                  theme={theme}
                  themeType='a'
                  className='rounded-t-xl'
                />
              </div>
            ))}
          </div>
        </div>
      ) : null;
    }
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.isArticle) {
    const { articles } = ownProps;

    return {
      assets: articles,
    };
  } else {
    const editorial = state.editorials.find(
      (ed) => ed.id === ownProps.carousel.id,
    );
    const carouselImageSize =
      _.get(state, 'systemConfig.image.sizes.carouselImageSize', null) ||
      defaultConfig.image.sizes.carouselImageSize;
    const assetTypeNames = _.get(
      state,
      'systemConfig.hideCarouselModal.assetTypeNames',
      [],
    );
    const categoryMapping = _.get(state, 'systemConfig.categoryMapping', []);
    return {
      carouselImageSize,
      assetTypeNames,
      categoryMapping,
      editorial,
      categoryPath: selectors.getCategoryPath(ownProps, editorial),
      assets: _.get(editorial, 'assets', []),
    };
  }
}

LayoutAHeroGrid.propTypes = {
  gridVersion: PropTypes.string,
  isArticle: PropTypes.bool,
  articles: PropTypes.array,
  theme: PropTypes.string,
};

LayoutAHeroGrid.defaultProps = {
  gridVersion: 'v1',
  isArticle: false,
  articles: [],
  theme: 'dark',
};

export default connect(mapStateToProps)(LayoutAHeroGrid);
