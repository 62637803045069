import { mapObjIndexed, type, equals, toLower, map } from 'ramda';

const typeIs = (expected) => (original) => equals(type(original), expected);
const isObjectLiteral = typeIs('Object');

const replaceNeedlesInHaystack = (needles, replaceData) => (haystack) => {
  // Make lowercase on needles array
  const lowerCaseNeedles = map(toLower, needles);

  const walkBackwards = mapObjIndexed((value, key) => {
    const isNestedHaystack = isObjectLiteral(value);
    // Make key lowercase
    const isMatchingNeedle = lowerCaseNeedles.includes(toLower(key));

    if (isNestedHaystack) return walkBackwards(value);
    if (isMatchingNeedle) return replaceData;
    return value;
  });
  return walkBackwards(haystack);
};

export { replaceNeedlesInHaystack };
