import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function fixtures(state = initialState.fixtures, action) {
  let currentFixtures;

  switch (action.type) {

    case types.GET_FIXTURES_INPROGRESS:
    const alreadyExistingData = state.find(fixtures => fixtures.competitionId === action.fixtures.competitionId && fixtures.season === action.fixtures.season)
    if(alreadyExistingData){
      alreadyExistingData.inProgress = true;
      alreadyExistingData.isSuccess = false;
    }
    return state;

    case types.GET_FIXTURES_SUCCESS:
      return [
        ...state.filter(fixtures => fixtures.competitionId !== action.fixtures.competitionId || fixtures.season !== action.fixtures.season),
        {
          ...action.fixtures,
          inProgress: false,
          isSuccess: true
        }
      ]

    case types.GET_FIXTURES_FAILURE:
      currentFixtures = {
        // for failure case, use whatever has been loaded before
        ...state.find(fixtures => fixtures.competitionId === action.fixtures.competitionId && fixtures.season === action.fixtures.season),
        ...action.fixtures,
        inProgress: false,
        isSuccess: false
      };

      return [
        ...state.filter(fixtures => fixtures.competitionId !== action.fixtures.competitionId || fixtures.season !== action.fixtures.season),
        currentFixtures
      ];

    default:
      return state;
  }
}

