import _ from 'lodash';
import axios from 'axios';
import * as types from '../constants/actionTypes';
import defaultConfig from 'constants/defaultConfig';
import { getVcmsSeasonId } from 'utils/opta';

export function getSummary(seasonId, competitionId, teamId) {
  return (dispatch) => {
    const results = { seasonId, competitionId, teamId };
    const {
      vcmsBaseUrl,
      VCMSConfig: { teamSummaryEndpoint },
    } = defaultConfig;
    const vcmsSeasonId = getVcmsSeasonId(competitionId);
    const url = `${vcmsBaseUrl}${teamSummaryEndpoint}?season=${vcmsSeasonId}&competition=${competitionId}&team=${teamId}`;
    const headers = {};

    dispatch({ type: types.GET_TEAMSUMMARY_INPROGRESS, results });

    axios
      .get(url, { headers })
      .then((res) => {
        const isSuccess = _.get(res, 'data.result[0].statuscode', 'N') === 'Y';

        if (isSuccess) {
          results.rows = _.get(res, 'data.APIinfo[0]', []); // results return HighlightsInfo as well
          dispatch({ type: types.GET_TEAMSUMMARY_SUCCESS, results });
        } else {
          dispatch({ type: types.GET_TEAMSUMMARY_FAILURE, results });
        }
      })
      .catch((error) => {
        dispatch({ type: types.GET_TEAMSUMMARY_FAILURE, results });
      });
  };
}
