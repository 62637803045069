import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import shortid from 'shortid';
import defaultConfig from '../../../../constants/defaultConfig';
import VideoCard from './asset-card/AssetCard';
import selectors from './selectors';
import Header from './Header/';

/**
 * Responsive Component to create 2 Hero cards
 *
 * Desktop mode: 2 Hero cards in 1 row
 *
 * Tablet & Mobile: Smaller cards in 2 separate rows
 */

class LayoutB2Heroes extends Component {
  render() {
    const {
      imageBaseUrl,
      editorial,
      categoryMapping,
      categoryPath,
      carousel,
      theme,
    } = this.props;

    let assets = _.get(editorial, 'assets', []);
    assets = assets.slice(0, assets.length >= 2 ? 2 : assets.length);

    const videoCards = assets.map((asset) => (
      <div key={_.get(asset, 'id', shortid.generate())} className='item'>
        <VideoCard
          asset={asset}
          imageBaseUrl={imageBaseUrl}
          categoryMapping={categoryMapping}
          theme={theme}
          themeType='b'
          className='rounded-t-xl'
        />
      </div>
    ));

    return (
      <>
        {videoCards.length ? (
          <div
            className={`
              flex
              flex-col
              w-full
            `}
          >
            <Header
              title={_.get(carousel, 'title', _.get(editorial, 'title', ''))}
              categoryPath={categoryPath}
              theme={theme}
            />
            <div className='layout-b-card-list'>{videoCards}</div>
          </div>
        ) : null}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const editorial = state.editorials.find(
    (ed) => ed.id === ownProps.carousel.id,
  );
  const imageBaseUrl =
    _.get(state, 'systemConfig.image.baseUrl', null) ||
    defaultConfig.image.baseUrl;
  const carouselImageSize =
    _.get(state, 'systemConfig.image.sizes.carouselImageSize', null) ||
    defaultConfig.image.sizes.carouselImageSize;
  const assetTypeNames = _.get(
    state,
    'systemConfig.hideCarouselModal.assetTypeNames',
    [],
  );
  const categoryMapping = _.get(state, 'systemConfig.categoryMapping', []);
  return {
    imageBaseUrl: imageBaseUrl,
    carouselImageSize: carouselImageSize,
    assetTypeNames: assetTypeNames,
    categoryMapping: categoryMapping,
    editorial: editorial,
    categoryPath: selectors.getCategoryPath(ownProps, editorial),
  };
}

export default connect(mapStateToProps)(LayoutB2Heroes);
