import _ from 'lodash';
import defaultConfig from 'constants/defaultConfig';

export function getOptaConfig(currentPath, navigationList) {
  const paths = currentPath.split('/');

  // current path is in format as /epl/fixture/ ...
  if (!paths || paths.length < 1 || !paths[1]) {
    //wrong currentPath or in home page which is '/' no opta metadata
    return null;
  }

  const competitionPath = paths[1].toLowerCase();
  const currentNavigation = navigationList.find(
    (navigation) => navigation.path === competitionPath,
  );

  const competitionId = _.get(currentNavigation, 'opta.competitionId', null);
  const season = _.get(currentNavigation, 'opta.season', null);
  const optaItems = _.get(currentNavigation, 'optaItems', null);

  if (competitionId && season && optaItems) {
    return { competitionId, season, competitionPath, optaItems };
  }
  if (competitionId && season) {
    return { competitionId, season, competitionPath };
  }

  return null;
}

export const getVcmsSeasonId = (competitionId) => {
  const {
    VCMSConfig: { competitions },
  } = defaultConfig;

  const result = _.find(competitions, (c) => `${c.competitionId}` === `${competitionId}`);
  return _.get(result, 'season', '');
};

export const getCompetitionCode = (competitionId) => {
  const {
    VCMSConfig: { competitions },
  } = defaultConfig;

  const result = _.find(competitions, (c) => `${c.competitionId}` === `${competitionId}`);
  return _.get(result, 'competitionCode', '');
};
