import _ from 'lodash';
import React, { Component } from "react";
import moment from 'moment-timezone';
import TapAndPinchable from 'react-tappable/lib/TapAndPinchable';
import { withRouter } from 'react-router-dom';
import FixtureTeam from "./FixtureTeam";
import FixtureStatus from "../FixtureStatus";
import defaultConfig from '../../../../constants/defaultConfig';

class NextMatch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			nextmatchPopoverShown: false
		}
	}

	findTeamShortCode = (teamID, teamlist) => {
		if (teamlist && teamlist.length !== 0) {
			const team = teamlist.allTeams.find( e => e.TeamID === teamID );

			if (team) {
				const teamShortCode = team.SYMID;
				return teamShortCode;
			}
		}
		return null;
	}

	handleTap = e => {
		this.setState({
			nextmatchPopoverShown: !this.state.nextmatchPopoverShown
		})
	}

	handlePress = e => {
		this.setState({
			nextmatchPopoverShown: false
		}, () => this.props.history.push(this.props.nextAssetURL))
	}

	handleMouseClick = url => {
		this.setState({
			nextmatchPopoverShown: false
		}, () => this.props.history.push(url))
	}

	render() {
		let match = this.props.nextMatchData;
		let homeTeamShortCode = this.findTeamShortCode(match.HomeTeamID, this.props.teams);
		let awayTeamShortCode = this.findTeamShortCode(match.AwayTeamID, this.props.teams);

    const homeTeamImg = _.get(match, 'HomeTeamImageURL', '');
    const awayTeamImg = _.get(match, 'AwayTeamImageURL', '');

    const finalHomeTeamImg =
      homeTeamImg.indexOf('https://') === 0
        ? homeTeamImg
        : `${defaultConfig.infinitOptaBaseUrl}/Sports/Soccer${homeTeamImg}`;

    const finalAwayTeamImg =
      awayTeamImg.indexOf('https://') === 0
        ? awayTeamImg
        : `${defaultConfig.infinitOptaBaseUrl}/Sports/Soccer${awayTeamImg}`;

		const matchDate = moment.utc(match.MatchDateTime).local();
		const matchDateFormatted = matchDate.format("dddd, DD MMM YYYY");
		const matchTimeFormatted = matchDate.format("HH:mm");

		return (
			<TapAndPinchable onTap={e => this.handleTap(e)} onPress={e => this.handlePress(e)}>
				<div
					onMouseEnter={() => this.setState({ nextmatchPopoverShown: true })}
					onMouseLeave={() => this.setState({ nextmatchPopoverShown: false })}
					onClick={()=> this.handleMouseClick(this.props.nextAssetURL)}
					className="cursor-pointer team-badge inline-block align-middle relative pointer-events-none xl:pointer-events-auto outline-none focus:outline-none active:outline-none"
				>
					<div className={`nextmatch-popover-wrap absolute w-279 h-80 bg-medium-dark-grey os-triangle-after-popup p-16 shadow-fixture-score z-99999 ${this.state.nextmatchPopoverShown ? 'xl:flex xl:flex-wrap xl:justify-center popover-fadein' : 'hidden popover-fadeout'}`}>
						<h2 className="text-center text-white text-xs font-MarkPro mb-10">{matchDateFormatted}</h2>

						<div className="flex flex-no-wrap justify-center items-center">
							<div className="team-one flex-1 text-right">
								<FixtureTeam
									imgUrl={finalHomeTeamImg}
									team={homeTeamShortCode || ''}
									reverse
									teamShortCode={homeTeamShortCode || ''}
								/>
							</div>

							<div className="score-card mx-7 flex-no-grow flex-no-shrink w-46 relative os-top--1">
								<FixtureStatus label={matchTimeFormatted} type="time" mini />
							</div>

							<div className="team-two flex-1">
								<FixtureTeam
									imgUrl={finalAwayTeamImg}
									team={awayTeamShortCode || ''}
									teamShortCode={awayTeamShortCode || ''}
								/>
							</div>
						</div>
					</div>
					<div
						className="w-24 h-24 bg-center bg-no-repeat bg-contain rounded-full inline-block align-middle"
						style={{backgroundImage: `url(${finalAwayTeamImg})`}}
					/>
				</div>
			</TapAndPinchable>
		)
	}
}

NextMatch.defaultProps = {
	onNextClick: () => {}
}

export default withRouter(NextMatch);


