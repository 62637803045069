// import _ from 'lodash';
// import axios from 'axios';
// import defaultConfig from '../constants/defaultConfig';
// import * as types from '../constants/actionTypes';
// import { getVcmsSeasonId } from 'utils/opta';
//
// export function getFixtures(competitionId, season) {
//   const vcmsSeasonId = getVcmsSeasonId(competitionId);
//
//   return (dispatch) => {
//     const {
//       vcmsBaseUrl,
//       VCMSConfig: { fixturesEndpoint },
//     } = defaultConfig;
//     const url = `${vcmsBaseUrl}${fixturesEndpoint}?season=${vcmsSeasonId}&competition=${competitionId}`;
//     const fixtures = { competitionId, season };
//
//     dispatch({ type: types.GET_FIXTURES_INPROGRESS, fixtures });
//
//     axios
//       .get(url)
//       .then((res) => {
//         const isSuccess = _.get(res, 'data.result[0].statuscode', 'N') === 'Y';
//
//         if (isSuccess) {
//           fixtures.rows = _.get(res, 'data.APIinfo[0].FixtureInfo', []);
//           dispatch({ type: types.GET_FIXTURES_SUCCESS, fixtures });
//         } else {
//           dispatch({ type: types.GET_FIXTURES_FAILURE, fixtures });
//         }
//       })
//       .catch((error) => {
//         dispatch({ type: types.GET_FIXTURES_FAILURE, fixtures });
//       });
//   };
// }

import _ from 'lodash';
import axios from 'axios';
import defaultConfig from '../constants/defaultConfig';
import * as types from '../constants/actionTypes';

export function getFixtures(competitionId, season) {
  return (dispatch) => {
    const fixtures = {competitionId, season};
    const url = `${defaultConfig.infinitOptaBaseUrl}/Sports/Soccer/fixture/fixture_${season}_${competitionId}.json`;

    dispatch({type: types.GET_FIXTURES_INPROGRESS, fixtures});

    axios.get(url)
      .then((res) => {
        const isSuccess = (_.get(res, 'data.result[0].statuscode', 'N') === 'Y');

        if (isSuccess) {
          fixtures.rows = _.get(res, 'data.APIinfo[0].FixtureInfo', []);
          dispatch({type: types.GET_FIXTURES_SUCCESS, fixtures});
        } else {
          dispatch({type: types.GET_FIXTURES_FAILURE, fixtures});
        }

      })
      .catch((error) => {
        dispatch({type: types.GET_FIXTURES_FAILURE, fixtures});
      })
  }
}
