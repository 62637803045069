import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import FooterMegaFooter from './FooterMegaFooter';

const getYear = () => {
  const year = moment().format('YYYY');
  return year;
};

const renderLinks = (links) => {
  const lastIndex = links.length - 1;
  return links.map((link, key) => {
    const props = {
      target: `_blank`,
      className: `text-white leading-1.14 text-dark-grey text-sm no-underline lg:underline ${
        key !== lastIndex ? 'mr-32' : ''
      }`,
      key,
    };
    return React.cloneElement(link, props);
  });
};

const Footer = ({
  websiteName,
  copyRight,
  maxWidth,
  links,
  children,
  className,
  hideMegaFooter,
  hideDownloadApps,
  newHome,
  ...props
}) => {
  let footerBottomLinks = (
    <div
      className={`bg-dark-grey flex flex-no-wrap flex-col px-20 sm:px-32 lg:px-0 lg:flex-row lg:justify-between ${
        newHome ? 'xl:min-w-1204' : 'w-full'
      } ${className ? ` ${className}` : ''}`}
      {...props}
    >
      <div
        className={`
      flex
      flex-col
      xs:flex-row
      flex-no-wrap
      justify-between
      ${newHome ? '' : 'lg:pl-118'}
      ${newHome ? '' : 'lg:pl-118'}
    `}
      >
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://optus.com.au/"
          className="text-teal leading-1.14 font-MarkProHeavy text-sm ml-8 sm:ml-14 mt-16 lg:ml-0 no-underline os-transition-btn hover:text-teal-dark active:text-teal-light"
        >
          {websiteName}
        </a>
        <div className="flex flex-no-wrap lg:ml-45 mr-8 sm:mr-14 mt-16">
          {renderLinks(links)}
        </div>
      </div>
      <p
        className={`text-sm leading-1.14 text-dark-grey text-center mt-24 sm:text-left lg:mt-16 mb-16 sm:ml-14 lg:inline-block ${
          newHome ? '' : 'lg:pr-118'
        }`}
      >{`© ${getYear()} ${copyRight}`}</p>
    </div>
  );

  if (newHome) {
    footerBottomLinks = (
      <div className="pin-b pin-l w-full bg-dark-grey lg:flex lg:justify-center new-home-footer-links">
        {footerBottomLinks}
      </div>
    );
  }
  return (
    <footer
      className={`w-full bg-black block pt-40 lg:pt-96 xl:flex xl:flex-wrap xl:flex-column justify-center ${className}`}
    >
      {!hideMegaFooter && (
        <FooterMegaFooter
          hideDownloadApps={hideDownloadApps}
          newHome={newHome}
        />
      )}
      {footerBottomLinks}
    </footer>
  );
};

Footer.propTypes = {
  /** Copyright text to show in the footer.**/
  copyRight: PropTypes.string,
  /** Text to show at the location of website.**/
  websiteName: PropTypes.string,
  /** maxWidth supports a Tailwind width class passed on as string.**/
  maxWidth: PropTypes.string,
  /** Links to add on to the footer links area.**/
  links: PropTypes.node,
  /** Hide/Show the FooterMegaFooter **/
  hideMegaFooter: PropTypes.bool,
  /** Hide/Show the Download the Apps section on tablet/mobile devices **/
  hideDownloadApps: PropTypes.bool,
};

Footer.defaultProps = {
  websiteName: 'optus.com.au',
  copyRight: 'Singtel Optus Pty Limited',
  maxWidth: 'max-w-lg',
  links: [],
  hideMegaFooter: false,
  hideDownloadApps: true,
  className: '',
};

export default Footer;
