import _ from 'lodash';
import * as types from '../constants/actionTypes';
import defaultConfig from 'constants/defaultConfig';
import API from '@aws-amplify/api';

export function getTeams(competitionId, season, teamId) {
  return (dispatch) => {
    const teams = { competitionId, season };
    const {
      VCMSConfig: { allTeamsUrl, competitions },
    } = defaultConfig;
    dispatch({ type: types.GET_TEAMS_INPROGRESS, teams });
    const validCompetitionIds = _.map(competitions, 'competitionId');

    API.get('fe-api-dev-metadataGetPage', allTeamsUrl, {})
      .then((res) => {
        const teams = _.get(res, 'teams', {});
        if (!_.isEmpty(teams)) {
          const validTeams = teams.filter((team) => {
            let teamCompetitions = team.competitions;
            teamCompetitions = teamCompetitions.filter((tc) =>
              validCompetitionIds.includes(Number(tc.optaId)),
            );
            team.competitions = teamCompetitions;

            // Transform for backwards compatibility from VCMS <-> Infinit
            const teamId = team.TeamID || team.optaId;
            const teamShortName = team.SYMID || team.shortCode;
            _.set(team, 'TeamID', teamId);
            _.set(team, 'SYMID', teamShortName);

            return teamCompetitions.length > 0;
          });

          teams.rows = validTeams;
          dispatch({ type: types.GET_TEAMS_SUCCESS, teams });
        } else {
          dispatch({ type: types.GET_ALLTEAMS_FAILURE, teams });
        }
      })
      .catch((error) => {
        dispatch({ type: types.GET_TEAMS_FAILURE, teams });
      });
  };
}
