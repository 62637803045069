import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { triggerGoogleAnalyticsEvent } from 'utils';
import BackIcon from '../Icons/BackIcon';
import HamIcon from '../Icons/HamIcon';
import LogoIcon from '../Icons/LogoIcon';
import AccountIcon from '../Icons/AccountIcon';
import NotificationIndicator from '../Chat-UI/NotificationIndicator';
import UserDropDown from '../UserDropDown';
import { disableBGScroll } from '../../../../utils/helper';
import HeaderNavBack from '../HeaderNavBack';
import { debounce } from '../../../../utils/debounce';
import { isFitnessVOD } from '../../../../utils/getAssetData';
import LiveTvIcon from '../Icons/LiveTvIcon';
import SearchIcon from '../Icons/SearchIcon';
import analytics from './analytics';

class HeaderNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSlider: false,
      showUserDown: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.showSlideHandler);

    // Only create interval check when window width equals or greater than xl (1200px)
    if (window.innerWidth >= 1200) {
      this.checkSlideHandlerInterval = window.setInterval(() => {
        const navWidth = _.get(this.fullNavList, 'scrollWidth');
        // Issue where the initial width will be as less as 40px before expanding out
        if (navWidth > 40) {
          window.clearInterval(this.checkSlideHandlerInterval);
          this.showSlideHandler();
        }
      }, 500);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.showSlideHandler);
  }

  componentWillReceiveProps(nextProps) {
    const { isShowSearchSectionInNavbar: currentSearchActive } = this.props;
    const { isShowSearchSectionInNavbar: nextSearchActive } = nextProps;

    // We need to refresh the nav slider when search box is closed in case user
    // has somehow resized his browser while search box is active
    if (currentSearchActive && currentSearchActive !== nextSearchActive) {
      this.showSlideHandler();
    }
  }

  showSlideHandler = debounce(() => {
    const { userName } = this.props;
    const navWidth = _.get(this.fullNavList, 'scrollWidth');

    if (navWidth > 0) {
      this.setState({
        showSlider: !(window.innerWidth - (userName ? 322 : 382) > navWidth),
      });
    }
  }, 250);

  callbackHandler = (callback, e) =>
    typeof callback === 'function' ? callback(e) : '';

  scrollTo = (direction) => {
    const navList = this.navList;
    this.sideScroll(navList, direction, 25, 150, 10);
  };

  sideScroll(element, direction, speed, distance, step) {
    let scrollAmount = 0;
    let slideTimer = setInterval(() => {
      if (direction === 'left') {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  signupClickHandler(e) {
    if (isFitnessVOD(this.props.asset?.data)) {
      const analyticsData = analytics.sign_up_button;
      triggerGoogleAnalyticsEvent(
        analyticsData.category,
        analyticsData.action,
        analyticsData.label,
      );
    }
    this.props.signupClick(e);
  }

  userLoginHandler(e) {
    if (isFitnessVOD(this.props.asset?.data)) {
      const analyticsData = analytics.log_in_button;
      triggerGoogleAnalyticsEvent(
        analyticsData.category,
        analyticsData.action,
        analyticsData.label,
      );
    }
    this.props.userLogin(e);
  }

  render() {
    const dropDownCss =
      'cursor-pointer h-full flex justify-center items-center hover:text-teal sm:relative md:mx-8';
    const { showSlider } = this.state;
    const {
      notificationActive,
      notificationValue,
      userName,
      children,
      notificationLeave,
      notificationHover,
      hamClick,
      hamHover,
      hamLeave,
      notificationDropDown,
      simpleHeader,
      showBack,
      onBackClicked,
      handleLiveChannels,
      backLabel,
      oneThirdWidth,
      customClassNames,
      active,
      toggleSearchSection,
      isShowSearchSectionInNavbar,
      searchEnabled,
      textClass,
    } = this.props;

    // Required to centre align the in nav menu search bar due to differing widths of
    // left logo area and right user actions area
    // As of 17-Feb-2021, right side is always larger than left side
    const userActionRefWidth =
      this.userActionRef && this.userActionRef.offsetWidth;
    const logoItemRefWidth = this.logoItemRef && this.logoItemRef.offsetWidth;
    const diffWidth = userActionRefWidth - logoItemRefWidth;

    return (
      <>
        {/* dummy block used to calculate actual width of navigation items */}
        {!simpleHeader && (
          <ul
            ref={(node) => (this.fullNavList = node)}
            className='flex flex-grow absolute leading-base invisible overflow-x-scroll'
          >
            {children}
          </ul>
        )}
        <div
          className={`pr-16 w-full h-48 bg-dark-grey flex flex-no-wrap items-center relative ${
            !oneThirdWidth ? 'justify-center' : 'justify-start'
          } xl:justify-start border-b border-medium-dark-grey ${customClassNames}`}
        >
          {!simpleHeader && (
            <div className='text-white xl:hidden cursor-pointer absolute pin-l'>
              <HamIcon
                onMouseOver={(e) => this.callbackHandler(hamHover, e)}
                onMouseLeave={(e) => this.callbackHandler(hamLeave, e)}
                onClick={(e) => {
                  disableBGScroll();
                  this.callbackHandler(hamClick, e);
                }}
              />
            </div>
          )}
          {/* {showBack ? <HeaderNavBack onBackClicked={onBackClicked} label={backLabel} /> : null} */}
          {showBack ? (
            <>
              <HeaderNavBack
                onBackClicked={onBackClicked}
                label={backLabel}
                oneThirdWidth={oneThirdWidth}
                textClass={textClass}
              />
              {children}
            </>
          ) : (
            <Link
              to='/'
              style={
                isShowSearchSectionInNavbar && diffWidth > 0
                  ? { marginRight: `${diffWidth}px` }
                  : null
              }
              className={`
                h-48
                ${
                  active
                    ? 'border-transparent xl:border-teal'
                    : 'border-transparent'
                }
                ${simpleHeader ? 'm-auto' : 'xl:ml-16 xl:mr-0'}
                os-transition hover:border-teal border-b-4 font-MarkProBold pb-12 pt-16 pl-16 pr-10 sm:text-center
              `}
              ref={(logoItem) => (this.logoItemRef = logoItem)}
            >
              <LogoIcon />
            </Link>
          )}
          {!simpleHeader && (
            <>
              {!searchEnabled || !isShowSearchSectionInNavbar ? (
                <div
                  className={`hidden xl:block relative text-center px-24 ${
                    showSlider ? 'nav-slider-container' : ''
                  } `}
                >
                  {showSlider ? (
                    <React.Fragment>
                      <BackIcon
                        className='hidden xl:block absolute icon-left hover:text-teal cursor-pointer pin-l z-3'
                        color='white'
                        onClick={() => this.scrollTo('left')}
                      />
                      <BackIcon
                        className='hidden xl:block absolute icon-right hover:text-teal cursor-pointer pin-r z-3'
                        color='white'
                        onClick={() => this.scrollTo('right')}
                      />
                    </React.Fragment>
                  ) : null}
                  <ul
                    className={`hidden xl:flex flex-grow p-0 items-center overflow-hidden relative leading-base ${
                      showSlider
                        ? !userName
                          ? `
                                max-w-704
                                xxl:max-w-lg
                                xxxl:max-w-xl
                                xxxxl:max-w-1024
                                xxxxxl:max-w-2xl
                                xxxxxxl:max-w-1216
                                xxxxxxxl:max-w-1312
                                xxxxxxxxl:max-w-1408
                              `
                          : `
                                max-w-768
                                xxl:max-w-896
                                xxxl:max-w-1024
                                xxxxl:max-w-1088
                                xxxxxl:max-w-1184
                                xxxxxxl:max-w-3xl
                                xxxxxxxl:max-w-1376
                                xxxxxxxxl:max-w-1472
                              `
                        : ''
                    }`}
                    ref={(node) => (this.navList = node)}
                  >
                    {children}
                  </ul>
                </div>
              ) : (
                children
              )}

              <div
                className={`
                  flex
                  flex-no-wrap
                  justify-center
                  items-center
                  xl:mr-16
                  absolute
                  ml-0
                  ${isShowSearchSectionInNavbar ? 'xl:relative' : ''}
                  pin-r
                  h-full
                `}
                ref={(userAction) => (this.userActionRef = userAction)}
              >
                {searchEnabled ? (
                  <div
                    onClick={(e) =>
                      this.callbackHandler(toggleSearchSection, e)
                    }
                    className='flex flex-no-wrap text-white hover:text-teal items-center cursor-pointer md:mx-8 ml-48'
                  >
                    <SearchIcon />
                  </div>
                ) : null}
                {userName && (
                  <div
                    className={`
                      cursor-pointer
                      flex-no-wrap
                      text-white
                      hover:text-teal
                      items-center
                      lg:px-8
                      ${searchEnabled ? `hidden xs:flex` : `flex`}
                    `}
                    onClick={(e) => this.callbackHandler(handleLiveChannels, e)}
                  >
                    <LiveTvIcon />
                  </div>
                )}
                {userName && (
                  <div
                    className={dropDownCss}
                    onMouseOver={(e) =>
                      this.callbackHandler(notificationHover, e)
                    }
                    onMouseLeave={(e) =>
                      this.callbackHandler(notificationLeave, e)
                    }
                  >
                    <NotificationIndicator
                      active={notificationActive}
                      value={notificationValue}
                    />
                    {notificationDropDown}
                  </div>
                )}
                {!userName && (
                  <button
                    className='hidden xl:block ml-8 focus:outline-none bg-medium-grey
                  hover:bg-teal hover:text-black py-8 px-38 text-sm
                  font-MarkProHeavy leading-base
                  text-white active:os-ty-4 os-transition-btn rounded-4xl'
                    onClick={(e) =>
                      this.callbackHandler(
                        this.signupClickHandler.bind(this),
                        e,
                      )
                    }
                  >
                    Sign Up
                  </button>
                )}
                <div
                  className={dropDownCss}
                  onClick={(e) =>
                    !userName &&
                    this.callbackHandler(this.userLoginHandler.bind(this), e)
                  }
                  onMouseOver={(e) => this.setState({ showUserDown: true })}
                  onMouseLeave={(e) => this.setState({ showUserDown: false })}
                >
                  <div className='flex flex-no-wrap text-white hover:text-teal ml-7 items-center'>
                    <AccountIcon />
                    <p className='text-sm font-MarkProHeavy hidden xl:block'>
                      {/* Remove userName display as search icon taking too much real estate */}
                      {!userName && 'Login'}
                    </p>
                    {userName && (
                      <UserDropDown
                        open={this.state.showUserDown}
                        onUserLogout={this.props.userLogout}
                      />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

HeaderNav.propTypes = {
  /** Whether notification is available for this user. If this is set to true, userName is a required property*/
  notificationActive: PropTypes.bool,
  /** Number of notifications available for this user in case there are notifications. */
  notificationValue: PropTypes.number,
  /** Name to show in case the user is logged in */
  userName: PropTypes.string,
  /** Event handler for notificationIconHover event */
  notificationHover: PropTypes.func,
  /** Event handler for notificationIconLeave event */
  notificationLeave: PropTypes.func,
  /** notification drop down react component */
  notificationDropDown: PropTypes.object,
  /** Event handler for user login click event */
  userLogin: PropTypes.func,
  /** Event handler for user logout click event */
  userLogout: PropTypes.func,
  /** Event handler for sign up button click event */
  signupClick: PropTypes.func,
  /** Event handler for hamburger button click event */
  hamClick: PropTypes.func,
  /** Event handler for hamburger button click event */
  hamHover: PropTypes.func,
  /** Event handler for hamburger button click event */
  hamLeave: PropTypes.func,
  /** Child link nodes */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showBack: PropTypes.bool,
  /** Event handler for Live Channels click event */
  handleLiveChannels: PropTypes.func,
  /** Flag for the component's width */
  oneThirdWidth: PropTypes.bool,
  /** Additional class names */
  customClassNames: PropTypes.string,
  /** Flag to make NavBar active */
  active: PropTypes.bool,
  /** Event handler for search icon click event **/
  toggleSearchSection: PropTypes.func,
  /** Flag to display search box **/
  isShowSearchSectionInNavbar: PropTypes.bool,
  /** Flag to display search icon **/
  searchEnabled: PropTypes.bool,
  /** Font styling class **/
  textClass: PropTypes.string,
};

HeaderNav.defaultProps = {
  notificationActive: false,
  notificationValue: null,
  notificationDropDown: null,
  userName: null,
  showBack: false,
  oneThirdWidth: false,
  customClassNames: '',
  active: false,
  toggleSearchSection: () => {},
  isShowSearchSectionInNavbar: false,
  searchEnabled: false,
  textClass: 'text-white hover:text-teal',
};

function mapStateToProps(state, ownProps) {
  return {
    asset: state.asset,
  };
}

export default connect(mapStateToProps)(HeaderNav);
