import _ from 'lodash';
import { start, success, fail, apiAction } from '../middleware/fetch';

export const ACCOUNT_CREATE = `account/ACCOUNT_CREATE`;

export const userTypes = {
  basic: `basic`,
  premium: `premium`,
};

export const DEFAULT_USER_TYPE = userTypes.basic;

const initialState = {
  error: null,
  created: null,
};

function getDeviceInfo() {
  return {
    // For now, do not do browser fingerprinting.
    deviceId: window.navigator.userAgent,
    userAgent: window.navigator.userAgent,
  };
}

// Moved (copied) to selectors
export function isLoggedIn(user) {
  return user && user.loggedIn;
}

export function isEmailValid(user) {
  return _.get(user, `validateEmail.isSuccess`);
}

export function getUserType(user) {
  const { userType } = user || {};
  if (!userType?.updated) {
    return null;
  }
  const type = userType.type;
  return userTypes[type] || DEFAULT_USER_TYPE;
}

// TODO: Could've used `userReducer` but it already exists.
// So for this case, use `account` instead.
export default function accountReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case start(ACCOUNT_CREATE): {
      return {
        ...state,
        error: null,
        created: null,
      };
    }
    case success(ACCOUNT_CREATE): {
      return {
        ...state,
        error: null,
        created: true,
      };
    }
    case fail(ACCOUNT_CREATE): {
      return {
        ...state,
        error: payload || { description: type },
        created: false,
      };
    }
    default:
      return state;
  }
}

export function createAccount(userInfo) {
  return apiAction(ACCOUNT_CREATE, `fe-api-createAccount`, {
    method: `post`,
    body: {
      ...getDeviceInfo(),
      ...userInfo,
    },
  });
}

export function legacyCreateUser(userInfo) {
  return apiAction(ACCOUNT_CREATE, `fe-api-createUser`, {
    method: `post`,
    body: {
      notifyUserOnCreation: `NONE`,
      ...userInfo,
    },
  });
}
