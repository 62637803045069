import defaultConfig from '../../constants/defaultConfig';
import { getBrowser, getDevice, getOS } from '../../utils/userAgent';
import * as videoService from '../video';

export function getAppInfo() {
  const {
    defaultPlatform: platform,
    advertConsent,
    name: appName = 'optus-sport-portal',
    env: environment = '',
    version: appVersion = ''
  } = defaultConfig;

  return {
    appName,
    platform,
    environment,
    appVersion,
    advertConsent
  };
}

export function getDeviceInfo() {
  const { deviceId } = getDevice();
  const { version: OSVersion = '' } = getOS();
  const { name: browserName = '', version: browserVersion = '' } = getBrowser();

  return {
    deviceId,
    OSVersion,
    browserName,
    browserVersion
  };
}

export function getAssetInfo(data, playerConfig = {}) {
  const { drm = {} } = videoService.getPlaylistSource(playerConfig);
  const drmLicenseUrl =
    (Object.keys(drm) || []).map(key => {
      const item = drm[key];
      return item.url;
    })[0] || '';

  const streamUrl = videoService.getSourceStreamUrl(playerConfig);
  const { id: assetId, title: assetTitle } = data;

  return {
    assetId,
    assetTitle,
    streamUrl,
    drmLicenseUrl
  };
}

export function getUserInfo(data) {
  const {
    analytics: { analyticUserId: userID } = {},
    userTags,
    userType: { type: userType } = {}
  } = data;

  return {
    userID,
    userTags,
    userType
  };
}

export function getCommonProps({ player, getProps, getState, playerConfig }) {
  const {
    asset: { data: assetData },
    user: userData
  } = getProps();
  const app = getAppInfo(userData);
  const device = getDeviceInfo(userData);
  const user = getUserInfo(userData);
  const asset = getAssetInfo(assetData, playerConfig);

  return {
    app,
    device,
    user,
    asset
  };
}
