import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import DeviceTypes from './Icons/DeviceTypes';
import VendorButton from './VendorButton';
import { getDevice } from '../../../utils/userAgent';
import { initializeGoogleAnalytics, triggerGoogleAnalyticsEvent } from 'utils';

class FooterWidget extends Component {
  componentDidMount() {
    initializeGoogleAnalytics();
  }

  renderWidgetContent(type) {
    const footerLinkClass =
      'font-MarkProBold text-sm xl:text-base no-underline cursor-pointer text-teal os-transition-btn hover:text-teal-dark active:text-teal-light';
    if (type === 'appstore-links') {
      const userDevice = getDevice();
      const deviceType = get(userDevice, 'type', 'PC');

      const downloadAppUrl =
        deviceType === 'PC'
          ? 'https://itunes.apple.com/au/app/optus-sport/id1113368382?mt=8'
          : 'https://c5pq6.app.goo.gl/?link=https://sport.optus.com.au&apn=au.com.optus.sport&isi=1113368382&ibi=au.com.optus.epl&ius=optussport';
      const downloadPlayStoreUrl =
        deviceType === 'PC'
          ? 'http://play.google.com/store/apps/details?id=au.com.optus.sport'
          : 'https://c5pq6.app.goo.gl/?link=https://sport.optus.com.au&apn=au.com.optus.sport&isi=1113368382&ibi=au.com.optus.epl&ius=optussport';

      return (
        <div className='widget-content xl:ml-80'>
          <div className='flex justify-center md:justify-start md:pr-30'>
            <div className='max-w-325 xl:max-w-366'>
              <DeviceTypes />
            </div>
          </div>

          <div className='flex flex-no-wrap justify-center xl:justify-start my-16'>
            <VendorButton
              link={downloadAppUrl}
              className='w-100pminus3 md:w-100pminus5 mr-3 md:mr-5 max-w-180 rounded-full'
              type='apple'
            />

            <VendorButton
              link={downloadPlayStoreUrl}
              className='w-100pminus3 md:w-100pminus5 ml-3 md:ml-5 max-w-180 rounded-full'
            />
          </div>
        </div>
      );
    } else if (type === 'os-links') {
      const { history } = this.props;
      return (
        <div className='widget-content mb-32 xl:pt-16'>
          <ul className='list-reset m-0 p-0'>
            <li className='mb-16 xl:mb-40'>
              <a href='/news' className={footerLinkClass}>
                News &amp; Articles
              </a>
            </li>

            <li className='mb-16 xl:mb-40'>
              <a
                href='/fitness'
                className={footerLinkClass}
                onClick={(e) => {
                  e.preventDefault();
                  triggerGoogleAnalyticsEvent(
                    'Footer',
                    'click',
                    'Fitness Button',
                  );
                  history.push('/fitness');
                }}
              >
                Fitness
              </a>
            </li>

            <li className='mb-16 xl:mb-40'>
              <a
                href='/fitnessonoptussport'
                className={footerLinkClass}
                onClick={(e) => {
                  e.preventDefault();
                  triggerGoogleAnalyticsEvent(
                    'Footer',
                    'click',
                    'What is Fitness Button',
                  );
                  history.push('/fitnessonoptussport');
                }}
              >
                What is Fitness?
              </a>
            </li>

            <li className='mb-16 xl:mb-40'>
              <a href='/laliga' className={footerLinkClass}>
                LaLiga
              </a>
            </li>

            <li className='mb-16 xl:mb-40'>
              <a href='/epl' className={footerLinkClass}>
                Premier League
              </a>
            </li>

            <li className='mb-16 xl:mb-40'>
              <a href='/womens' className={footerLinkClass}>
                FA Women's Super League
              </a>
            </li>

            <li className='mb-16 xl:mb-40'>
              <a href='/womens-world-cup-2023' className={footerLinkClass}>
                FIFA Women's World Cup 2023
              </a>
            </li>

            <li className='mb-16 xl:mb-40'>
              <a href='/j-league' className={footerLinkClass}>
                J.League
              </a>
            </li>

            <li className='mb-16 xl:mb-40'>
              <a href='/internationals' className={footerLinkClass}>
                Internationals
              </a>
            </li>

            <li className='mb-16 xl:mb-40'>
              <a href='/dfb-pokal' className={footerLinkClass}>
                DFB Pokal
              </a>
            </li>
          </ul>
        </div>
      );
    } else if (type === 'help-links') {
      return (
        <div className='widget-content mb-32 xl:pt-16'>
          <ul className='list-reset m-0 p-0 block'>
            <li className='mb-16 xl:mb-40 w-1/2 inline-block'>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.optus.com.au/for-you/support/answer?id=7048'
                className={`${footerLinkClass} leading-1.14 xl:leading-normal`}
              >
                Getting Started
              </a>
            </li>

            <li className='mb-16 xl:mb-40 w-1/2 inline-block'>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.optus.com.au/for-you/support/answer?id=6989'
                className={`${footerLinkClass} leading-1.14 xl:leading-normal`}
              >
                How to Watch Optus Sport
              </a>
            </li>

            <li className='mb-16 xl:mb-40 w-1/2 inline-block'>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.optus.com.au/for-you/support/answer?id=20213'
                className={`${footerLinkClass} leading-1.14 xl:leading-normal`}
              >
                How to Watch in Full HD
              </a>
            </li>

            <li className='mb-16 xl:mb-40 w-1/2 inline-block'>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='/help'
                className={`${footerLinkClass} leading-1.14 xl:leading-normal`}
              >
                Report an Issue
              </a>
            </li>

            <li className='mb-16 xl:mb-40 w-1/2 inline-block'>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='/usersettings'
                className={`${footerLinkClass} leading-1.14 xl:leading-normal`}
              >
                Manage Account
              </a>
            </li>

            <li className='mb-16 xl:mb-40 w-1/2 inline-block'>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.optus.com.au/for-you/support/entertainment/optus-sport-hub'
                className={`${footerLinkClass} leading-1.14 xl:leading-normal`}
              >
                Frequently Asked Questions
              </a>
            </li>
          </ul>
        </div>
      );
    } else if (type === 'advertise-links') {
      return (
        <div className='widget-content mb-32 xl:pt-16'>
          <ul className='list-reset m-0 p-0'>
            <li className='mb-16 xl:mb-40'>
              <a href='/advertise' className={footerLinkClass}>
                Advertise on Optus Sport
              </a>
            </li>
            <li className='mb-16 xl:mb-40'>
              <a href='/venues' className={footerLinkClass}>
                Optus Sport in Your Venue
              </a>
            </li>
          </ul>
        </div>
      );
    }
    return null;
  }

  renderWidgetFooter() {
    return (
      <footer className='widget-footer flex justify-center xl:mb-24 xl:justify-start xl:ml-200 xl:pl-20'>
        <a
          href='https://www.optus.com.au/for-you/entertainment/sport/optus-sport'
          target='_blank'
          rel='noopener noreferrer'
          className='text-teal font-MarkProHeavy text-sm no-underline os-transition-btn hover:text-teal-dark active:text-teal-light'
        >
          Find Out More
        </a>
      </footer>
    );
  }

  render() {
    return (
      <aside className='footer-widget'>
        <header className='widget-header mb-24'>
          <h2
            className={`widget-title font-MarkProHeavy text-xl md:text-xl text-white ${this.props.widgettype === 'appstore-links'
                ? 'text-center md:text-left xl:ml-80'
                : 'text-left'
              }`}
          >
            {this.props.title}
          </h2>
        </header>

        {this.renderWidgetContent(this.props.widgettype)}

        {this.props.widgettype === 'appstore-links'
          ? this.renderWidgetFooter()
          : null}
      </aside>
    );
  }
}

FooterWidget.propTypes = {
  /** Title for the widget.**/
  title: PropTypes.string.isRequired,
  /** Widget type.**/
  widgettype: PropTypes.oneOf([
    'appstore-links',
    'os-links',
    'help-links',
    'advertise-links',
  ]).isRequired,
};

FooterWidget.defaultProps = {
  title: 'Footer Widget Title',
  widgettype: 'appstore-links',
};

export default withRouter(FooterWidget);
