
const jwErrorConfig = {
  '24': {
    '011': {
      'isPlaying': {
        'message': 'Unfortunately, you are unable to access this content. If you believe this is an error, please contact Optus Sport Support.',
        'retryAllow': true
      },
      'message': 'Unfortunately, you are unable to access this content. This may be because you are using a VPN or trying to access the video from overseas. If you believe this is an error, please contact Optus Sport Support.',
      'retryAllow': true
    },
    '403': {
      'message': 'Unfortunately, you are unable to access this content. This may be because you are using a VPN or trying to access the video from overseas. If you believe this is an error, please contact Optus Sport Support.',
      'retryAllow': true
    },
    '500': {
      'message': 'Unfortunately, you are unable to access this content. If you believe this is an error, please contact Optus Sport Support.',
      'retryAllow': true
    },
    '502': {
      'message': 'Unfortunately, you are unable to access this content. If you believe this is an error, please contact Optus Sport Support.',
      'retryAllow': true
    },
    '503': {
      'message': 'Unfortunately, you are unable to access this content. If you believe this is an error, please contact Optus Sport Support.',
      'retryAllow': true
    },
    '504': {
      'message': 'Unfortunately, you are unable to access this content. If you believe this is an error, please contact Optus Sport Support.',
      'retryAllow': true
    }
  },
  '22': {
    '003': {
      'message': 'Unfortunately, you are unable to access this content. If you believe this is an error, please contact Optus Sport Support.',
      'retryAllow': true
    },
    // Live stream stalls timeout
    '001': {
      'message': 'Unfortunately, you are unable to access this content. If you believe this is an error, please contact Optus Sport Support.',
      'retryAllow': true
    }
  },
  'CHUCK_NORRIS': {
    'message': 'You have exceeded the maximum number of concurrent streams. Playback is stopped.',
    'retryAllow': true
  },
  'ZOMBIE_STREAM_KICK': {
    'message': 'You have exceeded the maximum stream session time. Playback is stopped.',
    'retryAllow': true
  },
  '102630': {
    'isPlaying': {
      'message': 'Unfortunately, you are unable to access this content. If you believe this is an error, please contact Optus Sport Support.',
      'retryAllow': true
    },
    'message': 'Unfortunately, you are unable to access this content. To view this content on your mobile device or tablet please download the Optus Sport app for iOS or Android. If you believe this is an error, please contact Optus Sport Support.',
    'retryAllow': false
  },
  '246000': {
    'title': 'Update your browser',
    'message': 'It looks like the browser you are using is not supported for video playback. Please update your browser to play video on the Optus Sport website, or use our app for a better experience.',
    'retryAllow': false,
    'ctaButton': {
      'text': 'Learn more',
      'link': 'https://www.optus.com.au/for-you/support/answer?id=6989',
    },
  },
  'default': {
    'title': 'Oops!',
    'message': 'Sorry, you are unable to access this content. If you believe this is an error, please contact Optus Sport Support.',
    'retryAllow': true
  }
};

export default jwErrorConfig;
