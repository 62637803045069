export const getCompetition = (competitonID) => {
  switch (competitonID) {
    case 8:
      return 'epl';
    case 5:
      return 'uefa-champions-league';
    case 6:
      return 'uefa-europa-league';
    case 941:
    case 336:
    case 642:
    case 1166:
      return 'internationals';
    case 550:
      return 'womens';
    case 335:
      return 'fifa-club-world-cup';
    case 20:
      return 'j-league';
    case 3:
    case 235:
      return 'uefa-euro-2020';
    case 205:
      return 'k-league';
    case 128:
      return 'copa-america';
    case 23:
      return 'laliga';
    default:
      return 'epl';
  }
};
