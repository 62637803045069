import API from '@aws-amplify/api';
import * as types from '../constants/actionTypes';

export function createTicket(userId, tickets) {
  return (dispatch) => {
    let option = {
      body: tickets
    };

    dispatch({type: types.CREATE_TICKET_INPROGRESS});

    API.post('fe-api-dev-ticketCreateTicket', `/users/${userId}`, option)
      .then((result) => {
        dispatch({type: types.CREATE_TICKET_SUCCESS});
      })
      .catch((error) => {
        dispatch({type: types.CREATE_TICKET_FAILURE});
      })
  }
}

export function getAllTickets(userId) {
  return (dispatch) => {
    dispatch({type: types.GET_ALL_TICKETS_INPROGRESS, userId: userId});

    API.get('fe-api-dev-ticketGetTickets', `/tickets/users/${userId}`, {
      queryStringParameters: {
        'ts': Date.now()
      }
    })
      .then((result) => {
        if (Array.isArray(result.records)) {
          dispatch({type: types.GET_ALL_TICKETS_SUCCESS, tickets: result.records});
        } else {
          dispatch({type: types.GET_ALL_TICKETS_SUCCESS, tickets: []});
        }
      })
      .catch((error) => {
        dispatch({type: types.GET_ALL_TICKETS_FAILURE});
      })
  }
}

export function getAllComments(userId, ticketId) {
  return (dispatch) => {
    dispatch({type: types.GET_ALL_COMMENTS_INPROGRESS, ticketId: ticketId});

    API.get('fe-api-dev-ticketGetTicketComments', `/comments/users/${userId}/tickets/${ticketId}`, {
      queryStringParameters: {
        'ts': Date.now()
      }
    })
      .then((result) => {
        dispatch({type: types.GET_ALL_COMMENTS_SUCCESS, currentTicketComments: result});
      })
      .catch((error) => {
        dispatch({type: types.GET_ALL_COMMENTS_FAILURE});
      })
  }
}

export function createComment(userId, ticketId, comments, attachments) {
  return (dispatch) => {
    let option = {
      body: {
        caseNumber: ticketId,
        comments: comments,
        attachments: attachments
      }
    };

    dispatch({type: types.CREATE_COMMENT_INPROGRESS});

    API.post('fe-api-dev-ticketCreateTicketComment', `/comment/users/${userId}/tickets/${ticketId}`, option)
      .then((result) => {
        dispatch({type: types.CREATE_COMMENT_SUCCESS});
      })
      .catch((error) => {
        dispatch({type: types.CREATE_COMMENT_FAILURE});
      })
  }
}

export function clearAllTickets() {
  return (dispatch) => {
    dispatch({type: types.CLEAR_ALL_TICKETS});
  }
}

export function clearAllComments() {
  return (dispatch) => {
    dispatch({type: types.CLEAR_ALL_COMMENTS});
  }
}

