import React, { Component } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import defaultConfig from '../../constants/defaultConfig';
import { getAbsoluteStaticUrl } from 'utils/path';

class RouteWithReactHelmet extends Component {
  isExcludedPath = (path = '') => {
    const urlParts = path.split('/');
    const urlPart1 = _.get(urlParts, '[1]', '');
    const urlPart2 = _.get(urlParts, '[2]', '');
    const { routeWithoutReactHelmet } = defaultConfig;
    return (
      routeWithoutReactHelmet.includes(urlPart1) ||
      routeWithoutReactHelmet.includes(urlPart2)
    );
  };

  isFitnessPath = (path = '') => {
    const urlParts = path.split('/');
    const urlPart1 = _.get(urlParts, '[1]', '');
    return urlPart1 === 'fitness';
  };

  render() {
    const pathname = _.get(window, 'location.pathname', '');
    const imageName = this.isFitnessPath(pathname)
      ? 'OS_OG_fitness_image.jpg'
      : 'OS_OG_image_aug_2022.png';
    const isExcludedPath = this.isExcludedPath(pathname);
    return !isExcludedPath ? (
      <>
        <Helmet>
          <link rel='canonical' href={_.get(window, 'location.href', '')} />
          <meta property='og:type' content='website' />
          <meta
            property='og:image'
            content={getAbsoluteStaticUrl(`/images/${imageName}`)}
          />
          <meta
            property='og:image:secure_url'
            content={getAbsoluteStaticUrl(`/images/${imageName}`)}
          />
        </Helmet>
      </>
    ) : null;
  }
}

export default withRouter(RouteWithReactHelmet);
