import moment from 'moment/moment';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTimezoneAbbr } from '../../../../../utils/timezone';
import AssetImage from './AssetImage';
import { TimeString } from './TimeString';
import { getCategoryTitle } from '../../utils';
import {
  isEditorialAssetGame,
  getFormattedDateTimeString,
  LOCALE_TIMEZONE_FORMAT_LLLL,
} from '../../../../../utils';
import styles from './styles';

export class AssetInfoBox extends Component {
  render() {
    const {
      name,
      title,
      categoryTitle,
      timeString,
      broadcastDateString,
      theme = 'dark',
      themeType,
    } = this.props;

    const selectedTheme = themeType
      ? styles[theme][themeType]
      : styles[theme]?.default;
    const videoImage = <AssetImage {...this.props} />;
    return (
      <div
        className={`rounded-xl video-info-box relative ${selectedTheme?.classes} px-16 pb-10 pt-56-25%`}
      >
        <div
          className={`
          sub-content
          md:flex
          xs:flex-col
        `}
        >
          <div className='category-title flex justify-between items-center pr-10 pb-4 pt-16'>
            <div className='font-MarkProBold text-12px leading-16px block text-left relative xxs:pb-2'>
              <div
                className={`text-ellipsis-1 ${selectedTheme?.categoryTitle}`}
              >
                {categoryTitle}
              </div>
            </div>
          </div>
          <div
            className={`card-title text-sm md:text-base leading-16px md:leading-20px text-left font-MarkProHeavy ${selectedTheme?.title}`}
          >
            {title}
          </div>

          {broadcastDateString ? (
            <div
              className={`${selectedTheme?.broadcastDateString} text-12px leading-16px pr-8`}
            >
              {broadcastDateString}
            </div>
          ) : (
            <TimeString
              timeString={timeString}
              linearChannel={name === 'linear-channel'}
              className={`${selectedTheme?.timeString} text-12px leading-16px`}
            />
          )}
          {videoImage}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { asset, isArticle } = ownProps;

  const { user } = state;
  const userLoggedIn = _.get(user, 'loggedIn', false);
  const userType = _.get(
    user,
    ['userType', 'type'],
    _.get(user, 'authData.cognitoUser.attributes[custom:userType]', 'basic'),
  );
  const isPremiumUser = userLoggedIn && userType === 'premium';
  const {
    assetTypeName = '',
    imageUrl = null,
    categoryTitle = 'Optus Sport',
    imageUrl_16_9 = null,
    match = null,
    liveBroadcastTime,
  } = asset;
  let name,
    title,
    drmProtected = false,
    eventDate,
    isLive = false,
    isGame = false,
    competitionData,
    timezoneAbbr,
    timezone,
    labeledAsFree = false,
    duration = '',
    timeString = _.get(asset, 'startDate', ''),
    broadcastDateString = isEditorialAssetGame(assetTypeName)
      ? getFormattedDateTimeString(
          liveBroadcastTime,
          LOCALE_TIMEZONE_FORMAT_LLLL,
        )
      : '';

  if (isArticle) {
    const articleTitle = _.get(asset, 'title', _.get(asset, 'description', ''));
    competitionData = articleTitle;
    title = articleTitle;
    eventDate = moment.utc(asset.updatedAt).local();
    timezone = moment
      .utc(asset.updatedAt)
      .tz(moment.tz.guess())
      .format('z');
    timezoneAbbr = timezone || getTimezoneAbbr(moment.tz.guess());
  } else {
    isGame = isEditorialAssetGame(assetTypeName);
    competitionData = _.get(asset, 'description', null);
    eventDate = moment.utc(liveBroadcastTime).local();
    timezone = moment
      .utc(liveBroadcastTime)
      .tz(moment.tz.guess())
      .format('z');
    timezoneAbbr = timezone || getTimezoneAbbr(moment.tz.guess());
    name = assetTypeName;
    title = _.get(asset, 'title', '');
    drmProtected = asset.drmProtected;
    labeledAsFree = asset.labeledAsFree;
    isLive = asset.live;
    let { onDemandTimeBegin, onDemandTimeEnd } = asset;
    duration = asset.duration;
    if (onDemandTimeBegin && onDemandTimeEnd) {
      duration = parseInt(onDemandTimeEnd - onDemandTimeBegin, 10);
    }
  }

  return {
    eventDate,
    competitionData,
    timezone,
    timezoneAbbr,
    isGame,
    isLive,
    title,
    name,
    categoryTitle: getCategoryTitle(assetTypeName, match, categoryTitle),
    labeledAsFree,
    drmProtected,
    duration,
    imageUrl,
    isArticle: isArticle || assetTypeName === 'article',
    isPremiumUser,
    isLoggedIn: userLoggedIn,
    timeString,
    broadcastDateString,
    imageUrl_16_9,
    assetTypeName,
    match,
  };
}

export default connect(mapStateToProps)(AssetInfoBox);
