// import _ from 'lodash';
// import axios from 'axios';
// import { success } from 'react-notification-system-redux';
// import defaultConfig from '../constants/defaultConfig';
// import * as types from '../constants/actionTypes';
// import { getVcmsSeasonId } from 'utils/opta';
//
// const notificationOpts = {
//   // uid: 'once-please', // you can specify your own uid if required
//   title: '',
//   message: '',
//   position: 'tl',
//   autoDismiss: 3,
//   action: null,
//   dismissible: 'none'
// };
//
// export function getResults(competitionId, season) {
//   return (dispatch) => {
//     const {
//       vcmsBaseUrl,
//       VCMSConfig: { resultsEndpoint },
//     } = defaultConfig;
//     const vcmsSeasonId = getVcmsSeasonId(competitionId);
//     const url = `${vcmsBaseUrl}${resultsEndpoint}?season=${vcmsSeasonId}&competition=${competitionId}`;
//     const results = {competitionId, season};
//
//     dispatch({type: types.GET_RESULTS_INPROGRESS, results});
//
//     axios.get(url)
//       .then((res) => {
//         const isSuccess = (_.get(res, 'data.result[0].statuscode', 'N') === 'Y');
//
//         if (isSuccess) {
//           results.rows = _.get(res, 'data.APIinfo[0].FixtureInfo', []); // results return FixtureInfo as well
//           dispatch({type: types.GET_RESULTS_SUCCESS, results});
//           dispatch(success(notificationOpts));
//         } else {
//           dispatch({type: types.GET_RESULTS_FAILURE, results});
//         }
//
//       })
//       .catch(() => {
//         dispatch({type: types.GET_RESULTS_FAILURE, results});
//       })
//   }
// }

import _ from 'lodash';
import axios from 'axios';
import { success } from 'react-notification-system-redux';
import defaultConfig from '../constants/defaultConfig';
import * as types from '../constants/actionTypes';

const notificationOpts = {
  // uid: 'once-please', // you can specify your own uid if required
  title: '',
  message: '',
  position: 'tl',
  autoDismiss: 3,
  action: null,
  dismissible: 'none'
};

export function getResults(competitionId, season) {
  return (dispatch) => {
    const results = {competitionId, season};
    const url = `${defaultConfig.infinitOptaBaseUrl}/Sports/Soccer/result/result_${season}_${competitionId}.json`;

    dispatch({type: types.GET_RESULTS_INPROGRESS, results});

    axios.get(url)
      .then((res) => {
        const isSuccess = (_.get(res, 'data.result[0].statuscode', 'N') === 'Y');

        if (isSuccess) {
          results.rows = _.get(res, 'data.APIinfo[0].FixtureInfo', []); // results return FixtureInfo as well
          dispatch({type: types.GET_RESULTS_SUCCESS, results});
          dispatch(success(notificationOpts));
        } else {
          dispatch({type: types.GET_RESULTS_FAILURE, results});
        }

      })
      .catch(() => {
        dispatch({type: types.GET_RESULTS_FAILURE, results});
      })
  }
}
