import defaultConfig from '../constants/defaultConfig';
import { incomplete } from '../ducks/stepper';

const { FEATURE_PHONE_CODE_VERIFICATION } = defaultConfig.features;

const SIGNUP_STAGE = {
  ENTER_EMAIL: `signup/STAGE_ENTER_EMAIL`,
  VERIFY_MOBILE: `signup/STAGE_VERIFY_MOBILE`,
  CREATE_ACCOUNT: `signup/STAGE_CREATE_ACCOUNT`,
};

const signupSequence = {
  LOGGED_OUT: [
    SIGNUP_STAGE.ENTER_EMAIL,
    FEATURE_PHONE_CODE_VERIFICATION ? SIGNUP_STAGE.VERIFY_MOBILE : undefined,
    SIGNUP_STAGE.CREATE_ACCOUNT,
  ].filter(Boolean),
};

const signupStages = {
  [SIGNUP_STAGE.ENTER_EMAIL]: incomplete(),
  ...(FEATURE_PHONE_CODE_VERIFICATION && {
    [SIGNUP_STAGE.VERIFY_MOBILE]: incomplete(),
  }),
  [SIGNUP_STAGE.CREATE_ACCOUNT]: incomplete(),
};

const initialState = {};

const signupReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    default:
      return state;
  }
};

export { SIGNUP_STAGE, signupSequence, signupStages };

export default signupReducer;
