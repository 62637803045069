import dataSource from './dataSource';
import { isMobile } from '../utils';

const version = require('../../package.json').version;
const name = require('../../package.json').name;
const defaultPlatform = 'web';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
  ),
);

/* eslint-disable no-unused-vars */
const {
  REACT_APP_AWS_USER_AUTH_BASE_URL,
  REACT_APP_AWS_METADATA_BASE_URL,
  REACT_APP_AWS_TICKET_MANAGER_BASE_URL,
  REACT_APP_AWS_PLAYBACK_BASE_URL,
  REACT_APP_AWS_SPORTS_DATA_BASE_URL,
  REACT_APP_AWS_FRONTEND_BASE_URL,
  REACT_APP_AWS_CUSTOM_FRONTEND_BASE_URL,
  //
  REACT_APP_YSP_SDK = true,
} = process.env;
/* eslint-enable no-unused-vars */

const defaultConfig = {
  yspSdk: REACT_APP_YSP_SDK,
  yspSdkLive: true,
  yspSdkV3: true,

  advertConsent: 0,
  playerName: 'jwPlayer',
  defaultPlatform,
  platform: isMobile() ? 'mobileweb' : defaultPlatform,
  env: 'dev',
  name: name,
  version: version,
  supportDataSource: [dataSource.firestore, dataSource.cloudStorage],
  dataSource: dataSource.firestore,
  currentBaseUrl: 'https://web-dev.sport-ott.com',
  staticBaseUrl: isLocalhost ? '' : 'https://static.sport-pp.optus.com.au',

  frontendConfigName: 'frontEndConfig',

  image: {
    baseUrl: 'https://images.sport.optus.com.au/',
    sizes: {
      carouselImageSize: '_s_1920x1080_t_jpeg',
      panelCoverSize: '_s_768x432',
      panelCoverSizeLarge: '_s_300x450',
      siteSelectorSize: '_s_80x80',
      teamBackgroudSize: '_s_1920x1080_l_third-landscape_t_jpeg',
      teamBadgeSize: '_s_150x150',
      // Temp. sizes during cms transition
      carouselImageSizeMedium: '_s_1280x720_t_jpeg',
      carouselRailSize: '_s_320x180',
    },
  },

  infinitOptaBaseUrl: 'https://d21xmla0sqnlu7.cloudfront.net',
  vcmsBaseUrl: 'https://api-cdn.optussport.info/api',

  VCMSConfig: {
    teamSummaryEndpoint: '/stats/api/sports/api/Soccer/teamsummary',
    fixturesEndpoint: '/stats/api/sports/api/Soccer/fixture',
    resultsEndpoint: '/stats/api/sports/api/Soccer/result',
    liveMatchesEndpoint: '/stats/api/sports/api/Soccer/livematches',
    resultsStandingsEndpoint: '/stats/api/sports/api/Soccer/resultsstandings',
    matchPreviewEnpoint: '/stats/api/sports/api/Soccer/prematch',
    compareTeamsEndpoint: '/stats/api/sports/api/Soccer/compareteams',
    lineupPreMatchEndpoint: '/stats/api/sports/api/Soccer/lineupprematch',
    matchHighlightsEndpoint: '/stats/api/sports/api/Soccer/matchhighlights',
    commentaryEndpoint: '/stats/api/sports/api/Soccer/commentary',
    teamStatsEndpoint: '/stats/api/sports/api/Soccer/seasonstatteam',
    teamSquadEndpoint: '/stats/api/sports/api/Soccer/teamsquad',
    matchLiveScoreEndpoint: '/stats/api/sports/api/Soccer/matchlivescore',
    allTeamsUrl: '/categories/all/teams',
    matchStatsEndpoint: '/stats/api/sports/api/Soccer/matchstat',
    competitions: {
      epl: {
        competitionId: 8,
        competitionCode: 'EPL',
        season: '2022/2023',
      },
      unl: {
        competitionId: 941,
        competitionCode: 'NAT',
        season: '2022/2023',
      },
      int: {
        competitionId: 88,
        competitionCode: 'FRI',
        season: '2021',
      },
      wqe: {
        competitionId: 336,
        competitionCode: 'WQE',
        season: '2022 Qatar',
      },
      wsl: {
        competitionId: 550,
        competitionCode: 'WSL',
        season: '2022/2023',
      },
      'j-league': {
        competitionId: 20,
        competitionCode: 'J1L',
        season: '2022',
      },
      wwq: {
        competitionId: 642,
        competitionCode: 'WWQ',
        season: '2023 Australia / New Zealand',
      },
      prd: {
        competitionId: 23,
        competitionCode: 'PRD',
        season: '2022/2023',
      },
      dfb: {
        competitionId: 231,
        competitionCode: 'DFB',
        season: '2022/2023',
      },
      wwc: {
        competitionId: 408,
        competitionCode: 'WWC',
        season: '2023 Australia / New Zealand',
      },
      cuf: {
        competitionId: 1166,
        competitionCode: 'CUF',
        season: '2022',
      },
    },
  },

  // DEV
  convivaKey: '50b8a7ec1b707daeff977e46aa0afe8452aa3620',
  muxCustomerKey: '67j73ggfioi8fa3cse2ii6uv8',
  convivaGatewayHost: '//optus-test.testonly.conviva.com',

  // PROD (Do not set this unless in production environment as it will affect current conviva metrics)
  //convivaKey: '4049c7d989b1188801fc97d406227690ab986dbd',

  awsRegion: 'ap-southeast-2',

  // DEV - direct url
  // awsUserAuthBaseUrl: 'https://web-dev.sport-ott.com/api',
  // awsMetadataBaseUrl: 'https://web-dev.sport-ott.com/api',
  // awsTicketManagerBaseUrl: 'https://web-dev.sport-ott.com/api',
  // awsPlaybackBaseUrl: 'https://web-dev.sport-ott.com/api',
  // awsSportsDataBaseUrl: 'https://web-dev.sport-ott.com/api',

  awsUserAuthBaseUrl: 'https://web-dev.sport-ott.com/api',
  awsMetadataBaseUrl: 'https://web-dev.sport-ott.com/api',
  awsTicketManagerBaseUrl: 'https://web-dev.sport-ott.com/api',
  awsPlaybackBaseUrl: 'https://web-dev.sport-ott.com/api',
  awsSportsDataBaseUrl: 'https://stats.web-dev.sport-ott.com',
  awsFrontEndBaseUrl: 'https://web-dev.sport-ott.com/api',
  awsCustomFrontEndBaseUrl: 'https://web-dev.sport-ott.com',

  // DEV - MAP url to avoid
  // awsApiLambdaBaseUrl: '//web-dev.sport-ott.com/api',
  // awsMetadataBaseUrl: '//web-dev.sport-ott.com/api',

  // PP - direct url
  // awsUserAuthBaseUrl: 'https://n8m7atiqn9.execute-api.ap-southeast-2.amazonaws.com/pp',
  // awsMetadataBaseUrl: 'https://vdm3gezbbk.execute-api.ap-southeast-2.amazonaws.com/pp',
  // awsTicketManagerBaseUrl: 'https://l3lsuzdw25.execute-api.ap-southeast-2.amazonaws.com/pp',
  // awsPlaybackBaseUrl: 'https://ab15i4zcrc.execute-api.ap-southeast-2.amazonaws.com/pp',
  // awsSportsDataBaseUrl: 'https://ex69egqku7.execute-api.ap-southeast-2.amazonaws.com/pp',

  // Prd - direct url
  // awsUserAuthBaseUrl: 'https://8rbw1ccq43.execute-api.ap-southeast-2.amazonaws.com/prd',
  // awsMetadataBaseUrl: 'https://ojmqgxttxe.execute-api.ap-southeast-2.amazonaws.com/prd',
  // awsTicketManagerBaseUrl: 'https://x5ju8incd6.execute-api.ap-southeast-2.amazonaws.com/prd',
  // awsPlaybackBaseUrl: 'https://us500mwhjh.execute-api.ap-southeast-2.amazonaws.com/prd',
  // awsSportsDataBaseUrl: 'https://o4l9c6k21l.execute-api.ap-southeast-2.amazonaws.com/prd',

  // PP - MAP url to avoid
  // awsApiLambdaBaseUrl: '//sport-pp.sport.optus.com.au/api',
  // awsMetadataBaseUrl: '//sport-pp.sport.optus.com.au/api',

  // DEV - cognito Ids
  // awsUserPoolId: 'ap-southeast-2_fm9jqVdRg',
  // awsUserPoolWebClientId: '1r84fscar98l1d24q8s3bl21t5',

  // PP - cognito Ids
  awsUserPoolId: 'ap-southeast-2_fm9jqVdRg',
  awsUserPoolWebClientId: '1r84fscar98l1d24q8s3bl21t5',

  // production - cognito Ids
  // awsUserPoolId: 'ap-southeast-2_uDrelnnhk',
  // awsUserPoolWebClientId: '4f9j45k3024143l8hck1k1o2tp',

  awsAuthenticationFlowType: 'USER_PASSWORD_AUTH',

  saagEnabled: !isLocalhost,
  saagCompetitions: ['EPL', 'WSL', 'JPJL', 'UNL', 'WQE', 'PRD', 'DFB', 'WWC'],
  saagTotalCount: 15,
  saagPostMatchesDuration: 3,

  // raygun - DEV
  raygunApiKey: 'cgKq4piZxEB7CkUM06sI0g',

  // raygun - PP
  //raygunApiKey: '7bV1mGB1DZr9Iy1P0GAJg',

  // raygun - PROD
  //raygunApiKey: 'SkdYwrsGWywqi3nNcDD1A'

  // bugsnag - DEV
  bugsnagApiKey: '384b448309d4bbdfda48f287203f3480',

  // bugsnag - PP
  //bugsnagApiKey: '5e089a4126af0316b57cca899d7282d4',

  // bugsnag - PROD
  //bugsnagApiKey: 'a149f5ec8cbac04225705743ebd7cffb'
  getCompetitionTitle: (compId) => {
    const compIdStr = compId && compId.toString();
    switch (compIdStr) {
      case '8':
      case 'epl':
        return 'Premier League';
      case '5':
      case 'ucl':
      case '232':
      case 'uclq':
        return 'UEFA Champions League';
      case '6':
      case 'uel':
        return 'UEFA Europa League';
      case '941':
      case 'unl':
        return 'UEFA Nations League';
      case '88':
      case 'int':
        return 'International Friendlies';
      case '235':
      case 'ecq2020':
        return 'EURO 2020 Qualifiers';
      case '408':
      case 'fwwc':
      case 'wwc':
        return "FIFA Women's World Cup 2023";
      case '334':
        return 'Premier League Asia Trophy';
      case '174':
      case 'usc':
        return 'UEFA Super Cup';
      case '550':
      case 'wsl':
        return "FA Women's Super League";
      case '669':
      case 'fcw':
        return "Women's FA Cup";
      case '335':
      case 'fcwc':
        return 'FIFA Club World Cup™';
      case '20':
      case 'j-league':
      case 'jpjl':
        return 'J.League';
      case '23':
      case 'prd':
        return 'LaLiga';
      case '3':
      case 'ec2020':
        return 'UEFA EURO 2020™';
      case '205':
      case 'k-league':
      case 'kl':
        return 'K League';
      case '336':
      case 'wqe':
        return 'FIFA World Cup European Qualifiers';
      case '128':
      case 'coa':
        return 'Copa América';
      case '642':
      case 'wwq':
        return "Women's World Cup Qualifiers";
      case '1166':
      case 'cuf':
        return 'CONMEBOL/UEFA Finalissima';
      case '231':
      case 'dfb':
        return 'DFB Pokal';
      default:
        return 'Premier League';
    }
  },

  getCategoryTitle: (title) => {
    const titleLowerCase = title && title.toLowerCase();
    switch (titleLowerCase) {
      case 'linear channels':
        return 'Live Channels';
      default:
        return title;
    }
  },

  networkDiagnosticsUrl: 'https://eplnetinfo-https.optusnet.com.au/netinfo',

  // recaptcha apiKey
  recaptchaApiKey: '6LdWyJcUAAAAABd2MEVJ_-P8G5kF0erDo9pr4YYn',

  //campaign redirect URL(voucher)
  campaignSuccessRedirectURL: '/',

  // Medallia embed script url
  medalliaEmbedJsUrl: `https://nebula-cdn.kampyle.com/wau/60853/onsite/embed.js`,

  // DEV - graphql endpoint for the Articles(AWS AppSync)
  awsAppSyncGraphQLBaseUrl: 'https://sport-pp.sport.optus.com.au/graphql',
  awsAppSyncXApiKey: 'da2-rcakpitrnrclfoteclk5lqk7z4',
  // New publishing rules parameters
  awsAppSyncQueryEnv: 'PREPROD',
  awsAppSyncQueryPlatform: 'WEB',
  awsAppSyncFilterType: 'exact',

  // PROD - graphql endpoint for the Articles(AWS AppSync)
  //awsAppSyncGraphQLBaseUrl: 'https://sport.optus.com.au/graphql',
  //awsAppSyncXApiKey: 'da2-m5qow7e7jraudkmlvo6bzmirqe',
  // New publishing rules parameters
  //awsAppSyncQueryEnv: 'PROD',
  //awsAppSyncQueryPlatform: 'WEB',
  //awsAppSyncFilterType: 'match',

  // DEV - loggly Customer Token and subDomain
  logglyCustomerToken: '2fbc9545-0a4a-48f6-9bf6-f8d1cfcbc5e6',
  logglySubdomain: 'https://optussport.loggly.com',
  logglyEnv: 'Dev',

  // PP - loggly Customer Token and subDomain
  // logglyCustomerToken: '',
  // logglySubdomain: 'https://optussport.loggly.com',
  // logglyEnv: 'Pre Prod',

  // PROD - loggly Customer Token and subDomain
  // logglyCustomerToken: '',
  // logglySubdomain: 'https://optussport.loggly.com',
  // logglyEnv: 'Prod'

  //Google Analytics Tracking Id
  gaTrackingId: 'UA-22088848-3',

  //Facebook SDK
  fbAppId: '180015362652616',
  fbClientToken: 'fb4b1e9203a607f5e9d9d49c666ee200',
  fbApiVersion: 'v8.0',

  //Segment SDK
  segmentDomain: 'https://evs.seg-cdn.optussport.info',
  segmentId: 'qNU92thhvH2SVsXfuFuXJyLWUjGDOUS2',
  segmentAnalyticsAlias: 'tYPaVkMqY4vMnng4tiuQWy',

  //Bitmovin Player
  bitmovinPlayerKey: '091F8084-B544-4DAB-B090-94ECF8506D12',
  //Flags for calling the API's v1 or v2
  // false => call the api v1
  // true => call the api v2
  apiFlags: {
    assets: true,
    editorials: true,
    search: true,
  },

  baseImgResizerUrl: 'https://sport-pp.sport.optus.com.au/cms/resources/images',
  routeWithoutReactHelmet: ['videos', 'play', 'articles'],
  optusCustomerSignupRedirectUrl:
    'https://www.optus.com.au/entertainment/sport/os-fitness#already-with-optus',
  optusCustomerRegisterRedirectUrl:
    'https://subhub.optus.com.au/streaming-services/optussport',

  // Feature toggles
  features: {
    FEATURE_BITMOVIN_NATIVE_YOSPACE: true,
    FEATURE_JUMBOTRON_DISABLED: ['home', 'news'],
    FEATURE_EXPLORE_RAIL: true,
    FEATURE_PHONE_CODE_VERIFICATION: true,
    // FEATURE_PHONE_CODE_VERIFICATION_DUMMY_NUMBERS: true,
    // If the SMS functionality has been used more than 5 times in the last 5 minutes, then it will be on cooldown for 5 minutes to avoid spam. The 'Resend code' link deactivates and there is a timer that counts down each second from 5:00. When it reaches 0, the link activates again. These parameters need to be configurable.
    // src: https://optussport.atlassian.net/browse/WB-959
    FEATURE_PHONE_CODE_VERIFICATION_USAGE_LIMIT_ATTEMPTS: 5,
    FEATURE_PHONE_CODE_VERIFICATION_USAGE_LIMIT_DURATION: 300000, // 5 minutes
    FEATURE_PHONE_CODE_VERIFICATION_USAGE_LIMIT_COOLDOWN: 300000, // 5 minutes

    FEATURE_ZOMBIE_KICK: true,
    FEATURE_ZOMBIE_KICK_TIMEOUT_HOURS: 6,

    FEATURE_PAYMENT_PAGE: true,
    FEATURE_PAYMENT_COUPON: true,
    FEATURE_PAYMENT_WORKAROUND_SUBSCRIPTION_STATUS_DELAY: 1500,
    FEATURE_CREDIT_CARD_INTEGRATION_DISPLAY_MODE: `custom`, // displayMode: PropTypes.oneOf([`inline`, `modal`, `redirect`, `custom`])
    FEATURE_PAYMENT_MERCHANT_ID: `97ba3cf6-92a2-4f3b-b7f8-c3bfc90ee07b`,
    // FEATURE_PAYMENT_MERCHANT_ID: `0f473b37-232f-4bf6-8894-3c08824cc02a`,
    FEATURE_PAYMENT_CUSTOM_CHECKOUT_URL: `https://customcheckout-uat.bambora.net.au/1.0.0/customcheckout.js`,
    FEATURE_CONVIVA: true,

    CONNECT_CONFIG: {
      CANCEL_INTERVAL: 300000,
      INTERVAL: 30000,
    },
    DEFAULT_REGISTER_CONFIG: {
      messages: {},
      images: {
        logo: '',
        banner: '',
      },
    },
    CTV_CONFIG: {
      lg: {
        offers: {},
        tags: ['ctv_lg'],
        messages: {},
        images: {},
      },
      samsung: {
        offers: {},
        tags: ['ctv_samsung'],
        messages: {
          title: 'Sign up to watch Optus Sport on Samsung TV',
          info: 'The Terrace',
          markup: [
            {
              name: 'terms',
              value: `<p>Optus Sport free subscription for 2 months if you sign up with a credit card and use coupon code until 21st December. Subscription will be charged at $24.99 per month after two months unless cancelled earlier. New customers only. <a target="_blank" href="/samsung/terms">Click here</a> for full T&Cs</p>`,
            },
          ],
        },
        images: {
          logo: './images/vendor/samsung/logo.svg',
          banner: './images/vendor/samsung/banner.png',
        },
      },
      playstation: {
        offers: {},
        tags: ['ctv_playstation'],
        messages: {},
        images: {},
      },
      androidtv: {
        offers: {},
        tags: ['ctv_androidtv'],
        messages: {},
        images: {},
      },
      fetch: {
        offers: {},
        tags: ['ctv_fetch'],
        messages: {},
        images: {},
      },
    },
    FEATURE_RELATED_ASSET: {
      enabled: true,
      nextAssetAutoPlayCountdown: 6000, //milliseconds
      cardSlideAnimationTimer: 500, //milliseconds
    },
    SUBSCRIPTION_UPDATE_NOTIFICATION_ENABLED: false,
    SUBSCRIPTION_UPDATE_NOTIFICATION_CONFIG: {
      paymentSource_postpaid: {
        notificationTitle: 'Changes to your subscription',
        notificationMsg: `From 1 August 2022, Optus Sport subscription prices are changing to <b>$6.99 per month</b>. The changes will take effect from your first subscription renewal <b>after 31 July 2022</b>. <br /><p class='pt-8'>The Cancel Subscription button below will take you to Optus SubHub, our subscription management tool, where you can unsubscribe using your Optus My Account login. Please note, you must be the Optus account holder to make changes to your subscription.</p>`,
        actionLinkText: 'Learn more',
        actionUrl:
          'https://sport.optus.com.au/articles/os43329/optus-sport-announcement-2022',
      },
      paymentSource_prepaid: {
        // notificationTitle: 'Changes to your subscription',
        // notificationMsg: `From 1 August 2022, Optus Sport Prepaid subscription prices are changing to <b>$11.99</b> for <b>28 days</b>. The changes will take effect from your first subscription renewal <b>after 31 July 2022</b>.`,
        // actionLinkText: '',
        // actionUrl: '',
      },
      paymentSource_cc: {
        notificationTitle: 'Changes to your subscription',
        notificationMsg: `From 1 August 2022, Optus Sport subscription prices are changing to <b>$24.99 per month or $199.00 per year</b> for our standard subscriptions. If you are currently subscribed to a special offer, please refer to the email we have sent you about the changes to your offer. All price changes will take effect from your next subscription renewal <b>after 31 July 2022</b>.`,
        actionLinkText: 'Learn more',
        actionUrl:
          'https://sport.optus.com.au/articles/os43329/optus-sport-announcement-2022',
        priceChangeDate: '01-Aug-2022',
        newPrice: {
          '1m': 24.99,
          '3months': 49.99,
          '12m': 199,
        },
        newPriceOffer: {
          S631361160_AU: 159,
          S344110223_AU: 159,
          S923762200_AU: 159,
          S666769808_AU: 159,
          S360763790_AU: 159,
        },
      },
      paymentSource_playstore: {
        notificationTitle: 'Changes to your subscription',
        notificationMsg: `From 1 August 2022, Optus Sport subscription prices are changing.`,
        actionLinkText: 'Learn more',
        actionUrl:
          'https://sport.optus.com.au/articles/os43329/optus-sport-announcement-2022',
      },
      paymentSource_appstore: {
        notificationTitle: 'Changes to your subscription',
        notificationMsg: `From 1 August 2022, Optus Sport subscription prices are changing.`,
        actionLinkText: 'Learn more',
        actionUrl:
          'https://sport.optus.com.au/articles/os43329/optus-sport-announcement-2022',
      },
      paymentSource_hyperion: {
        notificationTitle: 'Changes to your subscription',
        notificationMsg: `From 1 August 2022, Optus Sport subscription prices are changing to <b>$6.99 per month</b>. The changes will take effect from your first subscription renewal <b>after 31 July 2022</b>. <br /><p class='pt-8'>The Cancel Subscription button below will take you to Optus SubHub, our subscription management tool, where you can unsubscribe using your Optus My Account login. Please note, you must be the Optus account holder to make changes to your subscription.</p>`,
        actionLinkText: 'Learn more',
        actionUrl:
          'https://sport.optus.com.au/articles/os43329/optus-sport-announcement-2022',
      },
      paymentSource_optus_subhub: {
        notificationTitle: 'Changes to your subscription',
        notificationMsg: `From 1 August 2022, Optus Sport subscription prices are changing to <b>$6.99 per month</b>. The changes will take effect from your first subscription renewal <b>after 31 July 2022</b>. <br /><p class='pt-8'>The Cancel Subscription button below will take you to Optus SubHub, our subscription management tool, where you can unsubscribe using your Optus My Account login. Please note, you must be the Optus account holder to make changes to your subscription.</p>`,
        actionLinkText: 'Learn more',
        actionUrl:
          'https://sport.optus.com.au/articles/os43329/optus-sport-announcement-2022',
      },
      paymentSource_saturn_migration_pass: {
        notificationTitle: 'Notice',
        notificationMsg: `You currently have access to Optus Sport subscription content until 31 May 2023. We will advise you of further changes to your Optus Sport account and subscription closer to this date. Please contact your Optus account holder to make any changes to your account.`,
        actionLinkText: '',
        actionUrl: '',
      },
      checkout_page: {
        notificationTitle: 'Prices are changing',
        notificationMsg: `From 1 August 2022, Optus Sport subscription prices are changing to <b>$24.99 per month or $199.00 per year</b> for our standard subscriptions. If you are currently subscribed to a special offer, please refer to the email we have sent you about the changes to your offer. All price changes will take effect from your next subscription renewal <b>after 31 July 2022</b>.`,
        actionLinkText: 'Learn more',
        actionUrl:
          'https://sport.optus.com.au/articles/os43329/optus-sport-announcement-2022',
        priceChangeDate: '01-Aug-2022',
        newPrice: {
          '1m': 24.99,
          '3months': 49.99,
          '12m': 199,
        },
        newPriceOffer: {
          S631361160_AU: 159,
          S344110223_AU: 159,
          S923762200_AU: 159,
          S666769808_AU: 159,
          S360763790_AU: 159,
        },
      },
    },
    FEATURE_SUBSCRIPTION_ACTIONS_CONFIG: {
      paymentSource_postpaid: {
        active: {
          updateActionText: 'Manage subscription',
          updateActionUrl: `https://subhub.optus.com.au/sso-redirect?target=/service-details/optussport`,
          updateActionUrlTarget: '_self',
        },
      },
      paymentSource_hyperion: {
        active: {
          updateActionText: 'Cancel subscription',
          // updateActionUrl: 'https://subhub.optus.com.au/unsubscribe/optussport',
          updateActionUrl: '',
          updateActionUrlTarget: '_self',
        },
      },
      paymentSource_optus_subhub: {
        active: {
          updateActionText: 'Manage subscription',
          updateActionUrl: `https://subhub.optus.com.au/sso-redirect?target=/service-details/optussport`,
          // updateActionUrl: '',
          updateActionUrlTarget: '_self',
        },
      },
    },
    FEATURE_RETENTION_OFFER: true,
    RETENTION_OFFERS_CONFIG: {
      'web-creditcard-one-month-free-2021-07': {
        heroImageMobile: `/images/amazon-fire-tv-rotator-euro-1920-x-720-mobile.png`,
        heroImageDesktop: `/images/amazon-fire-tv-rotator-euro-1920-x-720-desktop.png`,
        heroImageAltText: `Football players`,
        headLineText: `Before you go we have a special offer just for you`,
        headingText: `Have a month subscription on us - <span class='text-green'>Save $24.99</span>`,
        yesBtnText: `Yes please!`,
        noBtnText: `No I want to cancel`,
        offerTnCText: `Redeemable only on Web, <a href='https://sport.optus.com.au/terms' target='_blank' class='text-teal os-transition-btn hover:text-teal-dark active:text-teal-light'>Terms & Conditions</a> apply`,
        successHeadingText: `A free month has been added to your subscription`,
        successContentText: `On {{next_billing_date}}, you will be charged {{subscription_discounted_price}}. After that date, you will be billed {{subscription_period}} for {{subscription_price}} until you cancel or change your plan.`,
      },
      'web-creditcard-one-day-free-2021-07': {
        heroImageMobile: `/images/amazon-fire-tv-rotator-euro-1920-x-720-mobile.png`,
        heroImageDesktop: `/images/amazon-fire-tv-rotator-euro-1920-x-720-desktop.png`,
        heroImageAltText: `Football players`,
        headLineText: `Before you go we have a special offer just for you`,
        headingText: `Have a day subscription on us - <span class='text-green'>Save $2.99</span>`,
        yesBtnText: `Yes please!`,
        noBtnText: `No I want to cancel`,
        offerTnCText: `Redeemable only on Web, <a href='https://sport.optus.com.au/terms' target='_blank' class='text-teal os-transition-btn hover:text-teal-dark active:text-teal-light'>Terms & Conditions</a> apply`,
        successHeadingText: `A free day has been added to your subscription`,
        successContentText: `On {{next_billing_date}}, you will be charged {{subscription_discounted_price}}. After that date, you will be billed {{subscription_period}} for {{subscription_price}} until you cancel or change your plan.`,
      },
    },
    // This will be overridden by fe-config > systemConfigs; these are dummy data for testing
    OFFERS_CONFIG: {
      S792796051_AU: {
        promoText: 'Limited time offer.*',
        promoDescription: `*Annual Pass offer available until 27, September 2020. Subscription will renew at $139.`,
      },
      S794694526_AU: {
        promoText: 'Get it while its hot.^',
        promoDescription: '^On the daily. For your flexible lifestyle.',
      },
    },
    FEATURE_PAYMENT_DEFAULT_ERROR: `There was a problem processing your credit card; please double check your payment information and try again.`,

    FEATURE_CONNECT_PAGE: true,
    FEATURE_EXTERNAL_SIGNUP_PAGE: true,
    SUBHUB_PROMO_ACTIVE: true,
    FEATURE_DEVICE_MANAGEMENT: false,
    SIGNUP_PROMO_CONFIG: {
      headerText: `Football and fitness all on Optus Sport`,
      primaryText: `Sign up to a free Optus Sport account and get access to football highlights, Fitness content and more. <br />Subscribe for full access to Premier League, Women's Super League, LaLiga, J.League, and more.`,
      secondaryText: ``,
    },
    SIGNUP_SUCCESS_CONFIG: {
      FROM_PREMIUM_ASSET: {
        title: `Welcome {firstName}`,
        content: `<div class='text-center'><p>Your account email is <b>{email}</b></p><br />
                  <p>Subscribe now in order to start watching <b>{assetTitle}</b>.</p></div>`,
        primaryButton: {
          text: 'Subscribe',
          link: '/checkout',
        },
        secondaryButton: {
          text: 'Not now',
          link: '/',
        },
        includedLink: {
          text: `What's included in a subscription?`,
          link: '/signup',
        },
      },
      FROM_FREE_ASSET: {
        title: `Welcome {firstName}`,
        content: `<div class='text-center'><p>Your account email is <b>{email}</b></p><br />
                  <p>You can now enjoy Optus sport <span class='text-green'>free content</span>, including hundreds of free Fitness videos, football news, articles and selected highlights.</p><br />
                  <p>Sign up to a paid subscription to access exclusive Premier League, FA Women's Super League, LaLiga and more.</p></div>`,
        primaryButton: {
          text: 'Watch now',
          link: '{assetLink}',
        },
        secondaryButton: {
          text: 'Subscribe',
          link: '/checkout',
        },
        includedLink: {
          text: `What's included in a subscription?`,
          link: '/signup',
        },
      },
      FROM_OTHER_SIGNUP_LINK: {
        title: `Welcome {firstName}`,
        content: `<div class='text-center'><p>Your account email is <b>{email}</b></p><br />
                  <p>You can now enjoy Optus sport <span class='text-green'>free content</span>, including hundreds of free Fitness videos, football news, articles and selected highlights.</p><br />
                  <p>Sign up to a paid subscription to access exclusive Premier League, FA Women's Super League, LaLiga and more.</p></div>`,
        primaryButton: {
          text: 'Explore Optus Sport',
          link: '/',
        },
        secondaryButton: {
          text: 'Subscribe',
          link: '/checkout',
        },
        includedLink: {
          text: `What's included in a subscription?`,
          link: '/signup',
        },
      },
      PREPAID_BOLT_ON_ACCOUNT: {
        title: 'Your account is ready to go',
        content: 'You can not access all Optus Sport subscription content.',
        primaryButton: {
          text: 'Browse Optus Sport',
          link: '/',
        },
        secondaryButton: {
          text: 'Create your support ticket',
          link: '/help',
        },
      }
    },
    FEATURE_EDIT_PROFILE: true,
    PROFILE_PAGE_CONFIG: {
      personalisedAd: {
        enabled: true,
        label: 'Turn off personalised ads',
        subText:
          'This will not reduce the number of ads you receive, but will limit our ability to deliver relevant ads to you',
      },
    },
    PAYMENT_PAGE_CONFIG: {
      optusCustomerSection: {
        enabled: true,
        headerText: `Are you an <span class='text-yellow'>Optus</span> Customer?`,
        primaryText: `If you're an eligible Optus customer, activate and manage your subscription through Optus SubHub to receive a discounted price of $6.99 per month.`,
        divider: {
          enabled: true,
          text: 'or',
        },
        ctaButton: {
          enabled: true,
          text: 'Go to Subhub',
          link: 'https://subhub.optus.com.au/streaming-services/optussport',
        },
      },
    },
    SAMSUNG_TV_CONFIG: {
      headerText: `Football and fitness.<br />
      All in Optus Sport.`,
      primaryText: `
        <div>Optus Sport is home to the Premier League, Women's Super League, LaLiga and J.League, along with the upcoming FIFA Women's World Cup 2023. Watch live or on demand on your Samsung TV.</div>
        <br />
        <div>Find your new favourite workout with OS Fitness. From improving your balance and movement with yoga and pilates, to working on strength and cardio with high intensity training, dancing and football skills. There is something for everyone.</div>
      `,
      signupCtaUrl: '/register',
      signupCta: false,
      bgImageLarge: ['/images/samsungtv_bg_1.jpeg 1x'],
      bgImageMedium: ['/images/samsungtv_bg.jpeg 1x'],
      bgImageSmall: [
        '/images/samsungtv_bg_small.jpeg 1x',
        '/images/samsungtv_bg_small_1.jpeg 2x',
      ],
      ctaButtonText: 'Get Optus Sport now',
      disclaimerText: `Compatible device required. Personal viewing in AU. Data charges may apply. Content can change. Fair Go Policy, T&Cs apply.`,
    },
    FITNESS_CONFIG: {
      paywallEnabled: true,
      headerText: `Workouts for every body<br/>All in one place.`,
      primaryText: `
      Sign up to <strong class='font-MarkProHeavy'>Optus Sport </strong> and start working out today for <strong class='font-MarkProHeavy'>$24.99 </strong> per month or it’s included with selected Optus plans.
      `,
      secondaryText: ``,
    },
    ARTICLE_DETAIL_CONFIG: {
      carousel: {
        id: 'manual_article_related',
        title: 'Read More',
        showViewAllLink: false,
      },
    },
    ASSET_PLAY_CONFIG: {
      fitness: {
        load: {
          TOP_PICKS: {
            id: 'top_picks',
            limit: 16,
          },
          SIMILAR_CLASSES: {
            id: 'similar_classes',
            limit: 32,
          },
        },
        carousel: [
          {
            id: 'similar_classes',
            title: 'More like this',
            viewAllUrl: '/fitness',
          },
          {
            id: 'top_picks',
            title: 'Top picks',
          },
        ],
      },
    },
    FITNESS_LANDING_CONFIG: {
      carousel: {
        id: 'top_picks',
        title: 'Find your next workout',
        sectionPath: '/fitness',
      },
    },
    FEATURE_SEARCH: true,
    HOMEPAGE_CONFIG: {
      freeOnlyPanels: [],
    },
    ANALYTICS: {
      mux: true,
      muxDebug: true,
    },
    SEO_CONFIG: {
      categoryNames: ['the athletic'],
      tagNames: ['athletic'],
    },
    CUSTOM_WIDGET: {
      recently_watched: {
        assetStructure: ['data', 'viewedAssets'],
        showViewAllLink: false,
        loginRequired: true,
      },
      explore: {
        showViewAllLink: false,
      },
    },
    FEATURE_BITMOVIN: false,
    FEATURE_BITMOVIN_SEGMENT_ENABLED: true,
  },
  muxPluginName: 'jwplayerMux',
  muxPluginVersion: '4.3.1',
  // OSN-1438: Temporary until OSN-1432 is ready to be deployed (can be removed once ready)
  redirectFitnessOnOptusSport: false,
};

// Allow for testing in non prod env
if (window.location.hash === '#noys' && defaultConfig.env !== 'prod') {
  defaultConfig.yspSdk = false;
}

export default defaultConfig;
