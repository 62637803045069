import _ from 'lodash';
import { getBrowser, getOS } from './userAgent';
import defaultJwErrorConfig from '../constants/jwErrorConfig';
import defaultConfig from '../constants/defaultConfig';
import queryString from 'query-string';

export function getProtocolAndDRM() {
  const osData = getOS();
  const browserData = getBrowser();

  const osName = _.get(osData, 'name', '');
  const browserName = _.get(browserData, 'name', '');

  switch (browserName.toLowerCase()) {
    case 'safari':
    case 'mobile safari':
      // HLS and Fairplay
      return {
        protocol: 'hls',
        drm: 'fairplay',
      };
    case 'edge':
      // Dash and Playready
      // Ken (24th-Nov-2020)::Playready not working as no ServiceCertificate, Widevine seems to be supported now
      // and the previous version of ua-parser-js (0.7.18) identified Microsoft Edge as "Chome" instead of
      // "Edge" which meant the default case of Widevine was used anyway.
      return {
        protocol: 'dash',
        drm: 'widevine',
      };
    default:
      // We should always provide HLS for iOS devices regardless of browser
      // i.e. Chrome in iOS
      if (osName.toLowerCase() === 'ios') {
        // HLS and Fairplay
        return {
          protocol: 'hls',
          drm: 'fairplay',
        };
      }

      // Dash and widevine as default if browser cannot be mapped
      return {
        protocol: 'dash',
        drm: 'widevine',
      };
  }
}

export function mapPlayerError(errorObj, isPlaying, s3JwErrorConfig) {
  const { code, message, type } = errorObj;

  const defaultErrorObj = _.get(
    s3JwErrorConfig,
    'default',
    _.get(defaultJwErrorConfig, 'default'),
  );
  const defaultMessage = _.get(
    defaultErrorObj,
    'message',
    'Sorry, you are unable to access this content. If you believe this is an error, please contact Optus Sport Support.',
  );
  const defaultRetryAllow = _.get(defaultErrorObj, 'retryAllow', true);
  const defaultTitle = _.get(defaultErrorObj, 'title', 'Oops!');

  let mappedErrorObj = {
    title: defaultTitle,
    code,
    message,
    retryAllow: defaultRetryAllow,
    type,
  };
  let isPlayingObj;

  const codeString = code.toString();
  const codePrefix = codeString.substr(0, 2);
  const codeSuffix = codeString.substr(3);

  //console.log('codePrefix', codePrefix);
  //console.log('codeSuffix', codeSuffix);

  if (
    _.includes(
      ['CHUCK_NORRIS', '102630', 'ZOMBIE_STREAM_KICK', '246000'],
      codeString,
    )
  ) {
    isPlayingObj = _.get(
      s3JwErrorConfig,
      [codeString, 'isPlaying'],
      _.get(defaultJwErrorConfig, [codeString, 'isPlaying']),
    );

    mappedErrorObj.message = _.get(
      s3JwErrorConfig,
      [codeString, 'message'],
      _.get(defaultJwErrorConfig, [codeString, 'message'], defaultMessage),
    );

    mappedErrorObj.retryAllow = _.get(
      s3JwErrorConfig,
      [codeString, 'retryAllow'],
      _.get(
        defaultJwErrorConfig,
        [codeString, 'retryAllow'],
        defaultRetryAllow,
      ),
    );

    mappedErrorObj.title = _.get(
      s3JwErrorConfig,
      [codeString, 'title'],
      _.get(defaultJwErrorConfig, [codeString, 'title'], defaultTitle),
    );

    mappedErrorObj.ctaButton = _.get(
      s3JwErrorConfig,
      [codeString, 'ctaButton'],
      _.get(defaultJwErrorConfig, [codeString, 'ctaButton'], null),
    );

    // OSN-2018 - custom logic for '246000' error, error config only to be used for Chromium browsers and version < 107
    // This will revert back to defaults if non chromium or version >= 107
    if (codeString === '246000') {
      const { name: browserName, version: rawBrowserVersion } =
        getBrowser() || {};
      const browserVersion = rawBrowserVersion
        ? parseFloat(rawBrowserVersion)
        : 0;
      const isChromium = _.includes(
        ['chrome', 'firefox', 'edge'],
        browserName.toLowerCase(),
      );

      // Set back to defaults if 246000 code is encountered on non chromium or chromium >= 107
      if (!isChromium || (isChromium && browserVersion >= 107)) {
        mappedErrorObj.title = defaultTitle;
        mappedErrorObj.message = defaultMessage;
        mappedErrorObj.retryAllow = defaultRetryAllow;
        mappedErrorObj.ctaButton = null;
      }
    }
  } else {
    isPlayingObj = _.get(
      s3JwErrorConfig,
      [codePrefix, codeSuffix, 'isPlaying'],
      _.get(defaultJwErrorConfig, [codePrefix, codeSuffix, 'isPlaying']),
    );

    mappedErrorObj.message = _.get(
      s3JwErrorConfig,
      [codePrefix, codeSuffix, 'message'],
      _.get(
        defaultJwErrorConfig,
        [codePrefix, codeSuffix, 'message'],
        defaultMessage,
      ),
    );

    mappedErrorObj.retryAllow = _.get(
      s3JwErrorConfig,
      [codePrefix, codeSuffix, 'retryAllow'],
      _.get(
        defaultJwErrorConfig,
        [codePrefix, codeSuffix, 'retryAllow'],
        defaultRetryAllow,
      ),
    );
  }

  if (isPlayingObj && isPlaying) {
    mappedErrorObj.message = _.get(isPlayingObj, 'message');
    mappedErrorObj.retryAllow = _.get(isPlayingObj, 'retryAllow');
  }

  return mappedErrorObj;
}
export const isFreePlayBack = (accessType) => {
  return (
    !_.includes(['PREMIUM', 'PAID'], accessType) ||
    accessType === 'FREE_WITHOUT_LOGIN'
  );
};

const getFromDefaultConfigFeatures = (prop) => _.get(defaultConfig, `features.${prop}`, false);

export const isBitmovinPlayer = ({
  getFeatureFlags = getFromDefaultConfigFeatures,
}) => {
  const path = _.get(window, 'location.pathname');
  const search = _.get(window, 'location.search', '');

  // Temporarily allow JWPlayer for debugging any issues/use as baseline
  const searchParams = queryString.parse(search);
  const useJwPlayerOverride = _.get(searchParams, 'useJwPlayerOverride', 'false');

  return useJwPlayerOverride === 'false' && (path.includes('/videos3') || getFeatureFlags('FEATURE_BITMOVIN'));
};
