/*
  Creating Analytic Logs
  1. Add eventName to the config below: `example_event_name`
  2. Define an array as the key: `example_event_name: [ ... ]`
  3. Define the analytic vendor as a string ie: loggly, segment, ga.  ex: `example_event_name: [ 'segment' ]`
  4. Define the analytic action for the analytic vendor separated by a colon ie: `example_event_name: [ 'segment:track' ]`
  5. Define any further props necessary for the analytic vendor, type is used to define the analytic vendor action type separated by a colon ie: `example_event_name: [ 'segment:track:info' ]`
*/

const ANALYTIC_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const ANALYTIC_CONFIG = {
  login_button_clicked: ['loggly:log:info'],
  os_ml_auth_api: ['loggly:log:info'],
  logout_button_clicked: ['loggly:log:info'],
  logout_result: ['loggly:log:info'],
  'Search Session Started': ['segment:track:info'],
  'Search Autocomplete Suggestion': ['segment:track:info'],
  'Search Requested': ['segment:track:info'],
  'Search Responded': ['segment:track:info'],
  'Search Filter Clicked': ['segment:track:info'],
  'Asset Clicked': ['segment:track:info'],
  'Offer Popup Viewed': ['segment:track:info'],
  'Offer Popup Accepted': ['segment:track:info'],
  'Offer Popup Dismissed': ['segment:track:info'],
  'Offer Churned After Accepting': ['segment:track:info'],
  'Purchase Successful': ['segment:track:info'],
  'Purchase Errored': ['segment:track:info'],
  'Subscription Update Successful': ['segment:track:info'],
  'Subscription Update Errored': ['segment:track:info'],
  'Get Retention Offers Successful': ['segment:track:info'],
  'Get Retention Offers Errored': ['segment:track:info'],
  'Autoplay Popup Displayed': ['segment:track:info'],
  'Autoplay Next Video Triggered': ['segment:track:info'],
  'Complete Profile Saved': ['segment:track:info'],
  'Incomplete Profile Saved': ['segment:track:info'],
  'Logout All Devices Performed': ['segment:track:info'],
  '/usersettings/devices': ['segment:page:info'],
};

const ANALYTIC_VENDOR_CONFIG = {
  segment: {
    actions: {
      identify: 'identify',
      track: 'track',
      reset: 'reset',
      page: 'page',
    },
  },
  loggly: {
    actions: {
      log: 'log',
    },
  },
};

const ANALYTIC_DEFAULT_VENDOR = ANALYTIC_VENDOR_CONFIG['segment'];
const ANALYTIC_DEFAULT_ACTION = 'track';

const NEEDLES = {
  userName: 'userName',
  password: 'password',
  AccessToken: 'AccessToken',
  RefreshToken: 'RefreshToken',
  IdToken: 'IdToken',
  Authorization: 'Authorization',
};

const ANALYTIC_NEEDLES = Object.keys(NEEDLES);

const ANALYTIC_NEEDLE_REPLACER = 'hidden-PII';

const APIEVENT = {
  AUTH: 'os_ml_auth_api',
  LOGOUT: 'logout_result',
};
export {
  ANALYTIC_CONFIG,
  ANALYTIC_VENDOR_CONFIG,
  ANALYTIC_TIME_FORMAT,
  ANALYTIC_NEEDLES,
  ANALYTIC_NEEDLE_REPLACER,
  ANALYTIC_DEFAULT_VENDOR,
  ANALYTIC_DEFAULT_ACTION,
  APIEVENT,
};
