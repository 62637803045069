import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as tablesActions from '../actions/table';
import * as resultsActions from '../actions/results';
import * as fixturesActions from '../actions/fixtures';
import * as navigationAction from '../actions/navigation';
import * as teamActions from '../actions/teams';
import * as saagActions from '../actions/saagData';
import defaultConfig from '../constants/defaultConfig';
import { isMobile } from '../utils';
import queryString from 'query-string';

// react components
import PortalRoutes from './features/PortalRoutes';
import { SystemConfigContextProvider } from './context/withSystemConfig';

import { SearchProvider } from '../components/search';

class App extends Component {
  constructor(props, context) {
    super(props, context);

    const location = props.location || _.get(window, 'location');
    const search = _.get(location, 'search', '');
    const searchParams = queryString.parse(search);
    const setDevice = _.get(searchParams, 'setDevice', 'desktop');

    this.state = {
      systemConfig: this.props.systemConfig,
      navigation: {},
      setDevice,
      competitionSeasonTeams: [],
    };
  }

  componentWillMount() {
    if (this.props.systemConfig && this.state.systemConfig.navigation) {
      this.props.actions.loadNavigations(this.state.systemConfig.navigation);
    }

    // We should not load the saag for ?setDevice=mobile
    if (defaultConfig.saagEnabled && this.state.setDevice !== 'mobile') {
      if (isMobile()) {
        this.saagMobileTimeout = setTimeout(() => {
          this.loadSAAGData();
        }, 10000);
      } else {
        this.loadSAAGData();
      }
    }
  }

  componentDidMount() {
    // We should not load the saag for ?setDevice=mobile
    if (defaultConfig.saagEnabled && this.state.setDevice !== 'mobile') {
      this.timer = setInterval(() => this.loadSAAGData(), 30000);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.systemConfig.navigation !== nextProps.systemConfig.navigation
    ) {
      // menu navigation is updated, load the new menu navigation
      this.props.actions.loadNavigation(nextProps.systemConfig.navigation);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.navigation && this.props.navigation.id) {
      if (this.props.navigation.id !== prevProps.navigation.id) {
        if (
          this.props.navigation.navigations &&
          this.props.navigation.navigations.length
        ) {
          const navigations = this.props.navigation.navigations.filter(
            (nav) => nav.opta !== undefined,
          );
          if (navigations && navigations.length) {
            navigations.map((nav) => {
              const optaItems = _.get(nav, 'optaItems', null);
              if (optaItems) {
                this.loadMultipleCompetitionData(optaItems);
              } else {
                this.loadFixtures(
                  nav.opta.competitionId,
                  nav.opta.season,
                );
                this.loadResults(
                  nav.opta.competitionId,
                  nav.opta.season,
                );
                // this.loadTeams(nav.opta.competitionId, nav.opta.season);
              }
              this.loadTeams(nav.opta.competitionId, nav.opta.season);
              this.loadTables(nav.opta.competitionId, nav.opta.season);
              return null;
            });
          }
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.saagMobileTimeout) {
      clearTimeout(this.saagMobileTimeout);
    }
  }

  loadMultipleCompetitionData(optaItems) {
    optaItems.forEach((opta) => {
      this.loadFixtures(
        opta.competitionId,
        opta.season,
      );
      this.loadResults(
        opta.competitionId,
        opta.season,
      );
      // this.loadTeams(opta.competitionId, opta.season);
    });
  }

  loadTables(competitionId, season) {
    this.props.tablesActions.getTables(competitionId, season);
  }

  loadResults(competitionId, season) {
    this.props.resultsActions.getResults(competitionId, season);
  }

  loadFixtures(competitionId, season) {
    this.props.fixturesActions.getFixtures(competitionId, season);
  }

  loadTeams(competitionId, season) {
    // Update - specifying competitionId of 0 with any season id will retrieve
    // all competitions' teams with that season so just load in unique seasons
    let { competitionSeasonTeams } = this.state;

    if (!_.includes(competitionSeasonTeams, season)) {
      //this.props.teamActions.getTeams(competitionId, season);
      this.props.teamActions.getTeams(0, season);

      competitionSeasonTeams.push(season);
      this.setState(competitionSeasonTeams);
    }
  }

  loadSAAGData() {
    this.props.saagActions.getSAAGData();
  }

  render() {
    return (
      <SystemConfigContextProvider>
        <SearchProvider>
          <PortalRoutes setDevice={this.state.setDevice} />
        </SearchProvider>
      </SystemConfigContextProvider>
    );
  }
}

App.propTypes = {
  systemConfig: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    systemConfig: state.systemConfig,
    navigation: state.navigation,
    saagdata: state.saagdata,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(navigationAction, dispatch),
    tablesActions: bindActionCreators(tablesActions, dispatch),
    resultsActions: bindActionCreators(resultsActions, dispatch),
    fixturesActions: bindActionCreators(fixturesActions, dispatch),
    teamActions: bindActionCreators(teamActions, dispatch),
    saagActions: bindActionCreators(saagActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
