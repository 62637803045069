
import React from 'react';
import PropTypes from 'prop-types';

const ChatIcon = ({ width, height, color = '#fff', ...props }) => (

  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} viewBox="0 0 32 32" {...props}>
    <defs>
        <path id="chat_a" d="M25.356 7h-18v12h3.75v4.406L16.825 19h8.531V7zm1.5-1.5v15H17.34l-7.734 6v-6h-3.75v-15h21z"/>
    </defs>
    <g fill="none" fillRule="evenodd">
        <path fill={color} fillOpacity="0" d="M0 0h32v32H0z"/>
        <mask id="chat_b" fill={color}>
            <use xlinkHref="#chat_a"/>
        </mask>
        <use fill={color} xlinkHref="#chat_a"/>
        <g fill={color} mask="url(#chat_b)">
            <path d="M0 0h32v32H0z"/>
        </g>
    </g>
</svg>
);

ChatIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

ChatIcon.defaultProps = {
  color: 'currentColor',
  width: 32,
  height: 32
};

export default ChatIcon;
