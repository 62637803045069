import winston from 'winston';
import { Loggly } from 'winston-loggly-bulk';
import defaultConfig from '../../constants/defaultConfig';
import { getBaseProps } from '../loggly/utils';
import { getBrowser } from '../../utils';

const log = ({
  eventName,
  type, // need this passed in to determine what kind of log to dispatch (pass in as part of api)
  tags = [],
  userId,
  userType,
  userTypeStatus,
  ...props
}) => {
  // Add default message and tag values
  if (!eventName) {
    return;
  }

  // Destructure props
  const baseProps = getBaseProps();

  // Initial prop definitions
  const payload = {
    ...baseProps,
    ...props,
    event: {
      eventName: eventName,
      severity: type.toUpperCase(),
    },
    user: {
      userId: userId,
      userType: userType,
      userTypeStatus: userTypeStatus,
    },
  };

  const {
    logglyCustomerToken: token,
    logglySubdomain: subdomain,
  } = defaultConfig;

  // Ken (4th-Feb-2021):
  // OSN-928 - Currently firing to Loggly when isBulk=false is causing
  // CORS issue for Safari browser, so we are temporarily patching
  // it to use isBulk=true
  const browserData = getBrowser();
  const browser = browserData.name;
  const isBulk = browser === 'Safari';

  try {
    // Clear any existing Loggly transport or else
    // duplicate logging will occur
    winston.clear().add(
      new Loggly({
        token,
        subdomain,
        tags,
        json: true,
        isBulk
      }),
    );

    const EVENT_TYPES = {
      info: 'info',
      error: 'error',
    };

    if (type.toLowerCase() === EVENT_TYPES.info) {
      winston.log(EVENT_TYPES.info, payload);
    } else if (type.toLowerCase() === EVENT_TYPES.error) {
      winston.log(EVENT_TYPES.error, payload);
    }
  } catch (err) {
    console.log('logglyLogger', err);
  }
};

export { log };
