import React from 'react';
import PropTypes from 'prop-types';
import LoadingIcon from '../ui-kit/Icons/LoadingIcon';

const style = (type, width, disabled, rounded, theme) => {
  let addCss;
  let hoverCss = `hover:bg-teal hover:text-black`;
  let disabledCss = 'bg-light-indicator-grey text-black';
  switch (type) {
    case 'secondary':
      addCss = 'bg-transparent text-teal border-teal border-solid border-2';
      hoverCss = `hover:text-teal-light hover:border-teal-light`;
      break;
    case 'tertiary':
      addCss = 'bg-dark-grey text-white border-white border-solid border-2';
      hoverCss = `hover:bg-teal hover:text-black hover:border-teal`;
      break;
    case 'quaternary':
      addCss =
        'bg-medium-dark-grey text-white border-transparent border-solid border-2';
      break;
    case 'secondary-alternate':
      if (theme === 'light' || theme === 'lightv2') {
        addCss = 'bg-transparent text-black border-black border-solid border-2';
        hoverCss = `hover:text-alt-grey hover:border-alt-grey`;
      } else {
        addCss = 'bg-transparent text-white border-solid border-2';
        hoverCss =
          'hover:border-light-indicator-grey hover:text-light-indicator-grey active:border-white-1 active:text-white-1';
      }
      break;
    case 'secondary-alternate-filter':
      addCss =
        'bg-transparent text-white border-solid border-2 os-hover-brightness-65';
      hoverCss = '';
      break;
    case 'danger':
      addCss = `bg-live-red text-black os-hover-brightness-65`;
      hoverCss = `active:bg-live-red`;
      break;
    case 'secondary-alt':
      addCss = `bg-medium-dark-grey text-white`;
      hoverCss = `hover:bg-medium-grey`;
      break;
    case 'primary-alternate':
      addCss = 'bg-white text-black border-none';
      hoverCss = 'hover:bg-light-indicator-grey active:bg-white';
      break;
    case 'submit':
    case 'primary':
    default:
      if (theme === 'light' || theme === 'lightv2') {
        addCss = 'bg-cyan-blue text-white btn-box-shadow';
        hoverCss = `hover:bg-cyan-blue-dark active:bg-cyan-blue-light`;
      } else {
        addCss = 'bg-teal text-black';
        hoverCss = `hover:bg-teal-light active:bg-teal`;
      }
      break;
  }
  const twWidth = typeof width === 'string' ? ` ${width}` : '';
  return `${addCss} rounded-4xl ${
    disabled ? `${disabledCss} cursor-not-allowed` : hoverCss
  } focus:outline-none h-48 flex items-center justify-center px-16 text-sm
  truncate os-transition-btn min-w-128${twWidth}`;
};
/**
 * Optus Sport React Button component
 */
const Button = ({
  type,
  text,
  width,
  icon,
  handler,
  leftAlign,
  disabled,
  className,
  loading,
  rounded,
  theme = 'dark',
  buttonClassName = '',
  textClassName = '',
  htmlType,
  ...rest
}) =>
  htmlType === 'link' ? (
    <a
      style={!isNaN(width) ? { width: `${width}rem` } : null}
      className={`${style(
        type,
        width,
        disabled,
        rounded,
      )} w-full max-w-325 no-underline font-MarkProBold font-bold md:w-1/3 ${buttonClassName}`}
      {...rest}
    >
      {text}
    </a>
  ) : (
    <div className={className}>
      <button
        type={type}
        disabled={disabled}
        style={!isNaN(width) ? { width: `${width}rem` } : null}
        className={`${style(
          type,
          width,
          disabled,
          rounded,
          theme,
        )} ${buttonClassName} font-MarkProHeavy`}
        onClick={(e) => handler(e)}
      >
        {loading ? (
          <LoadingIcon className='w-full h-full p-8' />
        ) : (
          <span className='flex w-full items-center relative'>
            {text && (
              <span
                className={`flex flex-grow justify-center ${textClassName}`}
              >
                {text}
              </span>
            )}
            {icon && <span className='flex '>{icon}</span>}
          </span>
        )}
      </button>
    </div>
  );

Button.propTypes = {
  /** Button type */
  type: PropTypes.string,
  /** Color of button. If none provided, primary will be used */
  color: PropTypes.string,
  /** Button text */
  text: PropTypes.string,
  /** Icon for this button. Could have any valid icon available. */
  icon: PropTypes.node,
  /** Width supports a Tailwind width class passed on as string or a fixed width passed on as number in rem.**/
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Handler function for this button. This function will be invoked with the click event received on the button */
  handler: PropTypes.func.isRequired,
  /** Whether the button text is left aligned. Defaults to false.**/
  leftAlign: PropTypes.bool,
  /** Whether the button is disabled or not. Defaults to false.**/
  disabled: PropTypes.bool,
  /** Classes to apply to the root element of this component. Classes added here will override any default classes or props that accept classes. For example width - use with caution. */
  className: PropTypes.string,
  /* Used to display loading icon */
  loading: PropTypes.bool,
  /* Used to style border radius */
  rounded: PropTypes.bool,
  /* Used to style the text element */
  textClassName: PropTypes.string,
};

Button.defaultProps = {
  type: null,
  color: 'primary',
  text: '',
  width: '',
  icon: null,
  leftAlign: false,
  disabled: false,
  className: '',
  loading: false,
  rounded: false,
  textClassName: '',
};

export default Button;
