import _ from 'lodash';
import { fetchApi } from '../middleware/fetch';
import defaultConfig from '../constants/defaultConfig';

const {
  FEATURE_PHONE_CODE_VERIFICATION_USAGE_LIMIT_COOLDOWN,
  FEATURE_PHONE_CODE_VERIFICATION_USAGE_LIMIT_ATTEMPTS,
} = defaultConfig.features;

const VERIFICATION_RESET = `verification/RESET`;
const VERIFICATION_START = `verification/START`;
const VERIFICATION_SUCCESS = `verification/SUCCESS`;
const VERIFICATION_FAIL = `verification/FAIL`;
const VERIFICATION_BLOCKED = `verification/BLOCKED`;
const VERIFICATION_UNBLOCKED = `verification/UNBLOCKED`;

export const STAGE_SESSION_START = `verification/STAGE_SESSION_START`;
export const STAGE_VERIFY_MOBILE = `verification/STAGE_VERIFY_MOBILE`;
export const STAGE_CONFIRM_CODE = `verification/STAGE_CONFIRM_CODE`;
export const STAGE_RESEND_CODE = `verification/STAGE_RESEND_CODE`;

export const ERROR_MESSAGES = {
  INVALID_CODE: `This code doesn't seem to match the one we sent you!`,
  INVALID_TOKEN: `Your session has expired! Please refresh this page.`,
  INVALID_PARAMS: `Invalid parameters!`,
  LIMIT_EXCEEDED: `Limit exceeded! Please try again later. If the problem persists, please try a different phone number.`,
  INVALID_PHONE_NUMBER: `This is an invalid phone number. Check your phone number and try again.`,
  RESEND_CODE_FAILED: `Sorry, we encountered an error. Please try again. If the error persists, please refresh the page.`,
  UNKNOWN: `Unknown Error`,
};

const initialState = {
  token: null,
  error: null,
  errorCode: null,
  attempts: 0,
  blocked: false,
  stage: null,
};

let cooldownTimeout = null;

function isMaxAttempts(state) {
  return state.attempts >= FEATURE_PHONE_CODE_VERIFICATION_USAGE_LIMIT_ATTEMPTS;
}

function isBlocked(dispatch, getState) {
  const currentState = getState().verification;
  return currentState.blocked;
}

function getResponseData(response) {
  return _.get(response, `response.data`, response || {});
}

function getErrorData(response) {
  const { error } = getResponseData(response);
  return error;
}

// Workaround if the microservice does not have a consistent error signature.
function getErrorDescription(error) {
  const err = getErrorData(error);
  const { description, message = ERROR_MESSAGES.UNKNOWN } = err || {};
  const errorMessage = ERROR_MESSAGES[description] || description;
  return errorMessage || message;
}

function getErrorCode(error) {
  const err = getErrorData(error);
  const { description, message = `UNKNOWN` } = err || {};
  return description || message;
}

function setCoolDownTimeout(dispatch, getState, error) {
  if (cooldownTimeout) {
    return;
  }
  const currentState = getState().verification;
  if (getErrorCode(error) !== `LIMIT_EXCEEDED` && !isMaxAttempts(currentState)) {
    return;
  }
  cooldownTimeout = setTimeout(() => {
    dispatch({ type: VERIFICATION_UNBLOCKED });
    clearTimeout(cooldownTimeout);
    cooldownTimeout = null;
  }, FEATURE_PHONE_CODE_VERIFICATION_USAGE_LIMIT_COOLDOWN);
}

export default function verificationReducer(state = initialState, action) {
  const { type, payload } = action;
  const { stage } = payload || {};
  switch (type) {
    case VERIFICATION_RESET: {
      return {
        ...state,
        token: null,
      };
    }
    case VERIFICATION_START: {
      const attempts = state.attempts + 1;
      const blocked = isMaxAttempts(state) && {
        blocked: true,
      };
      return {
        ...state,
        token: null,
        error: null,
        errorCode: null,
        stage,
        attempts,
        ...blocked,
      }
    }
    case VERIFICATION_SUCCESS: {
      const { token } = payload;
      return {
        ...state,
        token,
        error: null,
        errorCode: null,
        stage,
        blocked: false,
        attempts: 0,
      };
    }
    case VERIFICATION_FAIL: {
      const { error } = payload;
      const errorCode = getErrorCode(error);
      const blocked = errorCode === `LIMIT_EXCEEDED` && {
        blocked: true,
      };
      return {
        ...state,
        token: null,
        error: getErrorDescription(error),
        errorCode,
        stage,
        ...blocked,
      };
    }
    case VERIFICATION_BLOCKED: {
      return {
        ...state,
        blocked: true,
      }
    }
    case VERIFICATION_UNBLOCKED: {
      return {
        ...state,
        attempts: 0,
        blocked: false,
      }
    }
    default:
      return state;
  }
}

export function verificationReset() {
  return {
    type: VERIFICATION_RESET,
  };
}

export function verificationStart(stage) {
  return {
    type: VERIFICATION_START,
    payload: {
      stage,
    },
  };
}

export function verificationSuccess(stage, response) {
  const { token } = response;
  return {
    type: VERIFICATION_SUCCESS,
    payload: {
      token,
      stage,
    },
  };
}

export function verificationError(stage, error) {
  return {
    type: VERIFICATION_FAIL,
    payload: {
      error,
      stage,
    },
  };
}

function getDeviceInfo() {
  return {
    // For now, do not do browser fingerprinting.
    deviceId: window.navigator.userAgent,
    userAgent: window.navigator.userAgent
  };
}

function createCodeRequestBody({ token, email, number, ...others }) {
  return {
    body: {
      ...getDeviceInfo(),
      token,
      email,
      mobileNumber: number,
      ...others,
    },
  };
}

export function startVerificationSession({ email, number }) {
  return async (dispatch, getState) => {
    if (isBlocked(dispatch, getState)) {
      return;
    }
    dispatch(verificationStart(STAGE_SESSION_START));
    const [response, error] = await fetchApi(`fe-api-authStartSession`, {
      method: `post`,
      body: {
        ...getDeviceInfo(),
        email,
      }
    });
    if (error) {
      setCoolDownTimeout(dispatch, getState, error);
      dispatch(verificationError(STAGE_SESSION_START, error));
      return;
    }
    const { token } = response;
    const verifyMobileHandler = handleVerifyMobile({ token, email, number });
    if (verifyMobileHandler) {
      return verifyMobileHandler(dispatch, getState);
    }
  };
}

export function resendConfirmationCode({ token, email, number, stage = STAGE_RESEND_CODE }) {
  return async (dispatch, getState) => {
    // This action can be an independent action or as part of another action/
    if (stage === STAGE_RESEND_CODE) {
      if (isBlocked(dispatch, getState)) {
        return;
      }
      dispatch(verificationStart(stage));
    }
    const [response, error] = await fetchApi(`fe-api-resendConfirmationCode`, {
      method: `post`,
      ...createCodeRequestBody({ token, email, number }),
    });
    if (error) {
      setCoolDownTimeout(dispatch, getState, error);
      dispatch(verificationError(stage, error));
      return;
    }
    dispatch(verificationSuccess(stage, response));
    return;
  };
}

function handlePhoneNumberExists(error, { email, number }) {
  if (getErrorDescription(error) !== `PHONE_NUMBER_EXISTS`) {
    return null;
  }
  return (dispatch, getState) => {
    const { token } = getResponseData(error);
    const resendConfirmationCodeHandler = resendConfirmationCode({
      token,
      email,
      number,
      stage: STAGE_VERIFY_MOBILE
    });
    return resendConfirmationCodeHandler(dispatch, getState);
  };
}

function handleVerifyMobile({ token, email, number }) {
  return async (dispatch, getState) => {
    const [response, error] = await fetchApi(`fe-api-authCreateValidateUser`, {
      method: `post`,
      ...createCodeRequestBody({ token, email, number }),
    });

    // Ken Ho::(06Jan2020) - extract possible error from 200 or 4xx responses (the error node can be in either response or error)
    // Julius::(12Mar2020):
    // `response` and `error` exists then it is a 200 response with an error field.
    // Generally speaking, 200 OK responses should not have errors.
    // This is a workaround for the API returning an error field on a 200 response.
    const payload = response && error ? response : (response || error);
    const phoneNumberExistsHandler = handlePhoneNumberExists(payload, { email, number });
    if (phoneNumberExistsHandler) {
      await phoneNumberExistsHandler(dispatch, getState);
      return;
    }
    if (error) {
      setCoolDownTimeout(dispatch, getState, error);
      dispatch(verificationError(STAGE_VERIFY_MOBILE, error));
      return;
    }
    dispatch(verificationSuccess(STAGE_VERIFY_MOBILE, response));
  };
}

export function confirmCode({ token, code, email, number }) {
  return async (dispatch, getState) => {
    dispatch(verificationStart(STAGE_CONFIRM_CODE));
    const [response, error] = await fetchApi(`fe-api-confirmCode`, {
      method: `post`,
      ...createCodeRequestBody({ token, email, number, code }),
    });
    if (error) {
      setCoolDownTimeout(dispatch, getState, error);
      dispatch(verificationError(STAGE_CONFIRM_CODE, error));
      return;
    }
    dispatch(verificationSuccess(STAGE_CONFIRM_CODE, response));
  };
}
