import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropDown from './DropDown';
import NavLink from './NavLink';
import { routesList as getRoutesList } from '../../../constants/routesList';
import { withFeatureFlags } from '../../context/withFeatureFlags';

class UserDropDown extends Component {
  render() {
    const { getFeatureFlags } = this.props;
    const featurePaymentPage = getFeatureFlags('FEATURE_PAYMENT_PAGE');
    const routesList = getRoutesList();
    const settingsRoute = featurePaymentPage
      ? routesList.find((item) => item.name === 'UserSettings')
      : null;
    const settingsPath = settingsRoute
      ? _.get(settingsRoute, 'path', '/')
      : null;

    const changePasswordRoute = routesList.find(
      (item) => item.name === 'ChangePassword',
    );
    const changePasswordPath = _.get(changePasswordRoute, 'path', '/');

    return (
      <DropDown
        className="absolute pin-r z-30 user-dropdown-component"
        onOutSideClick={this.props.onOutSideClick}
        open={this.props.open}
      >
        <NavLink
          className="hover:bg-medium-dark-grey"
          label="Change Password"
          link={changePasswordPath}
        />
        {settingsPath ? (
          <NavLink
            className="hover:bg-medium-dark-grey"
            label="Settings"
            link={settingsPath}
          />
        ) : null}
        <li
          className="flex flex-no-wrap items-center os-transition w-full cursor-pointer h-48
        pr-8 pl-16 list-reset border-l-3 hover:border-teal border-transparent hover:bg-medium-dark-grey"
          onClick={this.props.onUserLogout}
        >
          <p className="text-white hover:text-teal text-sm flex-grow no-underline">
            Logout
          </p>
        </li>
      </DropDown>
    );
  }
}

UserDropDown.propTypes = {
  open: PropTypes.bool.isRequired,
  onOutSideClick: PropTypes.func,
  onUserLogout: PropTypes.func.isRequired,
};

UserDropDown.defaultProps = {
  open: false,
};

export default withFeatureFlags(UserDropDown);
