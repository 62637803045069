import _ from 'lodash';

const user = (state) => state?.user;
const initObserver = (state) => state?.user && state.user?.initObserver;
const isLoggedIn = (state) =>
  state?.user && state?.user.initObserver && state.user?.loggedIn;
const userType = (state) => state?.user && state.user?.userType;
const userTypeUpdated = (state) => userType(state)?.updated;
const userStrictType = (state) =>
  state?.user &&
  state.user?.loggedIn &&
  userType(state)?.updated &&
  userType(state)?.type;
const isPremiumUser = (state) =>
  isLoggedIn(state) && userStrictType(state) === 'premium';

const getUserId = (user) => {
  // Sometimes there is race condition as analytics.analyticUserId is not ready/set
  // on hard page loads so we will fallback onto user management id (Cognito user id)
  const userAuthDataId = _.get(user, [
    'authData',
    'cognitoUser',
    'attributes',
    'sub',
  ]);
  let userId = _.get(user, `analytics.analyticUserId`);

  userId =
    (!userId || userId === '-1') && !_.isEmpty(userAuthDataId)
      ? userAuthDataId
      : userId;

  return userId;
};

const getUserSegmentTags = (user) => {
  const userSubscriptions = _.get(user, 'orders') || [];

  return userSubscriptions.map(subscription => _.get(subscription, 'segmentTag'));
};

export {
  user,
  initObserver,
  isLoggedIn,
  userType,
  userStrictType,
  isPremiumUser,
  getUserId,
  userTypeUpdated,
  getUserSegmentTags,
};
