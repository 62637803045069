import _ from 'lodash';
import * as types from '../constants/actionTypes';
import initialState from './initialState';
import AllTeams from "../components/views/featured-teams";

export default function teams(state = initialState.teams, action) {
  let currentTeams;

  switch (action.type) {

    case types.GET_TEAMS_INPROGRESS:
      currentTeams = {
        ...state,
        inProgress: true,
        isSuccess: false
      };

      return currentTeams;

    case types.GET_TEAMS_SUCCESS:
      return {
        ...state,
        inProgress: false,
        isSuccess: true,
        allTeams: [...state.allTeams, ...action.teams.rows]
      };


    case types.GET_TEAMS_FAILURE:
      currentTeams = {
        // for failure case, use whatever has been loaded before
        ...state,
        inProgress: false,
        isSuccess: false
      };

      // Only load in static list of all teams if current state of allTeams node is empty
      if (_.isEmpty(state)) {
        currentTeams.allTeams = AllTeams;
      }

      return currentTeams;

    default:
      return state;
  }
}

