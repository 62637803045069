import React from 'react';
import PropTypes from 'prop-types';
import DownIcon from "./Icons/DownIcon";
import Label from "./Label";


class SelectBox extends React.Component {

  state = { showPopup: false, selectedItem: '', _validatorMessage: '' };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.isFormField) {
      if (prevProps.virgin !== this.props.virgin ||
        prevProps.valid !== this.props.valid
      ) {
        const { valid, virgin, validatorMessage } = this.props;
        if (!valid && !virgin) {
          this.setState({ _validatorMessage: validatorMessage });
        } else {
          this.setState({ _validatorMessage: '' });
        }
      }
    }
  }

  selectItem = (item) => {
    const { changed } = this.props;
    this.setState({ selectedItem: item });
    if (changed) {
      changed(item);
    }
    this.togglePopup(false);
  };

  handleClick = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }
    this.togglePopup(false);
  };

  togglePopup = (showPopup) => { this.setState({ showPopup }); };

  itemBgState = (selectedItem, item) => (
    selectedItem && selectedItem.value === item.value ? 'bg-teal text-black' : 'bg-dark-grey text-white'
  );
  buttonClasses = (isFormField, _validatorMessage, disabled) => {
    if (!isFormField) {
      return 'flex justify-center flex-row w-full bg-dark-grey h-48 px-16 text-white text-left os-transition os-transitionproperty-all focus:border-white focus:outline-none border-light-grey border items-center';
    } else {
      return `flex justify-center flex-row w-full h-48 px-16 text-white text-left os-transition os-transitionproperty-all focus:outline-none border-2 rounded items-center ${_validatorMessage
        ? 'border-live-red'
        : 'border-light-grey focus:border-white'
        } ${disabled ? 'bg-medium-grey' : 'bg-dark-grey'}`;
    }
  };
  spanClasses = (isFormField, selection) => {
    if (!isFormField) {
      return `flex-grow ${selection === null ? 'text-light-grey' : 'text-white'} leading-base`;
    } else {
      if (this.state.selectedItem || this.props.defaultItem) {
        if (this.state.selectedItem.value || this.props.defaultItem) {
          return `flex-grow text-white leading-base`;
        } else {
          return `flex-grow text-white opacity-50 leading-base`;
        }
      } else {
        return `flex-grow text-white opacity-50 leading-base`;
      }
    }
  };

  render() {
    const { isFormField, labelTextSize, validatorMessage, changed, valid, virgin, width, items, placeHolder, labelText, defaultItem, maxRows, disabled, ...props } = this.props;
    const { selectedItem, showPopup, _validatorMessage } = this.state;
    const selection = selectedItem ? selectedItem : (defaultItem ? defaultItem : placeHolder ? null : items[0]);
    return (
      <div className={`flex flex-col ${width}`}>
        {labelText && <Label textSize={labelTextSize ? labelTextSize : 'text-sm'} className="font-MarkProBold mb-16" color={_validatorMessage ? 'text-live-red' : 'text-white'}>{labelText}</Label>}
        <div
          ref={(node) => { this.node = node; }}
          className={`w-full relative `}
          {...props} >
          <button
            className={this.buttonClasses(
              isFormField,
              _validatorMessage,
              disabled,
            )}
            onClick={(e) => {
              e.preventDefault();
              this.togglePopup(true)
            }}
            disabled={disabled}
          >
            <span className={this.spanClasses(
              isFormField,
              selection
            )}>{selection ? selection.label : (placeHolder ? placeHolder : '')}</span>
            <DownIcon />
          </button>
          {showPopup &&
            <div
              style={{ maxHeight: `${maxRows * 50}px` }}
              className="w-full absolute pin-l bg-dark-grey text-white border-l border-r border-b overflow-y-auto z-300">
              <ul className="list-reset">
                {items.map((item, i) =>
                  <li key={i} className="cursor-pointer border-light-grey border-t-0 border-b" onClick={() => this.selectItem(item)}>
                    <span className={`h-48 p-16 block ${this.itemBgState(selection, item)} hover:bg-teal hover:text-black`}>{item.label}</span>
                  </li>)
                }
              </ul>
            </div>
          }
          {isFormField && _validatorMessage &&
            <Label
              textSize={'text-xs'}
              color={'text-live-red'}
              className={`
            font-MarkProBold
            block
            os-transition
            os-transitionproperty-all
            ${_validatorMessage ? `mb-16` : ``}
          `}
            >
              {_validatorMessage}
            </Label>
          }


        </div>
      </div>
    );
  }
}

SelectBox.propTypes = {
  /** Indicating whether this component's used in a Form(e.g. Venues page bottom form) Defaults to false. */
  isFormField: PropTypes.bool,
  /** Text size override for the Label for this input. */
  labelTextSize: PropTypes.string,
  /** validator message */
  validatorMessage: PropTypes.string,
  /** Whether this is the first touch. Defaults to true */
  virgin: PropTypes.bool,
  /** Whether the input is valid. Defaults to false */
  valid: PropTypes.bool,

  /** Placeholder for this input. Null by default */
  placeHolder: PropTypes.string,
  /** Item in the items array to be selected by default. If none provided & placeholder is not provided, first item will be selected by default. */
  defaultItem: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string
  }),
  /** onBlur event handler. The function will be invoked with the original event */
  onBlur: PropTypes.func,
  /** onFocus event handler. The function will be invoked with the original event */
  onFocus: PropTypes.func,
  /** Value this input field is currently holding. */
  value: PropTypes.string,
  /** Handler for change event. This function will be invoked with the new value when a user changes the input */
  changed: PropTypes.func,
  /** Width supports a Tailwind width class passed on as string or a fixed width passed on as number in rem.**/
  width: PropTypes.string,
  /** Array of items to show with structure: ``{'label': string,'value': string}`` */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })).isRequired,
  /** Label for this input. Defaults to null. */
  labelText: PropTypes.string,
  /** Whether this input is disabled or not. Defaults to false */
  disabled: PropTypes.bool,
  /** Max rows to be displayed before scrolling */
  maxRows: PropTypes.number,
};

//Default props
SelectBox.defaultProps = {
  isFormField: false,
  labelTextSize: 'text-sm',
  validatorMessage: '',
  virgin: true,
  valid: false,
  placeHolder: null,
  width: 'w-full',
  value: null,
  labelText: null,
  disabled: false,
  maxRows: 4
};

export default SelectBox;
