import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import jsCookie from 'js-cookie';
import HelpSupportModal from './ui-kit/HelpSupportModal';
import Button from './ui-kit/Button';
import ChatIcon from './ui-kit/Icons/ChatIcon';
import {
  SIGNUP_INITIATED_FROM_COOKIE_NAME,
  SIGNUP_INITIATED_FROM_COOKIE_VALUES,
} from 'utils/cookies';

class HelpSupportUI extends Component {
  render() {
    const isLoggedIn = this.props.user.loggedIn;
    const linkCss = `font-MarkPro leading-1.29 md:leading-1.13 text-teal hover:text-teal-dark active:text-teal-light active:no-underline os-transition os-transitionproperty-all text-sm md:text-base`;
    const loginLink = (
      <a className={linkCss} href='/login'>
        log in
      </a>
    );
    const registerLink = (
      <a
        className={linkCss}
        href='/register'
        onClick={(e) => {
          jsCookie.set(
            SIGNUP_INITIATED_FROM_COOKIE_NAME,
            SIGNUP_INITIATED_FROM_COOKIE_VALUES.OTHER_SIGNUP_LINK,
          );
          return true;
        }}
      >
        sign up here
      </a>
    );
    return (
      <HelpSupportModal
        open={this.props.showModal}
        onClose={() => this.props.closeModal()}
        type='helpsupport'
        newHome={this.props.newHome}
      >
        <div className='flex flex-col pt-48 xl:pt-32'>
          <h1 className='font-MarkProHeavy text-2xl xl:text-4xl text-white leading-none'>
            Help &amp; Support
          </h1>
          <span className='text-base md:text-xl mt-32 text-white font-MarkProHeavy leading-normal md:leading-loose'>
            How can we help?
          </span>
          <div className='h-auto md:h-48 mt-16 mb-8 md:mb-16'>
            <span className='text-sm md:text-base text-white font-MarkPro leading-1.29 md:leading-normal'>
              Take a look at the most common support questions below. If you
              don't find an answer you are looking for, contact our app support
              team.
            </span>
          </div>

          {isLoggedIn ? (
            <div
              className={`
                w-full
                my-32
                flex
                items-center
                flex-col
                sm:flex-row
              `}
            >
              <Button
                className={`
                  w-full
                  mb-16
                  sm:mb-0
                  sm:mr-30
                `}
                text='Submit a bug or issue'
                type='primary'
                handler={() => {
                  this.props.closeModal();
                  this.props.history.push('/help');
                }}
                width='w-full md:w-279'
                icon={<ChatIcon color='#000' />}
              />
              <Button
                className={`
                  w-full
                `}
                text='View active issues'
                type='secondary'
                handler={() => {
                  this.props.closeModal();
                  // Trigger bell icon hover
                  this.props.showNotification();
                }}
                width='w-full md:w-279'
              />
            </div>
          ) : (
            <div className='my-32 flex flex-col'>
              <span className='text-base md:text-xl text-white font-MarkProHeavy leading-normal md:leading-loose'>
                Log an Issue
              </span>
              <span className='text-sm md:text-base text-white font-MarkPro leading-1.29 md:leading-normal mt-16'>
                To raise a support ticket, please {loginLink} first.
              </span>
              <span className='text-sm md:text-base text-white font-MarkPro leading-1.29 md:leading-normal mt-16'>
                If you don't have an account, {registerLink}.
              </span>
            </div>
          )}

          <div className='h-24 md:h-32 flex items-center justify-start mb-14 '>
            <span className=' text-base md:text-md text-white font-MarkProHeavy leading-double md:leading-1.78'>
              Quick help links
            </span>
          </div>

          <div className='h-160 md:h-192 flex flex-col items-start justify-between'>
            <a
              className={linkCss}
              href='https://www.optus.com.au/for-you/support/entertainment/optus-sport-hub'
              target='blank'
            >
              Frequently Asked Questions
            </a>
            <a
              className={linkCss}
              href='https://www.optus.com.au/for-you/support/answer?id=6989'
              target='blank'
            >
              How to Watch Optus Sport
            </a>
            <a
              className={linkCss}
              href='https://www.optus.com.au/for-you/support/answer?id=20213'
              target='blank'
            >
              How to Watch Optus Sport in 1080p HD
            </a>
            <a className={linkCss} href='/usersettings' target='blank'>
              Manage account
            </a>
            <a className={linkCss} href='/help' target='blank'>
              Report an Issue
            </a>
          </div>
        </div>
      </HelpSupportModal>
    );
  }
}

export default withRouter(HelpSupportUI);
