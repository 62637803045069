import _ from 'lodash';
import axios from 'axios';
import * as types from '../constants/actionTypes';
import defaultConfig from 'constants/defaultConfig';
import { getVcmsSeasonId } from 'utils/opta';

export function getMatchHighlights(competitionId, season, matchId) {
  return (dispatch) => {
    const {
      vcmsBaseUrl,
      VCMSConfig: { matchHighlightsEndpoint },
    } = defaultConfig;
    const vcmsSeasonId = getVcmsSeasonId(competitionId);
    const url = `${vcmsBaseUrl}${matchHighlightsEndpoint}?season=${vcmsSeasonId}&competition=${competitionId}&match=${matchId}`;
    const matchhighlights = { competitionId, season, matchId };
    dispatch({ type: types.GET_MATCHHIGHLIGHTS_INPROGRESS, matchhighlights });

    axios
      .get(url)
      .then((res) => {
        const isSuccess = _.get(res, 'data.result[0].statuscode', 'N') === 'Y';

        if (isSuccess) {
          matchhighlights.rows = _.get(
            res,
            'data.APIinfo[0].HighlightInfo',
            [],
          );
          dispatch({
            type: types.GET_MATCHHIGHLIGHTS_SUCCESS,
            matchhighlights,
          });
        } else {
          dispatch({
            type: types.GET_MATCHHIGHLIGHTS_FAILURE,
            matchhighlights,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: types.GET_MATCHHIGHLIGHTS_FAILURE, matchhighlights });
      });
  };
}
