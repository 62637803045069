/*
 * Simliar to lodash, use this utility to pluck off properties that are matched on the second argument passed as an array
 * The third arg is similar to lodash default value
 * const getProperty = pick(obj, ['a', 'b', 'c'], 'Not found');
 */

const pick = (obj, keys, defaultValue) => {
  const foundKey = keys.find((key) => obj.hasOwnProperty(key));
  return foundKey ? obj[foundKey] : defaultValue;
};

export default pick;
