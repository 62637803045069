import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

/**
 * A container that lazy loads other components. NOTE: This component currently only works when it fills the page and
 * will be refactored to support any container size.
 *
 * It sets the property `lazyLoaded` initially to false for all child components and then sets the
 * property to true when a component becomes visible.
 *
 * All children of this component must accept a boolean property `lazyLoaded` and the default value must be
 * **true**. It is entirely up to the child component to decide what to display and not to display depending
 * on the state of `lazyLoaded`.For example: The Poster component will not show the image if `lazyLoaded` is set to
 * false.
 */
class LazyLoad extends Component {
  constructor(props, context) {
    super(props, context);
    this.onScroll = this.onScroll.bind(this);

    this.state = {
      visible:  []
    };

    this.firstLoad = true;
    this.timer = null;
  }

  componentDidMount() {
    this.addHandlers();
    this.onScroll();
  }

  componentWillUnmount() {
    this.removeHandlers();

    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  addHandlers() {
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onScroll);
  }

  removeHandlers() {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onScroll);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.children !== this.props.children) {
      this.setState({
        visible: []
      }, () => {
        this.removeHandlers();
        this.onScroll();
        this.addHandlers();
      });
    }
  }


  onScroll() {

    const node = ReactDOM.findDOMNode(this);

    if (node.children) {
      let visible = this.state.visible;
      let oldVisible = Array.apply(null, visible);
      let el;
      let i;
      let allLoaded = true;
      let repaint = false;
      let elRect;
      let inside;

      if (this.state.visible.length !== node.children.length) {
        visible = new Array(node.children.length);
        repaint = true;
      }

      if (node.children.length > 0 &&
        node.children[0].offsetTop === 0 &&
        node.children[0].clientHeight === 0 &&
        this.firstLoad) {
        // this code is used to avoid lazy load all element when page first load and all element was on top

        if (this.timer === null) {
          this.timer = setTimeout(() => {
              this.timer = null;
              this.onScroll();
            },
            500);
          this.firstLoad = false;
        }

        return;
      }


      if (node.children.length === 0 && node.children.length !== this.props.children) {

        if (this.timer === null) {
          // only start the time when the timer hasn't start yet
          this.timer = setTimeout(() => {
              this.timer = null;
              this.onScroll();
            },
            500);
        }

        return;
      }

      for (i = 0; i < node.children.length; i++) {
        el = node.children[i];
        elRect = el.getBoundingClientRect();
        inside = (elRect.top < window.innerHeight);

        if (!(visible[i] = (visible[i] ? true : inside))) {
          allLoaded = false;
        }
        if (visible[i] !== oldVisible[i]) {
          repaint = true;
        }
      }
      if (allLoaded) {
        this.removeHandlers();
      }
      if (repaint) {
        this.setState({
          visible: visible
        });
      }
    }
  }

  render() {
    let children;

    if (this.props.children) {
      children = [].concat(this.props.children)
        .filter(item => item !== undefined)
        .map((item, index) => {
          if (this.state.visible.length > index ? this.state.visible[index] : false) {
            return item; // components must default to lazyLoaded = true so don't clone the ones that are already loaded
          }

          return React.cloneElement(item, {lazyLoaded: false});
        });
    }

    return (
      <div className={`relative ${this.props.className}`}>
        {children}
      </div>
    );
  }
}

LazyLoad.propTypes = {
  className: PropTypes.string
};

LazyLoad.defaultProps = {
  className: ''
};

export default LazyLoad;
