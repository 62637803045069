import _ from 'lodash';
import * as analyticService from '../../services/analytic';
import pjson from '../../../package.json';
import defaultConfig from '../../constants/defaultConfig';

import { getBrowser, getOS, getDeviceDetail } from '../../utils/userAgent';

const getBaseProps = () => {
  const browserData = getBrowser();
  const osData = getOS();
  const deviceDetail = getDeviceDetail();

  const userName = _.get(
    localStorage && localStorage,
    `CognitoIdentityServiceProvider.${defaultConfig.awsUserPoolWebClientId}.LastAuthUser`,
  );
  const userData = JSON.parse(
    _.get(
      localStorage && localStorage,
      `CognitoIdentityServiceProvider.${defaultConfig.awsUserPoolWebClientId}.${userName}.userData`,
      '{}',
    ),
  );

  const userId = _.get(userData, 'Username');

  const logglyBaseTemplate = {
    app: {
      environment: defaultConfig.logglyEnv,
      appName: pjson.name,
      appVersion: pjson.version,
      platform: 'Web',
    },
    device: {
      browserData: browserData,
      osData: osData,
      deviceDetail: deviceDetail,
    },
    error: {},
    user: {
      userId: userId,
      userType: 'unknown',
    },
  };

  return logglyBaseTemplate;
};

const getCommonProps = (logData) => {
  const { app, device, user, asset } = analyticService.getCommonProps(logData);
  const { appName, platform, environment, appVersion, advertConsent } = app;
  const { deviceId, OSVersion, browserName, browserVersion } = device;
  const { userId = '', userTags = [], userType = '' } = user;

  return {
    asset,
    app: {
      appName,
      platform,
      environment,
      appVersion,
    },
    device: {
      deviceId,
      OSVersion,
      browserName,
      browserVersion,
      deviceTime: new Date().getTime(),
      advertConsent,
    },
    user: {
      userId,
      userTags,
      userType,
    },
  };
};

export { getCommonProps, getBaseProps };
