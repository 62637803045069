import UAParser from 'ua-parser-js';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import defaultConfig from '../constants/defaultConfig';

let parser = new UAParser(window.navigator.userAgent);
let parsedUA = parser.getResult();

export function getBrowser() {
  return parser.getBrowser();
}

export function getOS() {
  return parser.getOS();
}

export function getDevice() {
  return {
    ...parser.getDevice(),
    ...generateDeviceId(),
  };
}

export function isMobile() {
  const deviceData = getDevice();
  const deviceType = _.get(deviceData, 'type');

  return _.includes(['mobile', 'tablet'], deviceType);
}

export function getDeviceDetail() {
  let deviceDetail = null;
  const vendor = _.get(parsedUA, ['details', 'device', 'vendor'], null);
  const model = _.get(parsedUA, ['details', 'device', 'model'], null);
  let deviceType = _.get(parsedUA, ['device', 'type'], null);
  deviceType =
    deviceType === 'mobile' || deviceType === 'tablet' ? 'tablet' : 'PC';

  if (vendor) {
    deviceDetail = vendor + '|';
  }

  if (model) {
    deviceDetail += model;
  }

  if (!deviceDetail) {
    // no detail device type information in user agent, use the device type metadata
    deviceDetail = deviceType;
  }

  return deviceDetail;
}

export function getParsedUA() {
  return parsedUA;
}

export function generateDeviceId() {
  if (window && window.localStorage) {
    const key = 'deviceId';
    let deviceId = window.localStorage.getItem(key);
    if (!deviceId) {
      deviceId = uuidv4();
      window.localStorage.setItem(key, deviceId);
    }
    return { deviceId };
  }

  // Fallback to new uuid if window or window.localStorage not available
  return { deviceId: uuidv4() };
}

export function getAppInfo() {
  const {
    advertConsent,
    name: appName = 'optus-sport-portal',
    env: environment = '',
    version: appVersion = '',
  } = defaultConfig;

  return {
    appName,
    platform: 'web',
    environment,
    appVersion,
    advertConsent,
  };
}
