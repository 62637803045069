import React, { Component } from "react";

class RadialGradient extends Component {
  render() {
    return (
        <div className="teamlisting-radial-gradient-overlay w-full h-full relative">
            <svg className="legibility-gradient z-5" width="100%" height="100%" viewBox="0 0 100% 100%" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <defs>
                    <radialGradient fx="50%" fy="50%" r="82.1444116%" gradientTransform="matrix(-.58486 0 0 -1 .7924275 1)" id="b">
                        <stop stopOpacity="0" offset="0%"/>
                        <stop stopOpacity=".44999999" offset="100%"/>
                    </radialGradient>

                    <rect id="a" x=".5" width="100%" height="100%"/>
                </defs>

                <use fill="url(#b)" opacity=".40111607" xlinkHref="#a" fillRule="evenodd"/>
            </svg>
        </div>
    )
  }
}

export default RadialGradient;