import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPageTitleFromRootPath } from '../../utils/getPageTitle';
import { setOGTitle } from 'utils/openGraph';

class UpdateDocumentTitle extends Component {
  updateDocumentTitle(title) {
    document.title = title;
  }

  handleTitleUpdate() {
    let title = this.props.documentTitle;
    const path = this.props.location.pathname;

    const mappedTitle = getPageTitleFromRootPath(path, '');
    title = mappedTitle || title;

    if (!path.includes('articles')) {
      this.updateDocumentTitle(title);
    }
  }

  render() {
    this.handleTitleUpdate();
    return (
      <>
        {setOGTitle(document.title)}
        {this.props.children}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    documentTitle: state.documentTitle,
  };
};

export default withRouter(connect(mapStateToProps)(UpdateDocumentTitle));
