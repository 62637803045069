import _ from 'lodash';
import moment from 'moment';
import defaultConfig from '../../constants/defaultConfig';
import { getBrowser, getDevice, getOS } from '../../utils/userAgent';
import PlayerSession from '../../utils/playerSessionVersion2';
import { getPlayerInfo } from '../../utils/getPlayerInfo';
import * as videoService from '../video';
import {
  getClonedSearchSessionId,
  popCommingFromSearchResultsCookie,
} from '../../components/search/__analytics__';
import { getAppInfo } from '../../utils';
import {
  getEventTrackingProps,
  segmentEvents,
  SegmentErrorMapping,
  getHttpRequestResponsePayloadForApi,
  getErrorPropsForApi,
  SegmentPropertyExclusionList,
  PlaybackPositionOverrideList,
} from '../../utils/segmentAnalyticsHelper';
import { selectors } from '../../store/createPortalStore';

const heartbeatInterval = 60 * 1000;

const defaultVideoType = 'content';
const defaultHeartbeatAllowed = false;
let globalPosition = 0;
let globalFromPosition = 0;
let globalToPosition = 0;

function _addSegmentSearchSession() {
  if (popCommingFromSearchResultsCookie()) {
    return {
      search_session_id: getClonedSearchSessionId(),
    };
  }

  return {};
}

function _trackHeartbeatEvent(commonTrackingPropsFunc) {
  // Fallback for when cases we don't need a fresh copy
  // i.e. commonTrackingProps object passed in from handleBeforeComplete()
  const commonTrackingProps =
    typeof commonTrackingPropsFunc === 'function'
      ? commonTrackingPropsFunc()
      : commonTrackingPropsFunc;
  SegmentSession.setToPosition();
  const videoType = SegmentSession.videoType;

  if (videoType === 'ad') {
    _trackAdPlaying(commonTrackingProps);
  } else {
    _trackContentPlaying(commonTrackingProps);
  }
}

function _trackContentPlaying(commonTrackingProps) {
  let trackProps = getPlayContentTrackingProps(commonTrackingProps);

  const props = {
    event: getEventTrackingProps('videoContentPlaying'),
    ...SegmentSession.getPositions(),
    ...trackProps,
  };

  SegmentSession.trackEvent(segmentEvents.videoContentPlaying, props);
  SegmentSession.setFromPosition();
}

function _trackAdPlaying(commonTrackingProps) {
  const props = {
    event: getEventTrackingProps('videoAdPlaying'),
    ...SegmentSession.getPositions(),
    ...commonTrackingProps,
  };

  SegmentSession.trackEvent(segmentEvents.videoAdPlaying, props);
  SegmentSession.setFromPosition();
}

function getAdTrackingProps(currentPosition = 0, getState) {
  const {
    yoSessionManager,
    epochAdvertStart = 0,
    advertStartPosition = 0,
    isLive,
    isInAdBreak,
    adSchema,
    advertStallDuration = 0,
  } = getState();

  let adProps = {};

  if (isInAdBreak && yoSessionManager) {
    if (yoSessionManager) {
      let currentAdPlayingPosition = 0;
      //using position ad start captured during ad start from state for live assets
      if (isLive) {
        const currentEpoch = Date.now();
        const adDurationWatchedSeconds =
          (currentEpoch - epochAdvertStart) / 1000 - advertStallDuration;
        currentAdPlayingPosition = adDurationWatchedSeconds;
      } else {
        currentAdPlayingPosition =
          currentPosition > 0 ? currentPosition - advertStartPosition : 0;
      }
      adProps = {
        position: Math.round(currentAdPlayingPosition),
        ad_break: adSchema,
      };
    }
  }
  return adProps;
}

function handleSeek(seekPosition, commonTrackingProps) {
  SegmentSession.setToPosition();

  const { livestream } = commonTrackingProps;
  const liveEpochTime = new Date().getTime();
  const position = livestream
    ? Math.round(liveEpochTime / 1000 + seekPosition)
    : Math.round(seekPosition);

  const props = {
    ...commonTrackingProps,
    ...SegmentSession.getPositions(),
    // KenH(27-July-2020):: Current issue where seekPosition is incorrect for
    // live asset dvr whereby we are expecting a negative seek position but a
    // large number is observed (presumably the duration of the stream)
    seek_position: position,
    event: getEventTrackingProps('videoPlaybackSeekStarted'),
  };

  SegmentSession.trackEvent(segmentEvents.videoPlaybackSeekStarted, props);
  SegmentSession.clearInterval();
  SegmentSession.setFromPosition(position);

  return liveEpochTime;
}

function handleSeeked(commonTrackingProps) {
  SegmentSession.setToPosition();
  const props = {
    ...commonTrackingProps,
    event: getEventTrackingProps('videoPlaybackSeekCompleted'),
  };
  SegmentSession.trackEvent(segmentEvents.videoPlaybackSeekCompleted, props);
  SegmentSession.setFromPosition();
}

function handlePause(commonTrackingProps) {
  SegmentSession.setToPosition();

  const props = {
    ...commonTrackingProps,
    event: getEventTrackingProps('videoPlaybackPaused'),
    ...SegmentSession.getPositions(),
  };

  SegmentSession.trackEvent(segmentEvents.videoPlaybackPaused, props);
  SegmentSession.clearInterval();
  SegmentSession.setFromPosition();
}

function handleBuffer(commonTrackingProps) {
  SegmentSession.setToPosition();

  const props = {
    ...commonTrackingProps,
    event: getEventTrackingProps('videoPlaybackBufferStarted'),
    ...SegmentSession.getPositions(),
  };

  SegmentSession.trackEvent(segmentEvents.videoPlaybackBufferStarted, props);
  SegmentSession.clearInterval();
  SegmentSession.setFromPosition();
}

function handleBufferCompleted(commonTrackingProps) {
  SegmentSession.setToPosition();

  const props = {
    ...commonTrackingProps,
    event: getEventTrackingProps('videoPlaybackBufferCompleted'),
  };

  SegmentSession.trackEvent(segmentEvents.videoPlaybackBufferCompleted, props);
  SegmentSession.setFromPosition();
}

function updateVideoError(errorObj, commonTrackingProps, getState) {
  const { isPlaybackStarted, isFirstFrameRendered } = getState();
  const { session_id } = commonTrackingProps;
  if (errorObj && session_id) {
    SegmentSession.setToPosition();

    const { code, message } = errorObj;

    // Video error experienced before playback starts i.e. setup error
    if (!isPlaybackStarted) {
      const props = {
        ...commonTrackingProps,
        event: getEventTrackingProps('videoPlaybackPlayerError'),
        error: {
          type: SegmentErrorMapping.videoPlaybackPlayerError.type,
          message: `${code ? `${code}|${message}` : message}`,
        },
      };

      SegmentSession.trackEvent(segmentEvents.videoPlaybackError, props);

      // Previous implementation to simulate a mocked Video Playback Started
      // SegmentSession.startSession();
      //
      // SegmentSession.trackEvent(segmentEvents.videoPlaybackStarted, {
      //   ...commonTrackingProps,
      //   ..._addSegmentSearchSession()
      // });
    } else {
      if (code && code === 'CHUCK_NORRIS') {
        SegmentSession.trackKickSession(commonTrackingProps);
      } else if (code && code === 'ZOMBIE_STREAM_KICK') {
        SegmentSession.trackZombieKickSession(commonTrackingProps);
      } else {
        //First Manifest/chunks loading err or vpn
        const eventKey = !isFirstFrameRendered
          ? 'videoStartFailure'
          : 'videoPlaybackFailure';
        const errorType = !isFirstFrameRendered
          ? SegmentErrorMapping.videoStartFailure.type
          : SegmentErrorMapping.videoPlaybackFailure.type;
        const props = {
          ...commonTrackingProps,
          method: JSON.stringify(errorObj),
          ...SegmentSession.getPositions(),
          error: {
            type: errorType,
            message: `${code ? `${code}|${message}` : message}`,
          },
          event: getEventTrackingProps(eventKey),
        };

        SegmentSession.trackEvent(
          segmentEvents.videoPlaybackInterrupted,
          props,
        );
      }
    }
  }
  SegmentSession.closeSession();
}

function handleStartSession(isPlaybackStarted, commonTrackingPropsFunc) {
  if (!isPlaybackStarted) {
    const commonTrackingProps = commonTrackingPropsFunc();

    // We need this for instances whereby the player starts at nonzero for vods (resume events)
    SegmentSession.setToPosition();

    SegmentSession.startSession();

    SegmentSession.trackEvent(segmentEvents.videoPlaybackStarted, {
      ...commonTrackingProps,
      event: getEventTrackingProps('videoPlaybackStarted'),
      ..._addSegmentSearchSession(),
    });

    // Allow heartbeat to be started from now on
    SegmentSession.setHeartbeatAllowed(true);

    if (!SegmentSession.getIsInInterval()) {
      SegmentSession.startInterval(commonTrackingPropsFunc);
    }

    return true;
  }

  return false;
}

function handleBeforePlay(getState, commonTrackingPropsFunc) {
  const { isPlaybackStarted } = getState();
  const newState = {};

  const started = handleStartSession(
    isPlaybackStarted,
    commonTrackingPropsFunc,
  );

  if (started) {
    newState.isPlaybackStarted = true;
  }

  return newState;
}

function handleFirstFrame(player, getState, commonTrackingPropsFunc) {
  const {
    isPlaybackStarted,
    isVideoContentStarted,
    isFirstFrameRendered,
    yoSessionManager,
    isLive,
  } = getState();
  const advertMode = _isInAdBreakMode(
    isLive,
    yoSessionManager,
    player.getPosition(),
  );
  const newState = {};
  // SegmentSession.setToPosition();

  // const props = {
  //   ...commonTrackingProps,
  //   ...SegmentSession.getPositions()
  // };

  // Account for instances where handleFirstFrame fires BEFORE handlePlay
  // as observed for SSAI assets
  const started = handleStartSession(
    isPlaybackStarted,
    commonTrackingPropsFunc,
  );

  if (started) {
    newState.isPlaybackStarted = true;
  }
  if (!isFirstFrameRendered) {
    newState.isFirstFrameRendered = true;
    if (!advertMode && !isVideoContentStarted) {
      newState.isVideoContentStarted = true;
      trackVideoContentStarted(() => commonTrackingPropsFunc());
    }
  }

  return newState;
}

function _isInAdBreakMode(isLive, yoSessionManager, playerPosition) {
  let isInAnAdvert = false;

  //only for vod assets check if its in advert mode by verifying asset timeline mode
  if (
    yoSessionManager &&
    !isLive &&
    playerPosition >= 0 &&
    typeof playerPosition === 'number'
  ) {
    const { session = null } = yoSessionManager;
    if (session) {
      const { timeline = null } = session;
      const assetTimeLine =
        timeline && timeline.getElementAtTime(playerPosition);
      isInAnAdvert = assetTimeLine && assetTimeLine.type === 'advert';
    }
  }

  return isInAnAdvert;
}

function trackVideoContentStarted(commonTrackingPropsFunc) {
  const commonTrackingProps = commonTrackingPropsFunc();
  SegmentSession.setToPosition();
  SegmentSession.trackEvent(segmentEvents.videoContentStarted, {
    event: getEventTrackingProps('videoContentStarted'),
    ...SegmentSession.getPositions(),
    ...getPlayContentTrackingProps(commonTrackingProps),
  });
  SegmentSession.setFromPosition();
}

function trackAdStarted(commonTrackingPropsFunc) {
  const commonTrackingProps = commonTrackingPropsFunc();
  const { livestream } = commonTrackingProps;
  !livestream && SegmentSession.setToPosition();
  const props = {
    event: getEventTrackingProps('videoAdStarted'),
    ...SegmentSession.getPositions(),
    ...commonTrackingProps,
  };

  SegmentSession.trackEvent(segmentEvents.videoAdStarted, props);
  !livestream && SegmentSession.setFromPosition();
}

function trackAdCompleted(commonTrackingPropsFunc) {
  const commonTrackingProps = commonTrackingPropsFunc();
  const { livestream } = commonTrackingProps;
  !livestream && SegmentSession.setToPosition();
  const props = {
    event: getEventTrackingProps('videoAdCompleted'),
    ...commonTrackingProps,
    ...SegmentSession.getPositions(),
  };

  SegmentSession.trackEvent(segmentEvents.videoAdCompleted, props);
  !livestream && SegmentSession.setFromPosition();
}

function handlePlay(
  { playerOldState, playerNewState },
  getState,
  commonTrackingPropsFunc,
) {
  const commonTrackingProps = commonTrackingPropsFunc();
  const { isPause, isPlaybackStarted } = getState();
  const newState = {};

  const started = handleStartSession(
    isPlaybackStarted,
    commonTrackingPropsFunc,
  );

  if (started) {
    newState.isPlaybackStarted = true;
  } else {
    if (isPause) {
      _handleVideoResumed(commonTrackingProps);
    }

    if (playerOldState === 'buffering' && playerNewState === 'playing') {
      SegmentSession.handleBufferCompleted(commonTrackingProps);
    }

    if (!SegmentSession.getIsInInterval()) {
      SegmentSession.startInterval(commonTrackingPropsFunc);
    }
  }

  return newState;
}

function _handleVideoResumed(commonTrackingProps) {
  SegmentSession.setToPosition();

  const props = {
    ...commonTrackingProps,
    event: getEventTrackingProps('videoPlaybackResumed'),
  };

  SegmentSession.trackEvent(segmentEvents.videoPlaybackResumed, props);
  SegmentSession.setFromPosition();
}

function handleBeforeComplete(commonTrackingProps) {
  // Send Segment Video Content and Playback Completed events
  // Send Final heartbeat after Video Content Completed
  SegmentSession.trackEvent(segmentEvents.videoContentCompleted, {
    ...getPlayContentTrackingProps(commonTrackingProps),
    event: getEventTrackingProps('videoContentCompleted'),
  });
  SegmentSession.trackFinalHeartbeat(commonTrackingProps);
  SegmentSession.trackEvent(segmentEvents.videoPlaybackCompleted, {
    ...commonTrackingProps,
    event: getEventTrackingProps('videoPlaybackCompleted'),
  });
}

function trackYoSpaceEvent(props, state) {
  const getState = () => state;
  const getProps = () => props;
  const { asset } = getProps();
  const playbackItem = _.get(asset, 'play.playback.items.item.0', {});
  const { url: pbApiStreamUrl } = playbackItem;
  const {
    yoSessionManager,
    playBackSources,
    isYoSpaceError,
    yospaceErrorMessage,
  } = getState();
  const sources = playBackSources ? _.first(playBackSources) : {};
  const { file: streamUrl } = sources;
  const yospaceErrorProps = isYoSpaceError
    ? {
        error: {
          type: SegmentErrorMapping.videoPlaybackYoSpaceError.type,
          message:
            yospaceErrorMessage ||
            SegmentErrorMapping.videoPlaybackYoSpaceError.message,
        },
      }
    : {};
  const yospaceProps = streamUrl
    ? {
        yospace: {
          success: yoSessionManager ? true : false,
          playbackApiStreamUrl: pbApiStreamUrl,
          yospaceInitOutputUrl: yoSessionManager ? streamUrl : '',
          ...yospaceErrorProps,
        },
      }
    : '';

  SegmentSession.trackEvent(segmentEvents.yoSpaceInit, {
    ...SegmentSession.getCommonTrackingProps(null, getProps, getState),
    event: getEventTrackingProps('yoSpaceInit'),
    ...yospaceProps,
  });
  if (isYoSpaceError) {
    SegmentSession.trackEvent(segmentEvents.videoPlaybackError, {
      ...SegmentSession.getCommonTrackingProps(null, getProps, getState),
      event: getEventTrackingProps('videoPlaybackYoSpaceError'),
      ...yospaceProps,
      ...yospaceErrorProps,
    });
  }
}

function trackApiEvent({
  apiUrl,
  segmentEventName,
  requestParams,
  response,
  getProps,
  getState,
}) {
  const httpProps = getHttpRequestResponsePayloadForApi(
    apiUrl,
    requestParams,
    response,
  );

  const eventProps = getEventTrackingProps(segmentEventName);
  const segmentName = eventProps ? eventProps.name : '';
  SegmentSession.trackEvent(segmentName, {
    ...SegmentSession.getCommonTrackingProps(null, getProps, getState),
    event: eventProps,
    http: httpProps,
  });
}

function trackApiErrorEvent({
  segmentEventName,
  errorStatus,
  getProps,
  getState,
}) {
  const eventProps = getEventTrackingProps(segmentEventName);
  const errorProps = getErrorPropsForApi(errorStatus);
  SegmentSession.trackEvent(eventProps.name, {
    ...SegmentSession.getCommonTrackingProps(null, getProps, getState),
    event: eventProps,
    ...errorProps,
  });
}

function handleAppKillError(getState, commonTrackingPropsFunc) {
  const { isPlaybackStarted, isFirstFrameRendered } = getState();
  const commonTrackingProps = commonTrackingPropsFunc();

  if (isPlaybackStarted && isFirstFrameRendered) {
    // Normal playback and user has navigated away
    SegmentSession.stopHeartbeat(commonTrackingProps);
    const props = {
      ...commonTrackingProps,
      event: getEventTrackingProps('videoPlaybackCompleted'),
    };
    SegmentSession.trackEvent(segmentEvents.videoPlaybackCompleted, props);
  } else {
    // EBVS situations
    // 1. Playback hasn't started so Video Playback Error
    // 2. Playback has started but first frame hasn't been triggered
    const segmentEvent = !isPlaybackStarted
      ? segmentEvents.videoPlaybackError
      : segmentEvents.videoPlaybackInterrupted;

    const props = {
      ...commonTrackingProps,
      ...SegmentSession.getPositions(),
      error: SegmentErrorMapping.videoPlayerKill,
      event: {
        ...getEventTrackingProps('videoPlayerKill'),
        name: segmentEvent,
      },
    };

    SegmentSession.trackEvent(segmentEvent, props);
  }
}

function trackKickSession(commonTrackingProps) {
  const props = {
    ...commonTrackingProps,
    ...SegmentSession.getPositions(),
    error: SegmentErrorMapping.videoPlayerKicked,
    event: getEventTrackingProps('videoPlayerKicked'),
  };

  SegmentSession.trackEvent(segmentEvents.videoPlaybackInterrupted, props);
}

function trackZombieKickSession(commonTrackingProps) {
  const props = {
    ...commonTrackingProps,
    ...SegmentSession.getPositions(),
    error: SegmentErrorMapping.videoPlayerZombieKicked,
    event: getEventTrackingProps('videoPlayerZombieKicked'),
  };

  SegmentSession.trackEvent(segmentEvents.videoPlaybackInterrupted, props);
}

function getCommonTrackingProps(player, getProps, getState, directProps = {}) {
  const { asset, user } = getProps();
  const {
    assetId,
    isSSAIEnabled,
    timezoneOffset,
    assetSsaiEnabled,
    yoSessionManager,
    playBackSources,
    isInAdBreak,
    totalAdWatchedDuration,
    isPause,
    livePausedEpochTime,
  } = getState();

  const sources = playBackSources ? _.first(playBackSources) : {};
  const { file: streamUrl } = sources;
  const content_asset_id = assetId;
  const assetData = _.get(asset, 'data', {});
  const playback = _.get(asset, 'play', {});
  const playbackData = _.get(playback, 'playback', {});
  const playbackItem = _.get(playbackData, 'items.item.0', {});
  const licenseUrl = _.get(playbackItem, ['license', '@uri']);

  const {
    flag: cmsStreamConfig,
    selectedCDN: cdn,
    protocol,
    url: pbApiStreamUrl,
  } = playbackItem;
  const { live, streamType: videoStreamType, drmProtected } = playbackData;
  const isYsSdkEnabled = yoSessionManager && assetSsaiEnabled ? true : false;

  const playbackProps = !_.isEmpty(playbackItem)
    ? {
        playback: {
          streamUrl,
          pbApiStreamUrl,
          sourceType: protocol || '',
          drm: {
            url: licenseUrl,
            drmProtected,
          },
          videoStreamType,
          isYsSdkEnabled,
          isSsaiEnabled: isSSAIEnabled,
          live,
          cmsStreamConfig,
          cdn,
          streamKickingRule: playbackData.streamRule
            ? playbackData.streamRule.maxSubscriptionStreams
            : '',
        },
      }
    : {};

  const isLiveAsset = videoService.isPlaybackLive(playbackData);

  const fieldsToPick = [
    'accessType',
    'broadcastEndTime',
    'liveBroadcastTime',
    'categoryId',
    'channel',
    'duration',
    'id',
    'isInTransition',
    'live',
    'startDate',
    'source',
    'tags',
    'title',
    'type',
    'updatedAt',
    'isSSAIEnabled',
  ];
  const assetToTrack = _.pick(assetData, fieldsToPick);
  const livestream = !_.isEmpty(playbackData)
    ? isLiveAsset
    : assetToTrack['live'];
  const isSSAIEnabledAsset = !_.isEmpty(playbackData)
    ? isSSAIEnabled
    : assetToTrack['isSSAIEnabled'];

  const assetDataId = _.get(assetData, 'id');
  assetToTrack['description'] = assetToTrack['description'] || '';
  assetToTrack['externalId'] = assetToTrack['externalId'] || assetDataId;
  assetToTrack['publishStartDate'] = assetToTrack['startDate'];
  // Should we just post the asset metadata live value or use playback api's value here?
  assetToTrack['isLive'] = assetToTrack['live'];
  assetToTrack['broadcastStartTime'] = assetToTrack['liveBroadcastTime'];
  assetToTrack['duration'] = livestream ? -1 : assetToTrack['duration'];
  delete assetToTrack['accessType'];
  delete assetToTrack['startDate'];
  delete assetToTrack['live'];
  delete assetToTrack['liveBroadcastTime'];
  delete assetToTrack['categoryId'];
  delete assetToTrack['isSSAIEnabled'];

  const { defaultPlatform: platform } = defaultConfig;
  const muxPluginName = _.get(defaultConfig, 'muxPluginName', '');
  const muxPluginVersion = _.get(defaultConfig, 'muxPluginVersion', '');

  const playerName = _.get(defaultConfig, 'playerName', 'jwPlayer');
  const appName = _.get(defaultConfig, 'name', 'optus-sport-portal');
  const environment = _.get(defaultConfig, 'env', '');
  const appVersion = _.get(defaultConfig, 'version', '');
  const browserData = getBrowser();
  const browserName = _.get(browserData, 'name', '');
  const browserVersion = _.get(browserData, 'version', '');
  const playerVersionDetails = getPlayerInfo();
  const playerVersion = playerVersionDetails
    ? _.split(playerVersionDetails, '+')[0]
    : '';
  const session_id = PlayerSession.viewingSessionId;

  let positionFloat = player && player.getPosition ? player.getPosition() : 0;
  const adTrackingProps = getAdTrackingProps(positionFloat, getState);
  SegmentSession.setPosition(
    positionFloat,
    livestream,
    isPause,
    livePausedEpochTime,
  );
  const { position: adPosition = 0 } = adTrackingProps;
  let position;
  if (livestream) {
    position = !isInAdBreak ? globalPosition : adPosition;
  } else {
    positionFloat = Math.round(positionFloat - totalAdWatchedDuration);
    position = !isInAdBreak ? positionFloat : adPosition;
  }
  //overriding ad position if its in ad break mode else get calculated global position

  const playedDuration =
    player && player.getDuration && parseInt(player.getDuration(), 10);
  const total_length = livestream ? 0 : playedDuration;
  const content_length = total_length;

  const full_screen = player && player.getFullscreen && player.getFullscreen();
  // Todo: JW Player does not provide quality info when playing HLS in Safari, need a fix
  const visualQuality =
    player && player.getVisualQuality && player.getVisualQuality();

  const osData = getOS();
  const osVersion = _.get(osData, 'version', '');
  const osName = _.get(osData, 'name', '');
  const qualityLabel =
    window.localStorage.getItem('jwplayer.qualityLabel') || 'NA';
  const bitrateSelection =
    window.localStorage.getItem('jwplayer.bitrateSelection') || 'NA';

  const volume = window.localStorage.getItem('jwplayer.volume') || 'NA';

  const sound =
    (player && player.getVolume && parseInt(player.getVolume(), 10)) || volume;
  const bitrate =
    _.get(visualQuality, ['level', 'bitrate']) || bitrateSelection;
  const quality = _.get(visualQuality, ['level', 'label']) || qualityLabel;
  const { deviceId, model: deviceModel, vendor: deviceMake } = getDevice();

  const userTags = _.get(user, ['userTags']);
  const userLoggedIn = selectors.user.isLoggedIn({ user });

  const userId = userLoggedIn
    ? _.get(user, ['analytics', 'analyticUserId']) ||
      _.get(user, ['authData', 'cognitoUser', 'attributes', 'sub'])
    : deviceId;
  const userType = userLoggedIn
    ? _.get(user, ['userType', 'type'])
    : 'logged-out-user';
  const userToTrack = {
    userId,
    userTags,
    userType,
  };
  const { advertConsent } = getAppInfo();
  const advertId = !advertConsent ? 'NA' : '';
  const content_or_ad = SegmentSession.videoType;
  const trackingProps = {
    session_id,
    content_asset_id,
    asset_id: assetId,
    content_or_ad,
    position,
    total_length,
    content_length,
    bitrate,
    sound,
    full_screen,
    ad_enabled: isSSAIEnabledAsset,
    quality,
    frameRate: 30,
    livestream,
    asset: assetToTrack,
    user: userToTrack,
    application: {
      appName,
      platform,
      environment,
      appVersion,
    },
    video_player: {
      playerVersion: playerVersion.slice(0, 6),
      playerName,
    },
    device: {
      deviceId,
      deviceMake,
      deviceModel,
      deviceTime: moment.utc(new Date()).format(),
      osName,
      osVersion,
      advertConsent: !!advertConsent,
      advertId,
      advertIdType: '',
      browserName,
      browserVersion,
    },
    mux_plugin: {
      muxPluginName,
      muxPluginVersion,
    },
    ...playbackProps,
    timezone_offset: timezoneOffset,
    ...adTrackingProps,
    ...directProps,
  };

  return trackingProps;
}

function getPlayContentTrackingProps(commonTrackingProps) {
  const { content_asset_id: asset_id, ...restProps } = commonTrackingProps;
  return {
    asset_id,
    ...restProps,
  };
}

function trackVideoRequested(commonTrackingProps) {
  const props = {
    ...commonTrackingProps,
    event: getEventTrackingProps('videoPlaybackRequested'),
    ...SegmentSession.getPositions(),
  };
  SegmentSession.trackEvent(segmentEvents.videoPlaybackRequested, props);
}

function trackPlaybackErrorDisplayed({ commonTrackingProps, errorType, exceedDeviceAllowanceErrSessionId }) {
  const props = {
    ...commonTrackingProps,
    error_type: errorType,
    session_id: exceedDeviceAllowanceErrSessionId,
  };
  SegmentSession.trackEvent(segmentEvents.playbackErrorDisplayed, props);
}

function trackPlaybackErrorInteraction({ commonTrackingProps, interaction, exceedDeviceAllowanceErrSessionId }) {
  const props = {
    ...commonTrackingProps,
    interaction,
    session_id: exceedDeviceAllowanceErrSessionId,
  };
  SegmentSession.trackEvent(segmentEvents.playbackErrorInteraction, props);
}

const SegmentSession = {
  heartbeatAllowed: defaultHeartbeatAllowed,
  videoType: defaultVideoType,
  handlePlay,
  handleSeek,
  handleSeeked,
  handlePause,
  handleBuffer,
  handleBufferCompleted,
  updateVideoError,
  handleBeforePlay,
  handleFirstFrame,
  handleBeforeComplete,
  getCommonTrackingProps,
  trackApiEvent,
  trackApiErrorEvent,
  trackYoSpaceEvent,
  trackVideoRequested,
  trackPlaybackErrorDisplayed,
  trackPlaybackErrorInteraction,
  handleAppKillError,
  trackKickSession,
  trackAdStarted,
  trackAdCompleted,
  trackVideoContentStarted,
  trackZombieKickSession,
  //
  startSession: () => {
    SegmentSession.clearInterval();
    SegmentSession.setFromPosition();
    SegmentSession.setHeartbeatAllowed(defaultHeartbeatAllowed);
    SegmentSession.setVideoType(defaultVideoType);
  },

  closeSession: () => {
    SegmentSession.clearInterval();
    SegmentSession.resetPositions();
    SegmentSession.setHeartbeatAllowed(defaultHeartbeatAllowed);
    SegmentSession.setVideoType(defaultVideoType);
  },

  startInterval: (commonTrackingPropsFunc) => {
    if (SegmentSession.heartbeatAllowed) {
      SegmentSession.clearInterval();

      SegmentSession.eventPostInterval = window.setInterval(
        _trackHeartbeatEvent,
        heartbeatInterval,
        commonTrackingPropsFunc,
      );
    }
  },

  clearInterval: () => {
    const { eventPostInterval } = SegmentSession;
    if (eventPostInterval) {
      window.clearInterval(eventPostInterval);
      SegmentSession.eventPostInterval = null;
    }
  },

  trackEvent: (event, props) => {
    const { session_id, livestream } = props;
    const streamType = livestream ? 'LIVE' : 'VOD';
    const exclusionList = _.get(
      SegmentPropertyExclusionList,
      [streamType, event],
      [],
    );
    exclusionList.forEach((e) => delete props[e]);

    // the position value should be global position for all Video Playback * events during ads
    const shouldOverridePosition =
      _.indexOf(PlaybackPositionOverrideList[streamType], event) > -1;
    if (shouldOverridePosition) {
      props.position = globalPosition;
    }

    if (window.analytics && session_id) {
      window.analytics.track(event, props);
    }
  },

  trackFinalHeartbeat: (commonTrackingPropsFunc) => {
    SegmentSession.clearInterval();
    _trackHeartbeatEvent(commonTrackingPropsFunc);
  },

  stopHeartbeat: (commonTrackingPropsFunc) => {
    SegmentSession.trackFinalHeartbeat(commonTrackingPropsFunc);

    SegmentSession.closeSession();
  },

  setHeartbeatAllowed: (allowed) => (SegmentSession.heartbeatAllowed = allowed),

  setVideoType: (type) => (SegmentSession.videoType = type),

  resetPositions: () => {
    globalFromPosition = 0;
    globalToPosition = 0;
  },

  setFromPosition: (positionOverride) => {
    globalFromPosition =
      typeof positionOverride !== 'undefined'
        ? positionOverride
        : globalToPosition;
  },

  setPosition: (position, isLiveAsset, isPause, livePausedEpochTime) => {
    // Note: For live assets we should calculate the
    // current timestamp in seconds + the current position
    // (the position should be negative when we have DVR)
    if (isLiveAsset) {
      if (isPause && livePausedEpochTime) {
        globalPosition = Math.round(livePausedEpochTime / 1000);
      } else {
        globalPosition = Math.round(new Date().getTime() / 1000 + position);
      }
    } else {
      globalPosition = Math.round(position);
    }
    return globalPosition;
  },

  setToPosition: () => {
    globalToPosition = globalPosition;
  },

  getPositions: () => {
    return {
      global_from_position: globalFromPosition,
      global_to_position: globalToPosition,
    };
  },

  getIsInInterval: () => SegmentSession.eventPostInterval,
};

export default SegmentSession;

export function segmentSessionHandleLogin(userId) {
  if (window.analytics) {
    window.analytics.identify(userId);
  }
}

export function segmentSessionHandleLogout() {
  if (window.analytics) {
    window.analytics.reset();
  }
}
