import _ from 'lodash';

export const getAssetImageUrls = ({ imageUrl, panelCoverSize, carouselImageSizeMedium }) => {
  let srcSet = [];

  if (panelCoverSize) {
    srcSet.push(`${panelCoverSize} 768w`);
  }
  if (carouselImageSizeMedium) {
    srcSet.push(`${carouselImageSizeMedium} 1280w`);
  }
  if (imageUrl) {
    srcSet.push(`${imageUrl} 1920w`);
  }

  return srcSet.length > 1 ? srcSet.join(', ') : '';
};

export const replaceImageSize = (originalImageUrl, imageSize) => {
  const parsedImageSize = _.get(_.split(imageSize, '_', 3), [2]);
  const replacedImageUrl = _.replace(originalImageUrl, '${width x height}', parsedImageSize); // eslint-disable-line
  return replacedImageUrl;
};
