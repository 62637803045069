import _ from 'lodash';
import winston from 'winston';
import { Loggly } from 'winston-loggly-bulk';
import defaultConfig from '../../constants/defaultConfig';
import * as analyticService from '../../services/analytic';
import pjson from '../../../package.json';
import { getBrowser, getOS, getDeviceDetail } from '../../utils/userAgent';

function addLog(level, message, tags) {
  const {
    logglyCustomerToken: token,
    logglySubdomain: subdomain,
  } = defaultConfig;

  try {
    // Clear any existing Loggly transport or else
    // duplicate logging will occur
    winston.clear().add(
      new Loggly({
        token,
        subdomain,
        tags,
        json: true,
        isBulk: true,
      }),
    );

    winston.log(level, message);
  } catch (error) {
    console.log('logglyLogger', error);
  }
}

export function error(message, tags) {
  addLog('error', message, tags);
}

export function info(message, tags) {
  addLog('info', message, tags);
}

export function getBaseProps() {
  const browserData = getBrowser();
  const osData = getOS();
  const deviceDetail = getDeviceDetail();

  const userName = _.get(
    localStorage,
    `CognitoIdentityServiceProvider.${defaultConfig.awsUserPoolWebClientId}.LastAuthUser`,
  );
  const userData = JSON.parse(
    _.get(
      localStorage,
      `CognitoIdentityServiceProvider.${defaultConfig.awsUserPoolWebClientId}.${userName}.userData`,
      '{}',
    ),
  );
  const userAttributes = _.get(userData, 'UserAttributes');
  const userId = _.get(
    _.filter(userAttributes, ['Name', 'custom:userId'])[0],
    'Value',
    '-1',
  );
  const userType = _.get(
    _.filter(userAttributes, ['Name', 'custom:userType'])[0],
    'Value',
    'basic',
  );

  const logglyBaseTemplate = {
    app: {
      environment: defaultConfig.logglyEnv,
      appName: pjson.name,
      appVersion: pjson.version,
      platform: 'Web',
    },
    device: {
      browserData: browserData,
      osData: osData,
      deviceDetail: deviceDetail,
    },
    error: {},
    user: {
      userID: `${userId}`,
      userType: userType,
    },
  };

  return logglyBaseTemplate;
}

export function getCommonProps(logData) {
  const { app, device, user, asset } = analyticService.getCommonProps(logData);
  const { appName, platform, environment, appVersion, advertConsent } = app;
  const { deviceId, OSVersion, browserName, browserVersion } = device;
  const { userID = '', userTags = [], userType = '' } = user;

  return {
    asset,
    app: {
      appName,
      platform,
      environment,
      appVersion,
    },
    device: {
      deviceId,
      OSVersion,
      browserName,
      browserVersion,
      //
      // deviceMake: '',
      // deviceModel: '',
      deviceTime: new Date().getTime(),
      //
      advertConsent,
      // advertId: '',
      // advertIdType: ''
    },
    user: {
      userID,
      userTags,
      userType,
    },
  };
}
