import _ from 'lodash';

function getEditorialTitle(str) {
  const splitedStr = _.split(str, '_');
  let title = '';
  const splitedStrLength = _.size(splitedStr);
  for (let i = 1; i < splitedStrLength; ++i) {
    const postfixPad = i === splitedStrLength - 1 ? '' : ' ';
    title += _.startCase(_.toLower(splitedStr[i])) + postfixPad;
  }
  return title;
}

export function editorialTitleMapping(editorial) {
  // OSN-1622 - as part of app manager updates
  // we will now enforce the use of displayName
  // instead of remapping to our own titles
  const editorialDisplayName = _.get(editorial, 'displayName', null);
  if (editorialDisplayName) {
    return editorialDisplayName;
  }

  const editorialId = _.get(editorial, 'id', null);
  let title = null;

  switch (editorialId) {
    case 'home_hero_carousel':
    case 'epl_carousel':
    case 'ucl_carousel':
    case 'uel_carousel':
    case 'unl_carousel':
    case 'originals_hero_carousel':
    case 'j_league_carousel':
      title = 'Carousel';
      break;
    case 'home_minimatches':
    case 'epl_minimatches':
    case 'ucl_minimatches':
    case 'uel_minimatches':
    case 'unl_minimatches':
    case 'j_league_minimatches':
    case 'euro2020_minimatches':
    case 'wsl_minimatches':
    case 'kleague_minimatches':
    case 'fcwc_minimatches':
    case 'copa_minimatches':
      title = 'Mini Matches';
      break;
    case 'home_live_matches':
    case 'epl_live_matches':
    case 'ucl_live_matches':
    case 'uel_live_matches':
    case 'unl_live_matches':
    case 'j_league_live_matches':
    case 'euro2020_live_matches':
    case 'wsl_live_matches':
    case 'kleague_live_matches':
    case 'fcwc_live_matches':
    case 'copa_live_matches':
      title = 'Live & Upcoming';
      break;
    case 'epl_classics':
      title = 'Mini Classics';
      break;
    case 'epl_legends':
      title = 'Specials';
      break;
    case 'originals_gegen_pod':
      title = 'GegenPod';
      break;
    case 'home_pressconferences':
    case 'epl_pressconferences':
    case 'ucl_pressconferences':
    case 'uel_pressconferences':
    case 'unl_pressconferences':
    case 'euro2020_pressconferences':
      title = 'Press Conferences';
      break;
    case 'articles':
    case 'euro2020_articles':
    case 'copa_articles':
    case 'na_pl_must_read':
    case 'na_aus_must_read':
    case 'na_euro_must_read':
      title = 'Must Read';
      break;
    case 'home_fifa_club_world_cup':
      title = 'FIFA Club World Cup™';
      break;
    case 'wsl_review':
    case 'j_league_replays':
      title = 'Replays';
      break;
    case 'j_league_shows':
    case 'wsl_shows':
      title = 'Shows';
      break;
    case 'j_league_highlights':
      title = 'Highlights';
      break;
    case 'j_league_shorts':
      title = 'Shorts';
      break;
    case 'j_league_interviews':
      title = 'Interviews';
      break;
    case 'j_league_news':
    case 'na_home_news':
    case 'wsl_news':
      title = 'News';
      break;
    case 'euro2020_flashbacks':
      title = 'EURO 2020 Flashbacks';
      break;
    case 'euro2020_social':
      title = 'EURO 2020 Social';
      break;
    case 'home_classic_matches':
      title = 'Mini Classics';
      break;
    case 'home_biginterview':
    case 'na_features_big_interview':
      title = 'The Big Interview';
      break;
    case 'home_plworld':
    case 'epl_plworld':
      title = 'Premier League World';
      break;
    case 'home_fromthevault':
      title = 'From The Vault';
      break;
    case 'home_pllegends':
    case 'epl_pllegends':
      title = 'Premier League Legends';
      break;
    case 'home_pl100':
    case 'epl_pl100':
      title = 'Premier League 100';
      break;
    case 'na_home_trending':
    case 'home_featured':
    case 'na_pl_trending':
    case 'na_features_trending':
    case 'na_womens_trending':
    case 'na_aus_trending':
    case 'na_euro_trending':
      title = 'Trending';
      break;
    case 'home_egame':
    case 'epl_egame':
      title = 'ePremier League Finals';
      break;
    case 'home_kleague':
      title = 'K League 1';
      break;
    case 'home_fitness_and_wellbeing':
      title = 'Fitness | Trending';
      break;
    case 'home_fantasy_pl':
    case 'epl_fantasy_pl':
      title = 'Fantasy Premier League';
      break;
    case 'top_picks':
      title = 'Top Picks';
      break;
    case 'express_workouts':
      title = 'Express Workouts';
      break;
    case 'newly_added':
      title = 'Newly Added';
      break;
    case 'come_back_workout':
      title = 'Beginner Workouts';
      break;
    case 'football_skills':
      title = 'Sport Skills';
      break;
    case 'sport_drills_football_skills':
      title = 'Football Skills';
      break;
    case 'family_fitness':
      title = 'Family Fitness';
      break;
    case 'build_your_strength':
      title = 'Strength & Resistance';
      break;
    case 'pilates':
      title = 'Pilates';
      break;
    case 'yoga':
      title = 'Yoga';
      break;
    case 'yoga_and_pilates':
      title = 'Yoga & Pilates';
      break;
    case 'barre':
      title = 'Barre';
      break;
    case 'stretch_it_out':
      title = 'Stretch & Move';
      break;
    case 'hiit_strength':
      title = 'HIIT';
      break;
    case 'cardio_sweats_top_picks':
    case 'get_your_heart_pumping':
      title = 'Cardio';
      break;
    case 'dance':
      title = 'Dance';
      break;
    case 'dumbbells_and_kettlebells':
      title = 'Dumbbells & Kettlebells';
      break;
    case 'fiit':
    case 'move_and_flex_fiit':
    case 'strength_and_tone_fiit':
      title = 'FiiT';
      break;
    case 'virtual_playground':
    case 'move_and_flex_virtual_playground':
    case 'strength_and_tone_virtual_playground':
      title = 'Virtual Playground';
      break;
    case 'flow_athletic':
    case 'move_and_flex_flow_athletic':
    case 'strength_and_tone_flow_athletic':
      title = 'Flow Athletic';
      break;
    case 'eastnine':
      title = 'East Nine';
      break;
    case 'best_of_2020':
      title = 'Best of 2020';
      break;
    case 'free_to_watch':
      title = 'Free To Watch';
      break;
    case 'football_belongs':
    case 'na_features_football_belongs':
      title = 'Football Belongs';
      break;
    case 'lean_bean':
    case 'movement_lean_bean':
    case 'strength_lean_bean':
      title = 'Virtual Lean Bean';
      break;
    case 'the_healthy_mummy':
    case 'movement_the_healthy_mummy':
    case 'strength_the_healthy_mummy':
      title = 'The Healthy Mummy';
      break;
    case 'fitness_articles':
      title = 'Fitness Articles';
      break;
    case 'fitness_insider':
      title = 'Fitness Insider';
      break;
    case 'home_european_football':
    case 'na_home_european':
      title = 'European Football';
      break;
    case 'na_home_pl':
      title = 'Premier League';
      break;
    case 'na_home_features':
      title = 'Features';
      break;
    case 'na_home_womens':
      title = "Women's Football";
      break;
    case 'na_home_australia':
      title = 'Australian Football';
      break;
    case 'na_home_j_league':
      title = 'J.League';
      break;
    case 'na_home_fitness':
      title = 'Fitness';
      break;
    case 'na_pl_latest_news':
      title = 'Latest News';
      break;
    case 'na_pl_match_analysis':
      title = 'Match Analysis';
      break;
    case 'na_pl_transfer_talk':
      title = 'Transfer Talk';
      break;
    case 'na_features_athletic':
      title = 'Powered By The Athletic';
      break;
    case 'na_features_best_of_os':
      title = 'Best of Optus Sport';
      break;
    case 'na_womens_wsl':
      title = "Women's Super League";
      break;
    case 'na_womens_wwc':
      title = "FIFA Women's World Cup 2023";
      break;
    case 'na_womens_matildas':
    case 'na_aus_matildas':
      title = 'Matildas';
      break;
    case 'na_aus_socceroos':
      title = 'Socceroos';
      break;
    case 'na_euro_ucl':
      title = 'UEFA Champions League';
      break;
    case 'na_euro_uel':
      title = 'UEFA Europa League';
      break;
    case 'na_euro_football_news':
      title = 'European Football News';
      break;
    case 'ronaldo_returns':
      title = 'Ronaldo Returns';
      break;
    case 'for_the_core':
      title = 'Core Training';
      break;
    case 'body_and_mind':
      title = 'Body & Mind';
      break;
    case 'no_equipment':
    case 'no_equipment_needed':
      title = 'No Equipment';
      break;
    case 'meditation':
      title = 'Meditation';
      break;
    case 'mindful_yoga':
      title = 'Mindful Yoga';
      break;
    case 'sleep_visualisations':
      title = 'Sleep Visualisations';
      break;
    case 'affirmations':
      title = 'Affirmations';
      break;
    case 'stretch':
      title = 'Stretching';
      break;
    case 'recovery_and_mobility':
      title = 'Mobility';
      break;
    case 'aussie_team_workouts':
      title = 'Aussie Team Workouts';
      break;
    case 'warm_ups':
      title = 'Warm Ups';
      break;
    case 'hear_from_the_pros':
      title = 'Hear from the Pros';
      break;
    case 'functional_training':
      title = 'Functional';
      break;
    case 'core_strength':
      title = 'Core Strength';
      break;
    case 'upper_body':
      title = 'Upper Body';
      break;
    case 'full_body_circuits':
      title = 'Full Body Circuits';
      break;
    case 'leg_toners':
      title = 'Leg Toners';
      break;
    case 'boxing':
      title = 'Boxing';
      break;
    case 'cycle':
      title = 'Cycle';
      break;
    case 'run_club':
      title = 'Run Club';
      break;
    case 'high_intensity':
      title = 'High Intensity';
      break;
    case '15_minute_workouts':
      title = '15 Minute Workouts';
      break;
    case '20_minute_workouts':
      title = '20 Minute Workouts';
      break;
    case 'explore_web':
      title = 'Explore';
      break;
    case '30_minute_workouts':
      title = '30 Minute Workouts';
      break;
    case 'ab_blaster':
      title = 'Ab Blaster';
      break;
    case 'spring_bootcamp':
      title = 'Spring Bootcamp';
      break;
    case 'mental_health_month':
      title = 'Mental Health Month';
      break;
    case 'euro_cycling_trip':
      title = 'Euro Cycling Trip';
      break;
    case 'the_office_edit':
      title = 'The Office Edit';
      break;
    case 'our_team':
      title = 'Our Team';
      break;
    case 'taryn_brumfitts_top_workouts':
      title = "Taryn Brumfitt's Picks";
      break;
    case 'weight_training':
      title = 'Weight Training';
      break;
    case 'strength':
      title = 'Strength';
      break;
    case 'interval_training':
      title = 'Interval Training';
      break;
    case 'bootcamp':
      title = 'Bootcamp';
      break;
    case 'plyometric':
      title = 'Plyometric';
      break;
    case 'cross_training':
      title = 'Cross-Training';
      break;
    case 'power':
      title = 'Power';
      break;
    case 'tabata':
      title = 'Tabata';
      break;
    case 'emom':
      title = 'EMOM';
      break;
    case 'amrap':
      title = 'AMRAP';
      break;
    case 'low_impact':
      title = 'Low Impact';
      break;
    case 'balance':
      title = 'Balance';
      break;
    case 'aerobics':
      title = 'Aerobics';
      break;
    case 'walking':
    case 'walking_tracks':
      title = 'Walking';
      break;
    case 'vinyasa':
      title = 'Vinyasa';
      break;
    case 'ashtanga':
      title = 'Ashtanga';
      break;
    case 'hatha':
      title = 'Hatha';
      break;
    case 'yin':
      title = 'Yin';
      break;
    case 'football_tips':
      title = 'Football Tips';
      break;
    case 'pilates_cardio':
      title = 'Pilates Cardio';
      break;
    case 'foam_rolling':
      title = 'Foam Rolling';
      break;
    case 'rehabilitation':
      title = 'Rehabilitation';
      break;
    case 'tai_chi':
      title = 'Tai Chi';
      break;
    case 'pregnancy':
      title = 'Pregnancy';
      break;
    case 'post_pregnancy':
      title = 'Post Pregnancy';
      break;
    case 'pelvic_floor':
    case 'pelvic_floor_post_pregnancy':
      title = 'Pelvic Floor';
      break;
    case 'senior':
      title = 'Senior';
      break;
    case 'family_workouts':
      title = 'Family Workouts';
      break;
    case 'fun_with_mum':
      title = 'Fun with Mum';
      break;
    case 'best_of_2021':
      title = 'Best of 2021';
      break;
    case 'my_gym_buddy':
      title = 'My Gym Buddy';
      break;
    case 'summer_sweat':
      title = 'Summer Sweat';
      break;
    case 'the_summer_challenge':
      title = 'The Summer Challenge';
      break;
    case 'healthy_recipes':
      title = 'Healthy Recipes';
      break;
    case 'manual_article_related':
      title = 'Read More';
      break;
    default:
      title = getEditorialTitle(editorialId);
      break;
  }

  return title;
}
