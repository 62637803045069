import _ from 'lodash';
import axios from 'axios';
import * as types from '../constants/actionTypes';
import defaultConfig from 'constants/defaultConfig';
import { getCompetitionCode, getVcmsSeasonId } from 'utils/opta';

export function getTeamStats(seasonId, competitionId, teamId) {
  return (dispatch) => {
    const results = { seasonId, competitionId, teamId };

    const {
      vcmsBaseUrl,
      VCMSConfig: { teamStatsEndpoint },
    } = defaultConfig;
    const vcmsSeasonId = getVcmsSeasonId(competitionId);
    const competitionCode = getCompetitionCode(competitionId);
    const url = `${vcmsBaseUrl}${teamStatsEndpoint}?season=${vcmsSeasonId}&competitionCode=${competitionCode}&team=${teamId}`;
    dispatch({ type: types.GET_TEAMSTATS_INPROGRESS, results });

    axios
      .get(url)
      .then((res) => {
        const isSuccess = _.get(res, 'data.result[0].statuscode', 'N') === 'Y';

        if (isSuccess) {
          results.rows = _.get(res, 'data.APIinfo[0].StatInfo', []); // results return HighlightsInfo as well
          dispatch({ type: types.GET_TEAMSTATS_SUCCESS, results });
        } else {
          dispatch({ type: types.GET_TEAMSTATS_FAILURE, results });
        }
      })
      .catch((error) => {
        dispatch({ type: types.GET_TEAMSTATS_FAILURE, results });
      });
  };
}
