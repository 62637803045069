import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import defaultConfig from '../../../constants/defaultConfig';
import { logoutUser } from '../../../actions/user';
import { analyticAction } from '../../../ducks/analytic';
import { useDispatch } from 'react-redux';
// Helpers
import IconHelper from '../../../common/Icon/IconHelper';
import { AnalyticHelper } from '../../../analytic/components/Analytics';
import { isLoggedIn, getUserType } from '../../../ducks/user';
import { selectors, useSelector } from '../../../store/createPortalStore';

import Loader from '../../../common/Loader';
import Icon from '../../../common/Icon';
import Toggle from '../../../common/Toggle';
import Menu from '../../../common/Menu';

import {
  Heading,
  Controls,
  Button,
  Expand,
  Status,
  BottomOverlay,
} from './styles';

const RenderControls = ({ onExpand = () => {}, ...props }) => {
  // Selectors
  const { user: userSelector } = selectors;
  // Props
  const user = useSelector(userSelector.user);
  // User Type
  const userType = getUserType(user);
  const loggedIn = isLoggedIn(user);
  // Hooks
  const dispatch = useDispatch();
  const onLogin = () => {
    props.history.push('/login');
  };
  const onLogOut = () => {
    dispatch(
      analyticAction({
        eventName: 'logout_button_clicked',
        level: 'info',
      }),
    );
    dispatch(logoutUser());
  };

  const location = useLocation();

  const matchedLocation = 'login' === location?.pathname.split('/')[1];

  return (
    <Controls>
      <Heading left>{'Helper'}</Heading>
      <Expand onClick={onExpand}>
        <Icon name="EXPAND" size={24} stroke="black" />
      </Expand>
      {loggedIn && userType && <Status>{userType}</Status>}
      {!loggedIn && !matchedLocation && (
        <Button className="ml-8" onClick={onLogin}>
          Login{' '}
          <Icon
            rotate={180}
            name="BACK"
            fill="white"
            stroke="white"
            size={24}
          />
        </Button>
      )}
      {loggedIn && (
        <Button className="ml-8" onClick={onLogOut}>
          Logout
        </Button>
      )}
    </Controls>
  );
};

const Helper = ({ position = 'right', children, ...props }) => {
  const [selected, setSelected] = useState('Analytic');
  const [showLoader, setLoader] = useState(false);
  const [showHelper, setShowHelper] = useState(false);
  const [localPosition, setLocalPosition] = useState('right');

  const onExpand = () => {
    setLocalPosition('bottom');
    setLoader(!showLoader);
  };

  // Show / hide helper on development env
  useEffect(() => {
    if (
      window.location.hash === '#showhelper' &&
      defaultConfig.env !== 'prod'
    ) {
      setShowHelper(true);
    }
    // esling-disable-next-line
  }, []);

  if (!showHelper) {
    return null;
  }

  return (
    <Menu
      showClose
      showControls={true}
      renderControls={<RenderControls onExpand={onExpand} {...props} />}
      position={localPosition || position}
    >
      <Toggle
        callback={setLocalPosition}
        label="Position"
        max={2}
        items={['left', 'right']}
      />
      <br />
      <Toggle
        callback={setSelected}
        label="Component"
        max={2}
        items={['Analytic', 'Icon']}
      />

      {showLoader && (
        <>
          <p>
            TODO: Convert and refactor out all previous use-cases of
            `LoadingIcon` component.
          </p>
          <div className="flex justify-center">
            <Loader />
          </div>
        </>
      )}

      <br />
      {selected === 'Analytic' && <AnalyticHelper />}
      {selected === 'Icon' && <IconHelper />}
      {children}
      <BottomOverlay className="bottom" />
    </Menu>
  );
};

export default withRouter(Helper);
