import React from 'react';
import PropTypes from 'prop-types';

class SectionTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      leftActive: true
    }
  }

  componentWillMount(){
    const { selectedTabId, tabs } = this.props
    const selectedTab = selectedTabId ? tabs.find(tab => tab.id === selectedTabId ) : null;
    this.setState({ selectedTab });
  }

  selectTab = (e, selectedTab, side) => {
    const previousTab  = this.state.selectedTab;
    const { change } = this.props;

    if (side === 'left') {
      this.setState({
        selectedTab,
        previousTab,
        leftActive: true
      });
    }
    else {
      this.setState({
        selectedTab,
        previousTab,
        leftActive: false
      });
    }

    if (change) change(e, selectedTab);
  };

  tabFromId = (tabs, id) => (tabs.find(tab => tab.id ===id));

  renderTabs = (tabs, leftActive) => {
    const sectionTabs = tabs.map((tab, key) => {
      if (key === 0) {
        return (
          <React.Fragment>
            <button
              className={`sliding-button left-sliding-button h-40 w-1/2 cursor-pointer text-white py-12 text-sm text-center font-MarkProHeavy outline-none flex-none os-transition-btn overflow-hidden`}
              // onClick={() => this.setState({ leftActive: true }, (e) => this.selectTab(e, tab))}
              key={tab.id}
              onClick={(e) => this.selectTab(e, tab, 'left')}
            >
              <div className={`sliding-bit bg-teal os-transition-button h-40  relative ${leftActive ? 'active' : ''}`} />

              <div className={`relative button-text ${leftActive ? 'text-black' : 'text-white'}`}>{tab.label}</div>
            </button>

            <div className="h-40 w-1 bg-teal flex-none" />
          </React.Fragment>
        )
      } else {
        return (
          <button
            className={`sliding-button right-sliding-button h-40 w-1/2 cursor-pointer text-white py-12 text-sm text-center font-MarkProHeavy outline-none flex-none os-transition-btn overflow-hidden`}
            // onClick={() => this.setState({ leftActive: false }, (e) => this.selectTab(e, tab))}
            key={tab.id}
            onClick={(e) => this.selectTab(e, tab, 'right')}
          >
          <div className={`sliding-bit bg-teal os-transition-button h-40 relative ${!leftActive ? 'active' : ''}`} />

          <div className={`relative button-text ${leftActive ? 'text-white' : 'text-black'}`}>{tab.label}</div>
        </button>
        )
      }
    })

    return sectionTabs;
  }

  render() {
    const { leftActive } = this.state;
    const { tabs } = this.props;
    return (
      <div className="flex flex-no-wrap items-center justify-center border border-teal rounded">
        {this.renderTabs(tabs, leftActive)}
      </div>
    )
  }

}

SectionTab.propTypes = {
  /** Array of items representing the tabs with object structure {label: text to be shown as the title, id: uniquely addressable id} */
  tabs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  /** Tab to be selected by default */
  selectedTabId: PropTypes.string,
  /** Handler for change event. This function will be invoked with the new value when a user clicks on a section tab label. */
  changed: PropTypes.func,
};

SectionTab.defaultProps = {
  selectedTabId: null,
  change: null,
};


export default SectionTab;
