import React, { Component } from "react";
import FooterWidget from "./FooterWidget";

class FooterMegaFooter extends Component {
  render() {
    const { hideDownloadApps, newHome } = this.props;

    return (
      <div className={`
        flex
        flex-wrap
        xl:flex-no-wrap
        xl:flex-row-reverse
        ${newHome ? '': 'px-14'}
        py-0
        pt-40
        xl:pt-0
        pb-16
        xl:w-full
        ${newHome ? '': 'xl:px-118'}
        justify-center
        sm:justify-start
      `}>
        <div
          className={`
            xl:flex-1
            xl:ml-16
            xl:w-2/5
            ${hideDownloadApps ? 'hidden md:block' : ''}
          `}
        >
          <FooterWidget title="Download the Apps" widgettype="appstore-links" />
        </div>
        <div
          className={`
            md:flex
            md:flex-no-wrap
            ${hideDownloadApps ? 'md:mt-48' : 'mt-48'}
            xl:mt-0
            xl:w-3/5
          `}
        >
          <div className="sm:w-1/2 md:w-2/5">
            <FooterWidget title="Optus Sport" widgettype="os-links" />
          </div>
          <div className=" sm:w-1/2 md:w-3/5">
            <FooterWidget title="Help & Support" widgettype="help-links" />
            <FooterWidget title="Advertise" widgettype="advertise-links" />
          </div>
        </div>
      </div>
    )
  }
}

export default FooterMegaFooter;
